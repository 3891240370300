import axios from 'axios'
import { User } from '../../types'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  getUserById (id: string, token: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/users/${id}`, config)
  },
  createUser (token: string, user: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/users', { user }, config)
  },
  updateUserById (id: string, token: string, user: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/users/${id}`, { user }, config)
  },
  updateUserPasswordById (id: string, token: string, user: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/users/${id}/password`, { user }, config)
  },
  updateUserPhotoById (id: string, token: string, user: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/users/${id}/photo`, { user }, config)
  },
  sendVerifyCode (id: string, token: string, user: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/users/${id}/verify`, { user }, config)
  },
  verifyEmail (id: string, token: string, user: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/users/${id}/verify`, { user }, config)
  },
  updateNotifications (id: string, token: string, user: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/users/${id}/notifications`, { user }, config)
  },
  purgeUserAccount (id: string, token: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/users/${id}/purge`, config)
  },
  deleteUserById (id: string, token: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/users/${id}`, config)
  },
  updateUserAddressById (id: string, token: string, address: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/users/${id}/addresses`, { address }, config)
  },
  getUserAddressesById (id: string, token: string, perPage: number, page: number, signal: AbortSignal, search: string = '', filter: string = '') {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/users/${id}/addresses?${filter}`, config)
  },
  getAllUsers (token: string, perPage: number, page: number, signal: AbortSignal, search?: string, filter:string = '') {
    const config = {
      params: {
        limit: perPage,
        page,
        search

      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/users?${filter}`, config)
  },
  async getTotalUsers (token: string, signal: AbortSignal) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    let page = 1
    let pageCount = 2
    const accumulated: Array<User> = []
    do {
      const { data } = await apiClient.get(`/users?limit=${100}&page=${page}`, signal)
      accumulated.push(...data.users) // Concatenate the results to accumulated array
      pageCount = data.meta.pageCount
      page++
    } while (page <= pageCount)
    return accumulated
  },
  updateUserRoleById (id: string, token: string, user: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/users/${id}/role`, { user }, config)
  },
  updateUserCompanyById (id: string, token: string, user: { companyId: string }, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/users/${id}/company`, { user }, config)
  },
  updateUserEmailVerificationById (id: string, token: string, user: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/users/${id}/email-verification`, { user }, config)
  },
  updateUserActivationById (id: string, token: string, user: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/users/${id}/activate`, { user }, config)
  },
  adminUpdateUserPasswordById (token: string, id: string, user: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/users/${id}/password-reset-admin`, { user }, config)
  },
  joinCompanyByInviteCode (token: string, id: string, user: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/users/${id}/company-invite`, { user }, config)
  }
}
