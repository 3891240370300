import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import CampaignQuotaService from '../../../services/api/CampaignQuotaService'
import { CampaignQuotaNotificationState } from '../../../types'

const initialState: CampaignQuotaNotificationState = {
  campaignQuotaNotifications: [],
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  loading: false,
  error: null
}
export const getAllQuotaNotifications = createAsyncThunk(
  'quota/getAllQuotaNotifications',
  async ({ token, campaignId, perPage, page }: { token: string, perPage: number, page: number, campaignId: string}, { rejectWithValue }) => {
    try {
      const data = await CampaignQuotaService.getCampaignQuotaNotifications({ token, campaignId, perPage, page })
      return data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
export const updateCampaignQuotaNotification = createAsyncThunk(
  'quota/updateQuotaNotification',
  async ({ token, campaignQuotaNotificationId, campaignQuotaNotification }: { token: string, campaignQuotaNotificationId: string, campaignQuotaNotification: { threshold: number, recipients: string[] } }, { rejectWithValue }) => {
    try {
      const data = await CampaignQuotaService.updateCampaignQuotaNotification({ token, campaignQuotaNotificationId, campaignQuotaNotification })
      return data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
export const createCampaignQuotaNotification = createAsyncThunk(
  'quota/createQuotaNotification',
  async ({ token, campaignId, campaignQuotaNotification }: { token: string, campaignId: string, campaignQuotaNotification: { threshold: number, recipients: string[] } }, { rejectWithValue }) => {
    try {
      const data = await CampaignQuotaService.createCampaignQuotaNotification({ token, campaignId, campaignQuotaNotification })
      return data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
export const deleteCampaignQuotaNotification = createAsyncThunk(
  'quota/deleteCampaignQuotaNotification',
  async ({ token, campaignQuotaNotificationId }: { token: string, campaignQuotaNotificationId: string }, { rejectWithValue }) => {
    try {
      const data = await CampaignQuotaService.deleteCampaignQuotaNotification({ token, campaignQuotaNotificationId })
      return data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
const campaignQuotaNotificationSlice = createSlice({
  name: 'quotaQuotaNotification',
  initialState,
  reducers: {
    removeCampaignQuotaNotification: (state, action: PayloadAction<{campaignQuotaNotificationId: string}>) => {
      state.campaignQuotaNotifications = state.campaignQuotaNotifications.filter(
        notification => notification.id !== action.payload.campaignQuotaNotificationId
      )
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllQuotaNotifications.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getAllQuotaNotifications.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.campaignQuotaNotifications = action.payload.campaignQuotaNotifications
        state.metadata = action.payload.meta
      })
      .addCase(getAllQuotaNotifications.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload.error
      })
      .addCase(updateCampaignQuotaNotification.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(updateCampaignQuotaNotification.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = null
      })
      .addCase(deleteCampaignQuotaNotification.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = null
      })
      .addCase(deleteCampaignQuotaNotification.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(deleteCampaignQuotaNotification.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload.error
      })
      .addCase(updateCampaignQuotaNotification.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload.error
      })
      .addCase(createCampaignQuotaNotification.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(createCampaignQuotaNotification.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
      })
      .addCase(createCampaignQuotaNotification.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload.error
      })
  }
})

export const { removeCampaignQuotaNotification } = campaignQuotaNotificationSlice.actions
const { reducer } = campaignQuotaNotificationSlice
export default reducer
