import React, { useEffect } from 'react'
import { object, string } from 'yup'
import { useFormik } from 'formik'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { countriesObject } from '../../utils/countries'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Salutation, ShoppingCartAddress, Title } from '../../types'
import { openModal } from '../../utils/openModal'
import { dismissModal } from '../../utils/dismissModal'
import { ADDRESS_ADDITION_SUCCESS_MESSAGE, ADDRESS_UPDATE_SUCCESS_MESSAGE } from '../../constants/messages'
import { phoneValidationPattern } from '../../constants/regexPatterns'
import { SALUTATION_MAX_LENGTH, TITLE_MAX_LENGTH } from '../../constants/maxAndMinFieldValues'
interface AddressEditorProps {
  id: string
  initialAddress: Partial<ShoppingCartAddress> | null
  save: Function
}

const AddressEditor = ({
  id,
  initialAddress,
  save
}: AddressEditorProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isUpdating = useAppSelector((state) => state.apiUsers.isLoading)
  const salutations = useAppSelector((state) => state.apiSalutation.salutations)
  const titles = useAppSelector((state) => state.apiTitle.titles)
  const message = useAppSelector((state) => state.apiUsers.message)

  const token = currentUser?.token

  const dispatch = useAppDispatch()

  const countries = countriesObject.map((country) => ({
    value: country.country,
    label: country.country
  }))

  const deliveryAddressSchema = object({
    companyName: string()
      .min(2, 'Enter a valid company name')
      .max(32, 'Company Name is too long'),
    salutation: string().label('Salutation').max(SALUTATION_MAX_LENGTH),
    title: string().label('Title').max(TITLE_MAX_LENGTH),
    firstName: string()
      .min(2, 'Enter a valid first name')
      .max(32, 'First Name is too long')
      .when('companyName', {
        is: (companyName: string) => !companyName,
        then: string().required('First name is required'),
        otherwise: string()
      }),
    lastName: string()
      .min(2, 'Enter a valid last name')
      .max(32, 'Last Name is too long')
      .when(['companyName'], {
        is: (companyName: string) => !companyName,
        then: string().required('Last name is required'),
        otherwise: string()
      }),
    email: string().email('Enter a valid email')
      .required('Email is required'),
    phone: string()
      .nullable()
      .matches(phoneValidationPattern, 'Enter a valid phone number'),
    country: string().required('Country is required').oneOf(countries.map((country) => country.value)),
    city: string()
      .required('City is required')
      .max(32, 'City name is too long'),
    street: string()
      .required('Street and House Number are required')
      .max(64, 'Street and House Number are too long'),
    zip: string().label('Zip')
      .required('Zip is required')
      .max(32, 'Zip is too long'),
    addressAddition: string()
  })

  const saveAddress = (id: string, address: any, signal: AbortSignal) => {
    dispatch(save({ id, token, address, signal }))
  }

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    isSubmitting,
    resetForm
  } = useFormik({
    initialValues: {
      ...initialAddress,
      companyName: initialAddress?.companyName ?? '',
      salutation: initialAddress?.salutation ?? '',
      title: initialAddress?.title ?? '',
      firstName: initialAddress?.firstName ?? '',
      lastName: initialAddress?.lastName ?? '',
      phone: initialAddress?.phone ?? '',
      addressAddition: initialAddress?.addressAddition ?? '',
      email: initialAddress?.email ?? '',
      country: initialAddress?.country ?? '',
      city: initialAddress?.country ?? '',
      street: initialAddress?.street ?? '',
      zip: initialAddress?.zip ?? ''
    },
    onSubmit: (
      { companyName, salutation, title, firstName, lastName, email, phone, country, city, street, zip, addressAddition },
      actions
    ) => {
      const controller = new AbortController()
      const signal = controller.signal
      const address = {
        companyName: companyName || null,
        salutation: salutation || null,
        title: title || null,
        firstName: firstName || null,
        lastName: lastName || null,
        email,
        phone: phone || null,
        country,
        city,
        street,
        zip,
        addressAddition: addressAddition || null,
        type: 'delivery',
        affiliation: 'other'
      }
      if (token && id) {
        saveAddress(id, address, signal)
      }
      actions.setSubmitting(false)
    },
    validationSchema: deliveryAddressSchema,
    enableReinitialize: true
  })

  useEffect(() => {
    if (message === ADDRESS_ADDITION_SUCCESS_MESSAGE || message === ADDRESS_UPDATE_SUCCESS_MESSAGE) {
      resetForm()
    }
  }, [message])

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <div className="mb-3">
              <label
                htmlFor="deliveryAddressCompanyName"
                className="form-label"
              >
                Company Name
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.companyName}
                type="text"
                className={`form-control ${
                  errors.companyName &&
                  touched.companyName &&
                  errors.companyName
                    ? 'is-invalid'
                    : ''
                }`}
                id="deliveryAddressCompanyName"
                name="companyName"
                placeholder=""
              />
              <div
                id="validationDeliveryCompanyNameFeedback"
                className="invalid-feedback"
              >
                {errors.companyName}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="deliverySalutation" className="form-label">
                Salutation
              </label>
              <div className="input-group">
                <CreatableSelect
                  components={{
                    Input: (props) => (
                      <components.Input {...props} maxLength={SALUTATION_MAX_LENGTH} />
                    )
                  }}
                  inputId="deliverySalutation"
                  aria-label="Salutation"
                  name="salutation"
                  options={salutations.map((salutation: Salutation) => ({
                    value: salutation.name,
                    label: salutation.name
                  }))}
                  value={
                    values.salutation
                      ? { value: values.salutation, label: values.salutation }
                      : null
                  }
                  onChange={(selectedOption) =>
                    setFieldValue('salutation', selectedOption ? selectedOption.value : '')
                  }
                  onBlur={handleBlur}
                  className={`flex-grow-1 ${errors.salutation && touched.salutation ? 'is-invalid' : ''}`}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: (errors.salutation && touched.salutation) ? '#dc3545' : provided.borderColor,
                      '&:hover': {
                        boxShadow: (errors.salutation && touched.salutation) ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                        borderColor: (errors.salutation && touched.salutation) ? '#dc3545' : '#86b7fe'
                      }
                    })
                  }}
                  placeholder="Select or type"
                  isClearable
                />
                <div
                  id="validationSalutationFeedback"
                  className="invalid-feedback"
                >
                  {errors.salutation}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="deliveryTitle" className="form-label">
                Title
              </label>
              <div className="input-group">
                <CreatableSelect
                  components={{
                    Input: (props) => (
                      <components.Input {...props} maxLength={TITLE_MAX_LENGTH} />
                    )
                  }}
                  inputId="deliveryTitle"
                  aria-label="Title"
                  name="title"
                  options={titles.map((title: Title) => ({
                    value: title.name,
                    label: title.name
                  }))}
                  value={
                    values.title
                      ? { value: values.title, label: values.title }
                      : null
                  }
                  onChange={(selectedOption) =>
                    setFieldValue('title', selectedOption ? selectedOption.value : '')
                  }
                  onBlur={handleBlur}
                  className={`flex-grow-1 ${errors.title && touched.title ? 'is-invalid' : ''}`}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: (errors.title && touched.title) ? '#dc3545' : provided.borderColor,
                      '&:hover': {
                        boxShadow: (errors.title && touched.title) ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                        borderColor: (errors.title && touched.title) ? '#dc3545' : '#86b7fe'
                      }
                    })
                  }}
                  placeholder="Select or type"
                  isClearable
                />
                <div
                  id="validationTitleFeedback"
                  className="invalid-feedback"
                >
                  {errors.title}
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="row">
          <div className="col">
            <div className="mb-3">
              <label
                htmlFor="deliveryAddressFirstName"
                className="form-label"
              >
                First Name
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                type="text"
                className={`form-control ${
                  errors.firstName &&
                  touched.firstName &&
                  errors.firstName
                    ? 'is-invalid'
                    : ''
                }`}
                id="deliveryAddressFirstName"
                name="firstName"
                placeholder=""
              />
              <div
                id="validationDeliveryFirstNameFeedback"
                className="invalid-feedback"
              >
                {errors.firstName}
              </div>
            </div>
          </div>
          <div className="col">
            <div className="mb-3">
              <label
                htmlFor="deliveryAddressLastName"
                className="form-label"
              >
                Last Name
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                type="text"
                className={`form-control ${
                  errors.lastName &&
                  touched.lastName &&
                  errors.lastName
                    ? 'is-invalid'
                    : ''
                }`}
                id="deliveryAddressLastName"
                name="lastName"
                placeholder=""
              />
              <div
                id="validationDeliveryLastNameFeedback"
                className="invalid-feedback"
              >
                {errors.lastName}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label
                htmlFor="deliveryAddressEmail"
                className="form-label"
              >
                Email Address
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                type="email"
                className={`form-control ${
                  errors.email &&
                  touched.email &&
                  errors.email
                    ? 'is-invalid'
                    : ''
                }`}
                id="deliveryAddressEmail"
                name="email"
                placeholder=""
                autoComplete="off"
              />
              <div
                id="validationDeliveryEmailFeedback"
                className="invalid-feedback"
              >
                {errors.email}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="deliveryAddressPhone" className="form-label">
                Phone
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                type="text"
                className={`form-control ${
                  errors.phone &&
                  touched.phone &&
                  errors.phone
                    ? 'is-invalid'
                    : ''
                }`}
                id="deliveryAddressPhone"
                name="phone"
                placeholder=""
                autoComplete="off"
              />
              <div
                id="validationDeliveryPhoneFeedback"
                className="invalid-feedback"
              >
                {errors.phone}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="deliveryAddressCountry" className="form-label">
                Country
              </label>
              <Select
                className={`${
                  touched.country && errors.country ? 'is-invalid' : ''
                }`}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderColor:
                      errors.country && touched.country
                        ? '#dc3545'
                        : provided.borderColor,
                    '&:hover': {
                      boxShadow:
                        errors.country && touched.country
                          ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)'
                          : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                      borderColor:
                        errors.country && touched.country
                          ? '#dc3545'
                          : '#86b7fe'
                    }
                  })
                }}
                isClearable
                inputId="deliveryAddressCountry"
                name="country"
                aria-label="Country"
                options={countries}
                onChange={(selectedOption) => {
                  const selectedCountry = selectedOption?.value ?? ''
                  setFieldValue('country', selectedCountry)
                }}
                onBlur={handleBlur}
                value={countries.find(
                  (country) => country.value === values.country
                )}
              />
              <div
                id="validationDeliveryCountryFeedback"
                className="invalid-feedback"
              >
                {errors.country}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label
                htmlFor="deliveryAddressCity"
                className="form-label"
              >
                City
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
                type="text"
                className={`form-control ${
                  errors.city &&
                  touched.city &&
                  errors.city
                    ? 'is-invalid'
                    : ''
                }`}
                id="deliveryAddressCity"
                name="city"
                placeholder=""
              />
              <div
                id="validationDeliveryCityFeedback"
                className="invalid-feedback"
              >
                {errors.city}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label
                htmlFor="deliveryAddressStreet"
                className="form-label"
              >
                Street and House Number
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.street}
                type="text"
                className={`form-control ${
                  errors.street &&
                  touched.street &&
                  errors.street
                    ? 'is-invalid'
                    : ''
                }`}
                id="deliveryAddressStreet"
                name="street"
                placeholder=""
              />
              <div
                id="validationDeliveryStreetFeedback"
                className="invalid-feedback"
              >
                {errors.street}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label
                htmlFor="deliveryAddressZip"
                className="form-label"
              >
                Zip
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.zip}
                type="text"
                className={`form-control ${
                  errors.zip &&
                  touched.zip &&
                  errors.zip
                    ? 'is-invalid'
                    : ''
                }`}
                id="deliveryAddressZip"
                name="zip"
                placeholder=""
              />
              <div
                id="validationDeliveryZipFeedback"
                className="invalid-feedback"
              >
                {errors.zip}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label
                htmlFor="deliveryAddressAddressAddition"
                className="form-label"
              >
                Address Addition
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.addressAddition}
                type="text"
                className={`form-control ${
                  errors.addressAddition &&
                  touched.addressAddition &&
                  errors.addressAddition
                    ? 'is-invalid'
                    : ''
                }`}
                id="deliveryAddressAddressAddition"
                name="addressAddition"
                placeholder=""
              />
              <div
                id="validationDeliveryAddressAdditionFeedback"
                className="invalid-feedback"
              >
                {errors.addressAddition}
              </div>
            </div>
          </div>
        </div>

        <div className="text-end">
          <button
            className="btn btn-secondary  mt-2 me-2"
            type="button"
            onClick={() => {
              openModal('shoppingDeliveryAddressPickerModal')
              dismissModal('addressEditorModal')
              resetForm()
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary mt-2"
            disabled={
              isSubmitting || isUpdating
            }
          >
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

export default AddressEditor
