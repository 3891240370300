import React, { ChangeEvent, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import { string, object } from 'yup'
import utc from 'dayjs/plugin/utc'
import Progress from '../loaders/Progress'
import SkeletonTableRow from '../loaders/skeleton/SkeletonTableRow'
import TableHead from '../Orders/Table/TableHead'
import Pagination from '../Pagination'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  getPendingOrders, resetPendingOrderMessage,
  deletePendingOrderById, resetPendingOrderError
} from '../../store/reducers/api/pendingOrderReducer'
import { setToast } from '../../store/reducers/toastReducer'
import { PENDING_ORDER_DELETION_MESSAGE, PENDING_ORDER_UPDATE_MESSAGE } from '../../constants/messages'
import DeleteConfirmationModal from '../modals/DeleteConfirmationModal'
import { PendingOrder, PendingOrderShippingAddress } from '../../types'
import PendingOrderEditor from '../editors/PendingOrderEditor'
import Terms from '../Terms'
import { dismissModal } from '../../utils/dismissModal'
import { TrashIcon } from '../icons/TrashIcon'
import { PencilIcon } from '../icons/PencilIcon'
import { NoteIcon } from '../icons/NoteIcon'
import useDebounce from '../../utils/hooks/useDebounce'
import { Tooltip } from '../Tooltip'
import SkeletonElement from '../loaders/skeleton/SkeletonElement'

dayjs.extend(utc)

const PendingOrders = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiPendingOrder.isLoading)
  const pendingOrders = useAppSelector((state) => state.apiPendingOrder.pendingOrders)
  const metadata = useAppSelector((state) => state.apiPendingOrder.metadata)
  const message = useAppSelector((state) => state.apiPendingOrder.message)
  const pendingOrderError = useAppSelector((state) => state.apiPendingOrder.error)
  const legalTexts = useAppSelector((state) => state.apiCompany.legalTexts)

  const [initialPendingOrder, setInitialPendingOrder] = useState<Partial<PendingOrder>>({
    id: ''
  })

  const dispatch = useAppDispatch()

  const token = currentUser?.token

  const columns = [
    {
      name: 'Transaction ID',
      className: ''
    },
    {
      name: 'Status',
      className: ''
    },
    {
      name: 'Type',
      className: ''
    },
    {
      name: 'Created Date',
      className: ''
    },
    {
      name: 'Shipping Date',
      className: ''
    },
    {
      name: 'Shipping Information',
      className: ''
    },
    {
      name: 'Actions',
      className: 'text-end'
    }
  ]

  const storedPage = 1
  const storedPerPage = 5
  const [perPage, setPerPage] = useState(storedPerPage)
  const [page, setPage] = useState(storedPage)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 800)

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handlePendingOrdersRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token) {
      dispatch(getPendingOrders({ token, perPage, page, signal, search: debouncedSearchTerm }))
    }
  }

  const getShippingAddress = (shippingAddresses: Array<PendingOrderShippingAddress>) => {
    if (shippingAddresses.length > 0) {
      return shippingAddresses[0]
    }
    return {
      salutation: '',
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      street: '',
      addressAddition: '',
      zipCode: '',
      place: '',
      phone: '',
      country: ''
    }
  }

  const getName = (shippingAddress: Partial<PendingOrderShippingAddress>) => {
    if (shippingAddress.firstName) {
      return `${shippingAddress.firstName} ${shippingAddress.lastName}`
    }
    if (shippingAddress.lastName) {
      return `${shippingAddress.lastName}`
    }
    if (shippingAddress.company) {
      return `${shippingAddress.company}`
    }
    return ''
  }

  const searchSchema = object({
    search: string()
      .max(24, 'Search Name is too long')
  })

  useEffect(() => {
    return () => {

    }
  }, [perPage, page])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getPendingOrders({ token, perPage, page, signal, search: debouncedSearchTerm }))
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page, debouncedSearchTerm])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if ((token) && (message)) {
      const payload = {
        title: 'Success',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(resetPendingOrderMessage())
      dismissModal('confirmationModal')
    }

    if ((message === PENDING_ORDER_DELETION_MESSAGE || message === PENDING_ORDER_UPDATE_MESSAGE) && (token)) {
      dispatch(getPendingOrders({ token, perPage, page, signal, search: debouncedSearchTerm }))
      dismissModal('pendingOrderEditModal')
    }
  }, [message])

  useEffect(() => {
    if (pendingOrderError && pendingOrderError?.errors.message) {
      const payload = {
        title: 'Error',
        message: pendingOrderError.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetPendingOrderError())
    }
  }, [pendingOrderError])

  return (
    <main>
      <div className="m-4">
        <div className="navbar navbar-expand mb-0">
          <p className="h5"><i className="bi bi-stopwatch me-1"></i> Pending Orders</p>
          <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
            <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
              <Formik
                validationSchema={searchSchema}
                enableReinitialize
                initialValues={{
                  search: ''
                }}
                onSubmit={({ search }, actions) => {
                  setSearchTerm(search)
                  setPage(1)

                  actions.setSubmitting(false)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="input-group">
                      <input
                        onChange={(event) => {
                          const search = event.target.value
                          handleChange(event)
                          setSearchTerm(search)
                          setPage(1)
                        }}
                        maxLength={24}
                        onBlur={handleBlur}
                        value={values.search}
                        className={`form-control ${
                          errors.search &&
                          touched.search &&
                          errors.search
                            ? 'is-invalid'
                            : ''
                        }`}
                        type="text"
                        placeholder="Search..."
                        aria-label="Search"
                        aria-describedby="btnNavbarSearch"
                        name="search"
                        autoComplete="on"
                      />
                      <button
                        className="btn btn-outline-dark"
                        id="btnNavbarSearch"
                        type="submit"
                        disabled={isSubmitting}
                        title="Search"
                      >
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
            <button
              type="button"
              title="Refresh"
              aria-label="Refresh"
              className="btn btn-outline-dark"
              onClick={() => handlePendingOrdersRefresh()}
            >
              <i className="fas fa-redo"></i>
            </button>
          </ul>
        </div>
        {isLoading ? <Progress marginTop /> : <hr className="border border-primary border-1 opacity-50"></hr>}
        <div className="table-responsive">
          <table className="table table-hover table-centered table-nowrap align-middle">
            <TableHead columns={columns} />
            <tbody>
              {
                isLoading
                  ? (
                      Array.from(Array(perPage).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={columns.length} actionQuantity={2} />)
                    )
                  : pendingOrders.length > 0
                    ? (
                        pendingOrders.map((pendingOrder) => (
                          <tr key={pendingOrder.id} className={initialPendingOrder.id === pendingOrder.id ? 'table-primary' : ''}>
                            <th scope="row" title={String(pendingOrder.postedOrderId)} className="small user-select-all font-monospace" onClick={() => navigator.clipboard.writeText(String(pendingOrder.postedOrderId))}>
                              {pendingOrder.postedOrderId ?? <SkeletonElement type="text" width="w-75" animation={false} />}
                            </th>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="">
                                  <Tooltip text={pendingOrder.isQueued ? 'Queued' : 'Not Queued'} placement="bottom">
                                    {pendingOrder.isQueued
                                      ? (<i className="bi bi-check-circle-fill text-success" data-toggle="tooltip" title="Queued"></i>)
                                      : (<i className="bi bi-stopwatch" data-toggle="tooltip" title="Not Queued"></i>)
                                    }
                                  </Tooltip>
                                </div>
                                <div className="progress bg-tertiary" style={{ height: '0.25em', width: 20 }}>
                                  <div
                                    className="progress-bar bg-success"
                                    role="progressbar"
                                    style={{ width: pendingOrder.isQueued && pendingOrder.isPosted ? 20 : (pendingOrder.isQueued && !pendingOrder.isPosted ? 10 : 0) }}
                                    aria-valuenow={pendingOrder.isQueued && pendingOrder.isPosted ? 100 : (pendingOrder.isQueued && !pendingOrder.isPosted ? 50 : 0)}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  ></div>
                                </div>
                                <div>
                                <Tooltip text={pendingOrder.isPosted ? 'Processing' : 'Not Processed'} placement="bottom">
                                  {pendingOrder.isPosted
                                    ? (<i className="bi bi-check-circle-fill text-success" title="Processing"></i>)
                                    : (<i className="bi bi-stopwatch" title="Not Processed"></i>)
                                  }
                                </Tooltip>
                                </div>
                              </div>
                            </td>
                            <td>
                              <span className="badge bg-secondary">
                                {pendingOrder.campaign === null ? 'Shop' : 'Campaign'}
                              </span>
                            </td>
                            <td>{dayjs.utc(pendingOrder.createdAt).local().format('LLL')}</td>
                            <td>{dayjs.utc(pendingOrder.deliveryDate).local().format('LL')}</td>
                            <td>{`${getName(getShippingAddress(pendingOrder.shippingAddressRequests))} - ${getShippingAddress(pendingOrder.shippingAddressRequests).place}`}</td>
                            <td className="text-center">
                              <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                                <button
                                  className="btn btn-outline-dark btn-round me-2 position-relative"
                                  type="button"
                                  title={`${(pendingOrder.isPosted || pendingOrder.isQueued) ? 'View' : 'Edit'} Pending Order`}
                                  data-bs-toggle="modal"
                                  data-bs-target="#pendingOrderEditModal"
                                  onClick={() => {
                                    setInitialPendingOrder(pendingOrder)
                                  }}
                                >
                                  {
                                    (pendingOrder.isPosted || pendingOrder.isQueued) ? <NoteIcon /> : <PencilIcon />
                                  }
                                  {pendingOrder.note && <span className="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle">
                                    <span className="visually-hidden">Has note</span>
                                  </span>}
                                </button>
                                <button
                                  className="btn btn-outline-danger btn-round"
                                  type="button"
                                  title="Delete Pending Order"
                                  data-bs-toggle="modal"
                                  data-bs-target="#confirmationModal"
                                  disabled={(pendingOrder.isPosted || pendingOrder.isQueued) || pendingOrder.campaign === null}
                                  onClick={() => {
                                    setInitialPendingOrder(pendingOrder)
                                  }}
                                >
                                  <TrashIcon />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      )
                    : (
                      <tr>
                        <td colSpan={columns.length} className="text-center">
                          No pending orders available yet
                        </td>
                      </tr>
                      )
              }
            </tbody>
          </table>
        </div>
        <Pagination
          isLoading={isLoading}
          metadata={{
            limit: metadata.perPage,
            total: metadata.total,
            offset: ((metadata.page - 1) * (metadata.perPage))
          }}
          page={page}
          perPage={perPage}
          handlePageChange={handlePageChange}
          handleShowEntries={handleShowEntries}
          perPageOptions={[5, 10, 25, 50]}
        />
      </div>
      <DeleteConfirmationModal
        isLoading = {isLoading}
        deleteById={deletePendingOrderById}
        id={String(initialPendingOrder.id)}
        name={<><span className="fw-bold">{`'${initialPendingOrder.postedOrderId ? (initialPendingOrder.postedOrderId) : ''}'`}</span> pending order?</>}
        token={String(token)}
        autoDismiss={false}
      />
      {/* Edit Modal */}
      <div className="modal fade" id="pendingOrderEditModal" tabIndex={-1} aria-labelledby="pendingOrderEditModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="pendingOrderEditModalModalLabel">
                {initialPendingOrder.isPosted || initialPendingOrder.isQueued ? <i className="bi bi-card-text me-2"></i> : <i className="bi bi-pencil-square me-2"></i>}
                {initialPendingOrder.isPosted || initialPendingOrder.isQueued ? 'View' : 'Edit'} Pending Order
              </h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {<PendingOrderEditor pendingOrder={initialPendingOrder} shippingAddressRequests={initialPendingOrder.shippingAddressRequests || []} />}
            </div>
          </div>
        </div>
      </div>
      {/* Terms Modal */}
      <div className="modal fade" id="termsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="termsModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-3" id="termsModalLabel">Terms and Conditions</h1>
                <button type="button" className="btn-close" data-bs-toggle="modal" data-bs-target={'#pendingOrderEditModal'} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <Terms terms={legalTexts.find(legalText => legalText.type === 'terms')?.template} />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={'#pendingOrderEditModal'}>Understood</button>
              </div>
            </div>
          </div>
        </div>
    </main>
  )
}

export default PendingOrders
