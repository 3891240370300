import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AuthService from '../../services/AuthService'
import AuthVerificationService from '../../services/VerificationService'
import { AuthState } from '../../types'

const initialState: AuthState = {
  isLoading: false,
  error: null,
  success: false,
  auth: {
    accessToken: null,
    refreshToken: null
  }
}

export const verifyAuth = createAsyncThunk('auth/verify', async ({ code, signal }: { code: string, signal: AbortSignal }) => {
  const res = await AuthService.verifyAuthorization(code, signal)
  return res.data
})

export const refreshAuth = createAsyncThunk('auth/refresh', async ({ refreshToken, signal }: { refreshToken: string, signal: AbortSignal }) => {
  const res = await AuthService.refreshAuthorization(refreshToken, signal)
  return res.data
})

export const verify = createAsyncThunk('auth/verify/token', async ({ token, signal }: { token: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await AuthVerificationService.verifyAuthorization(token, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthError: (state) => {
      state.error = null
    },
    setAuth: (state, action) => {
      state.auth = action.payload
    },
    resetAuth: (state) => {
      state.auth = {
        accessToken: null,
        refreshToken: null
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyAuth.pending, (state) => {
        state.isLoading = true
      })
      .addCase(verifyAuth.fulfilled, (state, action) => {
        state.isLoading = false
        const {
          access_token: accessToken,
          refresh_token: refreshToken
        } = action.payload
        const auth = {
          accessToken,
          refreshToken
        }
        localStorage.setItem('auth', JSON.stringify(auth))
        state.auth = auth
      })
      .addCase(verifyAuth.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.error
      })
    builder
      .addCase(refreshAuth.pending, (state) => {
        state.isLoading = true
      })
      .addCase(refreshAuth.fulfilled, (state, action) => {
        state.isLoading = false
        const {
          access_token: accessToken,
          refresh_token: refreshToken
        } = action.payload
        const auth = {
          accessToken,
          refreshToken
        }
        localStorage.setItem('auth', JSON.stringify(auth))
        state.auth = auth
        state.error = null
      })
      .addCase(refreshAuth.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.error
        localStorage.clear()
        state.auth = {
          accessToken: null,
          refreshToken: null
        }
      })
    builder
      .addCase(verify.pending, (state) => {
        state.isLoading = true
        state.success = false
      })
      .addCase(verify.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.success = true
      })
      .addCase(verify.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.success = false
        state.error = action.payload
      })
  }
})

export const { resetAuthError, setAuth, resetAuth } = authSlice.actions

const { reducer } = authSlice
export default reducer
