import React, { useCallback, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import BrandedProductsViewerAndEditor from '../../components/BrandedProducts/BrandedProductsViewerAndEditor'
import { TabNames } from '../../constants/branding'
import { ProductCustomisation } from '../../types'
import PrintPreview from '../../components/BrandedProducts/PrintPreview'
import DropZone from '../../components/Dropzone/DropZone'
import Progress from '../../components/loaders/Progress'
import { getDownloadURLFirebase, getMetadataFirebase, refFirebase, storageFirebase, uploadBytesResumableFirebase } from '../../config/firebaseConfig'
import { useParams } from 'react-router-dom'
import { updateProductCustomisation } from '../../store/reducers/api/productCustomisationChatReducer'
import { dismissModal } from '../../utils/dismissModal'
import { getProductCustomisations } from '../../store/reducers/api/productReducer'

const firebaseStorageEnvironment = process.env.REACT_APP_FIREBASE_STORAGE_ENVIRONMENT || 'develop'

const BrandedProducts = () => {
  const [selectedProduct, setSelectedProducts] = useState<ProductCustomisation | null>(null)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadError, setUploadError] = useState<string | null>(null)
  const [uploadedFile, setUploadedFile] = useState<{'url':string, 'filename':string} | null>(null)
  const [uploadedFileBuffer, setUploadedFileBuffer] = useState<{'url':string, 'filename':string} | null>(null)
  const [activeTab, setActiveTab] = useState<string>(TabNames.BrandedProducts)
  const isLoading = useAppSelector((state) => state.apiProductCustomisationChat.status === 'loading')
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)

  const token = currentUser?.token

  const handleTab = (tab: string) => {
    setActiveTab(tab)
  }

  const handleUpload = () => {
    setUploadedFile(uploadedFileBuffer)
  }
  const { id } = useParams()
  const fileNameGenerator = (filename: string) => {
    const ext = filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2)
    return `${id}-${Date.now()}.${ext}`
  }

  const uploadCustomisationImage = async (file: File): Promise<{
    url: string
    filename: string
    size: number
    mimeType: string
  }> => {
    return new Promise((resolve, reject) => {
      const fileSizeBytes = 5 * 1024 * 1024
      if (file.size > fileSizeBytes) {
        reject(new Error('Select an image that is less than 5MB'))
        return
      }
      if (!file.type.startsWith('image/')) {
        reject(new Error('File is not an image'))
        return
      }

      const imageFileName = fileNameGenerator(file.name)
      const storageRefPath = `${firebaseStorageEnvironment}/companies/images/${imageFileName}`
      const storageRef = refFirebase(storageFirebase, storageRefPath)

      const uploadTask = uploadBytesResumableFirebase(storageRef, file)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          setUploadProgress(percentage)
        },
        (error) => {
          setUploadError(error.message)
          setUploadProgress(0)
          reject(error)
        },
        () => {
          getDownloadURLFirebase(uploadTask.snapshot.ref)
            .then((url) => {
              getMetadataFirebase(storageRef)
                .then((metadata) => {
                  const image = {
                    url,
                    filename: metadata.name,
                    size: metadata.size,
                    mimeType: metadata.contentType || file.type
                  }
                  setUploadProgress(0)
                  setUploadError(null)
                  resolve(image)
                })
                .catch(reject)
            })
            .catch(reject)
        }
      )
    })
  }
  const handleUploadBuffer = useCallback(async (file: File) => {
    try {
      const image = await uploadCustomisationImage(file)
      setUploadedFileBuffer({ url: image.url, filename: image.filename })

      setUploadProgress(100)
      setUploadError(null)
    } catch (error: any) {
      setUploadError(error.message)
    }
  }, [])
  const handleDeleteFile = () => {
    setUploadedFile(null)
  }
  const dispatch = useAppDispatch()
  const handleApproval = ({ isApproved }:{isApproved:boolean}) => {
    const controller = new AbortController()

    if (token && selectedProduct && selectedProduct.id) {
      const payload = {
        token,
        productCustomisationId: selectedProduct.id,
        productCustomisationData: { isApproved, customisationType: selectedProduct.customisationType, customisationDetail: selectedProduct.customisationDetail, designStatus: isApproved ? 'Approved' : 'Disapproved', color: selectedProduct.color, photos: selectedProduct.photos },
        signal: controller.signal
      }
      dispatch(updateProductCustomisation(payload)).then(() => {
        dismissModal('DisapproveConfirmationModal')
        dismissModal('ApproveConfirmationModal')
      })
      handleRefresh()
    }
  }
  const handleRefresh = useCallback(() => {
    if (token && id) {
      const controller = new AbortController()
      const signal = controller.signal
      dispatch(getProductCustomisations({ token, signal, productId: id, perPage: 5, page: 1 }))
      return () => controller.abort()
    }
  }, [token, id, dispatch])

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <div className="card">
          <div className="m-4 d-flex flex-column gap-3">
            <div className="navbar navbar-expand d-flex flex-column justify-content-start  align-items-start gap-2">
              <p className="h5">{activeTab === TabNames.BrandedProducts ? 'Branded Products Design Hub' : 'Project Progress'}</p>
              <div className="overflow-x-auto w-100">
                <ul className="nav nav-tabs" id="brandedProductsTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      disabled={isLoading}
                      className="nav-link active"
                      id="branded-products-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#branded-products"
                      type="button"
                      role="tab"
                      aria-controls="branded-products"
                      aria-selected="true"
                      onClick={() => handleTab(TabNames.BrandedProducts)}
                    >
                      Branded Products
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      disabled={isLoading}
                      className="nav-link"
                      id="print-preview-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#print-preview"
                      type="button"
                      role="tab"
                      aria-controls="print-preview"
                      aria-selected="false"
                      onClick={() => handleTab(TabNames.PrintPreview)}
                    >
                      Print Preview
                    </button>
                  </li>
                </ul>
                <div className="tab-content mt-2">
                  <div
                    className="tab-pane fade show active"
                    id="branded-products"
                    role="tabpanel"
                    aria-labelledby="branded-products-tab"
                    tabIndex={0}
                  >
                    <BrandedProductsViewerAndEditor
                      setSelectedProducts={setSelectedProducts}
                      handleTab={handleTab}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="print-preview"
                    role="tabpanel"
                    aria-labelledby="print-preview-tab"
                    tabIndex={0}
                  >
                    <PrintPreview
                      selectedProduct={selectedProduct}
                      isLoading={isLoading}
                      setSelectedProducts={setSelectedProducts}
                      uploadedFile = {uploadedFile}
                      handleDeleteFile = {handleDeleteFile}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="ApproveConfirmationModal"
        tabIndex={-1}
        aria-labelledby="ApproveConfirmationModal"
        aria-modal="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5
                className="modal-title text-primary"
                id="ApproveConfirmationModal"
              >
                <i className="bi bi-check-circle text-primary me-2"></i>Confirm
                Approval
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {isLoading && <Progress />}
            <div className="modal-body">
              <p>
                Are you sure you want to approve the product{' '} ?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                disabled={isLoading}
                aria-label="Approve"
                onClick={() => handleApproval({ isApproved: true })}
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="uploadNewFileModal"
        data-bs-backdrop="static"
        tabIndex={-1}
        aria-labelledby="uploadNewFileModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                id="uploadNewFileModalLabel"
              >
                <i className="bi bi-plus-circle"></i> Upload New File
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
              <div className="modal-body">
              <p>We recommend uploading a vector file for the logo upload. Vector files are AI, EPS, PDF or SVG formats.</p>
              <p>The file should not exceed 2MB.</p>
              <DropZone
                handleUpload={handleUploadBuffer}
                uploadProgress={uploadProgress}
                uploadError={uploadError}
                validWidth={48}
                validHeight={48}
              />
              </div>
              <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleUpload}
              >
                Upload
              </button>
              </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="DisapproveConfirmationModal"
        tabIndex={-1}
        aria-labelledby="DisapproveConfirmationModal"
        aria-modal="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5
                className="modal-title text-danger"
                id="DisapproveConfirmationModal"
              >
                <i className="bi bi-x-circle text-danger me-2"></i>Confirm
                Disapproval
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            {isLoading && <Progress />}
            <div className="modal-body">
              <p>
                Are you sure you want to disapprove the product?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                disabled={isLoading}
                aria-label="Disapprove"
                onClick={() => handleApproval({ isApproved: false })}
              >
                Disapprove
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default BrandedProducts
