import axios from 'axios'
import { CampaignAdditionalProductSetting } from '../../types'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  editAdditionalProductSetting (campaignAdditionalProductSettingId: string, token: string, campaignAdditionalProductSetting: CampaignAdditionalProductSetting, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/campaign-additional-product-settings/${campaignAdditionalProductSettingId}`, { campaignAdditionalProductSetting }, config)
  },
  deleteAdditionalProductSetting (campaignAdditionalProductSettingId: string, token: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/campaign-additional-product-settings/${campaignAdditionalProductSettingId}`, config)
  }
}
