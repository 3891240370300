import React from 'react'
import { Metadata } from '../types'

interface MiniPaginationProps {
  metadata: Metadata
  page: number
  handlePageChange: (page: number) => void
  showLabel?: boolean
}

const MiniPagination = ({
  metadata,
  page,
  handlePageChange,
  showLabel = true
}: MiniPaginationProps) => {
  return (
    <nav aria-label="Page navigation">
      <ul className="pagination m-0 text-nowrap">
        <li className={`page-item ${page <= 1 ? 'disabled' : ''}`}>
          <button
            disabled={page <= 1}
            className="page-link"
            onClick={() => {
              handlePageChange(page - 1)
            }}
            onDoubleClick={() => {
              handlePageChange(1)
            }}
            aria-label="Previous"
          >
            <i className="bi bi-chevron-left"></i>
            {showLabel && <span className="d-none d-md-inline-block ms-1">Previous</span>}
          </button>
        </li>
        <li className={`page-item ${page >= Math.ceil(metadata.total / metadata.limit) ? 'disabled' : ''}`}>
          <button
            disabled={page >= Math.ceil(metadata.total / metadata.limit)}
            className="page-link"
            onClick={() => {
              handlePageChange(page + 1)
            }}
            onDoubleClick={() => {
              handlePageChange(Math.ceil(metadata.total / metadata.limit))
            }}
            aria-label="Next"
          >
            {showLabel && <span className="d-none d-md-inline-block me-1">Next</span>}
            <i className="bi bi-chevron-right"></i>
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default MiniPagination
