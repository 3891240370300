import React, { useEffect, useState } from 'react'
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize
} from 'react-papaparse'
import { FieldArray, Formik, getIn } from 'formik'
import { array, date, number, object, string } from 'yup'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import Papa, { ParseError, ParseMeta } from 'papaparse'
import Calendar from 'react-datepicker'
import { styles } from '../../../pages/Campaigns/Details/styles'
import { countriesObject } from '../../../utils/countries'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { getAllSalutations } from '../../../store/reducers/api/salutationReducer'
import { getAllTitles } from '../../../store/reducers/api/titleReducer'
import {
  Bundle,
  Nullable,
  Salutation,
  Title
} from '../../../types'
import { setToast } from '../../../store/reducers/toastReducer'
import { dismissModal } from '../../../utils/dismissModal'
import Progress from '../../loaders/Progress'
import { detectOS } from '../../../utils/detectOs'
import { isWeekday } from '../../../utils/isWeekday'
import { DaysOfWeek } from '../../../enums/daysOfTheWeek'
import { phoneValidationPattern } from '../../../constants/regexPatterns'
import { getDateOfDispatchMinimumDays } from '../../../utils/getDateOfDispatchMinimumDays'
import { createCompanyBulkOrder, getCompanies, getCompanyBundles, resetPendingOrders } from '../../../store/reducers/api/companyReducer'
import * as userRoles from '../../../constants/userRoles'
import { SALUTATION_MAX_LENGTH, TITLE_MAX_LENGTH } from '../../../constants/maxAndMinFieldValues'

dayjs.extend(customParseFormat)

const bulkImportTemplateLink = String(process.env.REACT_APP_BULK_IMPORT_TEMPLATE_LINK)
const faqLink = String(process.env.REACT_APP_FAQ_LINK)
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919'
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40)

const dateOfDispatchMinimumDays = getDateOfDispatchMinimumDays()
const maximumQuantity = 1000

interface BundleImporterProps {
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>
  isAllowedToReadProducts: boolean
  isAllowedToReadBundles: boolean
  isAdditionalProductAllowed: boolean
}

const CompanyBulkOrder = ({
  setShowDialog,
  isAllowedToReadBundles
}: BundleImporterProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const salutations = useAppSelector((state) => state.apiSalutation.salutations)
  const titles = useAppSelector((state) => state.apiTitle.titles)
  const isCreatingBulkOrders = useAppSelector((state) => state.apiCompany.isCreatingOrders)
  const createdPendingOrders = useAppSelector((state) => state.apiCompany.pendingOrders)
  const orderError = useAppSelector((state) => state.apiCompany.error)
  const profile = useAppSelector((state) => state.profile.profile)
  const bundles = useAppSelector((state) => state.apiCompany.bundles)
  const isLoadingCampaignBundles = useAppSelector((state) => state.apiCompany.isLoadingBundles)
  const companies = useAppSelector((state) => state.apiCompany.companies)
  const isLoading = useAppSelector((state) => state.apiCompany.isLoading)

  const role = profile?.role

  const [zoneHover, setZoneHover] = useState(false)
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR)
  const [results, setResults] = useState<Array<any>>([])
  const [fileErrors, setFileErrors] = useState<Array<ParseError[]>>([])
  const [templateError, setTemplateError] = useState<Nullable<string>>(null)
  const [encoding, setEncoding] = useState(localStorage.getItem('encodingBulkUpload') || detectOS().encoding)
  const [clear, setClear] = useState(false)
  const [csvFile, setCsvFile] = useState<File | null>(null)
  const [filteredBundles, setFilteredBundles] = useState<Bundle[]>([])

  const token = currentUser?.token
  const [companyId, setCompanyId] = useState<Nullable<string>>(profile?.company?.id || null)
  const [isBulkCreateEnabled, setIsBulkCreateEnabled] = useState(profile?.company?.isBulkCreateEnabled || false)

  const countries = countriesObject.map(country => ({
    value: country.alpha2Code,
    label: country.country
  }))

  const baseAllowedKeys = [
    'company',
    'salutation',
    'title',
    'firstName',
    'lastName',
    'email',
    'phone',
    'street',
    'zip',
    'city',
    'addressAddition',
    'country',
    'note',
    'dateOfDispatch',
    'costCenter',
    'quantity',
    'bundle'
  ]

  const allowedKeys = [
    ...baseAllowedKeys
  ]

  const dispatch = useAppDispatch()
  const { CSVReader } = useCSVReader()

  const checkBundleExistsOrIsOutOfStock = (label: string, originalValue: string, values: any) => {
    const foundBundle = bundles.find(bundle =>
      String(bundle.merchantSku) === String(originalValue) ||
      bundle.id === String(originalValue) ||
      bundle.name === String(originalValue)
    )
    if (foundBundle) {
      return `${label} must be in stock`
    }
    return `Bundle must be one of the following values: ${values}`
  }

  const orderShape: any = {
    company: string().label('Company').nullable(),
    firstName: string()
      .label('First Name')
      .required('First Name is required')
      .min(2, (value) => `First Name must be at least ${value.min} characters`)
      .max(32, (value) => `First Name must be at most ${value.max} characters`)
      .nullable(),
    lastName: string()
      .label('Last Name')
      .required('Last Name is required')
      .min(2, (value) => `Last Name must be at least ${value.min} characters`)
      .max(32, (value) => `Last Name must be at most ${value.max} characters`)
      .nullable(),
    email: string().label('Email').email('Enter a valid email').required('Email is required').nullable(),
    quantity: number()
      .typeError((value) => `Quantity must be a number, update the provided value: ${value.originalValue}`)
      .label('Quantity')
      .required()
      .min(1, (value) => `Quantity must be greater than or equal to ${value.min}`),
    salutation: string().label('Salutation').nullable(),
    title: string().label('Title').nullable(),
    note: string()
      .label('Note')
      .max(100, (value) => `Note must be at most ${value.max} characters`)
      .default('')
      .nullable(),
    street: string().label('Street and House Number').required('Street and House Number are required').nullable(),
    city: string().label('City').required().nullable(),
    zip: string().label('Zip').required().nullable(),
    phone: string()
      .label('Phone')
      .nullable()
      .matches(phoneValidationPattern, 'Enter a valid phone number'),
    country: string()
      .label('Country')
      .required('Country is required')
      .oneOf(countriesObject.map(country => country.alpha2Code.toUpperCase()))
      .oneOf(countriesObject.map(country => country.alpha3Code.toUpperCase()))
      .oneOf(countriesObject.map(country => country.country.toUpperCase()))
      .oneOf(countriesObject.map(country => country.countryGerman.toUpperCase()))
      .transform((value, originalValue) => {
        if (originalValue) {
          originalValue.toUpperCase()
        }
        return value?.toUpperCase()
      }),
    dateOfDispatch: date()
      .typeError('Date of Dispatch is required')
      .label('Date of Dispatch')
      .min(dayjs().startOf('day').add(dateOfDispatchMinimumDays, 'days').toDate(), (value) => `Date of Dispatch field must be later than ${dayjs(value.min).format('YYYY-MM-DD HH:mm')}`)
      .transform((value, originalValue) => {
        // Move date to nearest weekday and convert the value to a Date before validating
        if (originalValue) {
          let date = dayjs(originalValue, ['DD-MM-YYYY', 'YYYY-MM-DD', 'DD.MM.YYYY']).set('hour', dayjs().hour()).set('minute', dayjs().minute()).add(15, 'minutes')
          const dayOfWeek = date.day()
          // If date is Saturday, move to next Monday
          if (dayOfWeek === DaysOfWeek.Saturday) {
            date = date.add(2, 'days')
          }
          // If date is Sunday, move to next Monday
          if (dayOfWeek === DaysOfWeek.Sunday) {
            date = date.add(1, 'day')
          }
          return date.toDate()
        }
        return value
      })
      .test('is-weekday', 'Date of Dispatch must be a weekday', (value) => {
        // Check if the date is a Saturday or Sunday
        const dayOfWeek = dayjs(value).day()
        return dayOfWeek !== DaysOfWeek.Sunday && dayOfWeek !== DaysOfWeek.Saturday
      }),
    costCenter: string().label('Cost Center').default('').nullable(),
    bundle: string()
      .label('Bundle')
      .required('Bundle is required')
      .oneOf(
        filteredBundles.filter(filteredBundle => filteredBundle.merchantSku)
          .map(filteredBundle => filteredBundle.merchantSku),
        (value) => `${checkBundleExistsOrIsOutOfStock(value.label, value.originalValue, value.values)}`
      )
      .oneOf(
        filteredBundles.map(filteredBundle => filteredBundle.id),
        (value) => `${checkBundleExistsOrIsOutOfStock(value.label, value.originalValue, value.values)}`
      )
      .oneOf(
        filteredBundles.map(filteredBundle => filteredBundle.name),
        (value) => `${checkBundleExistsOrIsOutOfStock(value.label, value.originalValue, value.values)}`
      )
      .nullable()
  }

  const ordersSchema = object().shape({
    orders: array()
      .of(object(orderShape))
      .required('Orders are required')
      .min(1, 'Upload a csv with at least 1 order'),
    hasAgreed: string().oneOf(
      ['yes'],
      'We want to make sure you understand our policies. Please read and agree to our terms and conditions before continuing.'
    )
  })

  const ordersSchemaAdmin = object().shape({
    orders: array()
      .of(object(orderShape))
      .required('Orders are required')
      .min(1, 'Upload a csv with at least 1 order'),
    hasAgreed: string().oneOf(
      ['yes'],
      'We want to make sure you understand our policies. Please read and agree to our terms and conditions before continuing.'
    ),
    companyId: string().label('Company')
      .when([], {
        is: () => true,
        then: string().required('Company is required').nullable(),
        otherwise: string().optional().nullable()
      })
  })

  const getAvailableBundleStock = (bundleId: string) => {
    const foundBundle = bundles.find(bundle => bundle.id === bundleId)
    if (foundBundle) {
      const foundBundleStock = foundBundle.stock
      if (foundBundleStock) {
        return Math.max(
          (foundBundleStock?.stockLevel || 0) - (foundBundleStock?.stockLevelReserved || 0),
          0
        )
      }
      return 0
    }
    return 0
  }

  const errorParagraphs = (errors: any) => {
    if (typeof errors === 'string') {
      return <li className="list-group-item text-danger small">{errors}</li>
    }

    if (typeof errors === 'object') {
      return errors.map((error: any, index: number) => {
        if (typeof error === 'object' && error !== null) {
          const errorMessage: any = Object.values(error)[0]
          return (
            <li key={`errors-${index}`} className="list-group-item text-danger small">
              Row {index + 1}: {errorMessage}
            </li>
          )
        }
        return null
      })
    }
    return null
  }

  const headers = (key: string) => {
    const required = ['city', 'zip']
    const data: any = {
      company: {
        text: 'Company',
        type: 'text',
        width: 200
      },
      firstName: {
        text: 'First Name*',
        type: 'text'
      },
      lastName: {
        text: 'Last Name*',
        type: 'text'
      },
      email: {
        text: 'Email*',
        type: 'text',
        width: 280
      },
      salutation: {
        text: 'Salutation',
        type: 'select-creatable',
        width: 150,
        options: salutations.map((salutation: Salutation) => ({
          label: salutation.name,
          value: salutation.name
        })),
        maxLength: SALUTATION_MAX_LENGTH
      },
      title: {
        text: 'Title',
        type: 'select-creatable',
        width: 150,
        options: titles.map((title: Title) => ({
          label: title.name,
          value: title.name
        })),
        maxLength: TITLE_MAX_LENGTH
      },
      street: {
        text: 'Street + House Number*',
        type: 'text',
        width: 250
      },
      country: {
        text: 'Country*',
        type: 'select-search',
        options: countries,
        width: 180
      },
      addressAddition: {
        text: 'Address Addition',
        type: 'text'
      },
      costCenter: {
        text: 'Cost Center',
        type: 'text'
      },
      note: {
        text: 'Note',
        type: 'textarea',
        width: 300
      },
      quantity: {
        text: 'Quantity*',
        type: 'number',
        width: 80,
        min: 1
      },
      dateOfDispatch: {
        text: 'Date of Dispatch*',
        type: 'date',
        min: dayjs().add(dateOfDispatchMinimumDays, 'day').toDate(),
        placeholder: 'Select a weekday'
      },
      bundle: {
        text: 'Bundle*',
        type: 'select',
        options: bundles.map(bundle => ({
          label: bundle.name,
          value: bundle.merchantSku || bundle.id
        }))
      }
    }
    if (key in data) {
      return data[key]
    }
    const text = required.includes(key) ? `${key}*` : key
    return {
      text,
      type: 'text'
    }
  }

  const renderInputs = (
    index: number,
    value: any,
    key: any,
    errors: any,
    handleChange: any,
    handleBlur: any,
    setFieldValue: any
  ) => {
    const className = `form-control ${getIn(errors.orders, `[${index}].${key}`) ? 'is-invalid' : ''}`
    const ariaLabel = headers(key).text || key

    if (headers(key).type === 'textarea') {
      return (
        <textarea
          className={className}
          name={`orders[${index}].${key}`}
          aria-label={ariaLabel}
          value={value[key] ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          style={{ minWidth: headers(key).width || 150 }}
          rows={1}
        />
      )
    }

    if (headers(key).type === 'number') {
      if (key === 'quantity') {
        let validatedValue = value[key]
        if (isNaN(Number(validatedValue))) {
          validatedValue = ''
        } else {
          const foundBundle = bundles.find(bundle =>
            String(bundle.merchantSku) === String(value.bundle) ||
            bundle.id === value.bundle ||
            bundle.name === value.bundle
          )
          const campaign = foundBundle?.campaign
          if (campaign?.isExceedStockEnabled) {
            validatedValue = Math.max(Math.min(validatedValue, maximumQuantity), 1)
          } else {
            if (foundBundle) {
              const availableStock = getAvailableBundleStock(foundBundle.id)
              const max = availableStock
              validatedValue = Math.max(Math.min(validatedValue, max), 1)
            } else {
              validatedValue = 0
            }
          }
        }
        return (
          <input
            className={className}
            type={headers(key).type}
            name={`orders[${index}].${key}`}
            aria-label={ariaLabel}
            value={validatedValue}
            onChange={(e) => {
              const selectedValue = Number(e.target.value)
              const foundBundle = bundles.find(bundle =>
                String(bundle.merchantSku) === String(value.bundle) ||
                bundle.id === value.bundle ||
                bundle.name === value.bundle
              )
              const campaign = foundBundle?.campaign
              if (campaign?.isExceedStockEnabled) {
                setFieldValue(`orders[${index}].${key}`, Math.max(Math.min(selectedValue, maximumQuantity), 1))
              } else {
                if (foundBundle) {
                  const availableStock = getAvailableBundleStock(foundBundle.id)
                  const max = availableStock
                  const quantity = Math.max(Math.min(Number(selectedValue), max), 1)
                  setFieldValue(`orders[${index}].${key}`, quantity)
                }
              }
            }}
            onBlur={handleBlur}
            style={{ minWidth: headers(key).width || 150 }}
            min={headers(key).min}
          />
        )
      }
      return (
        <input
          className={className}
          type={headers(key).type}
          name={`orders[${index}].${key}`}
          aria-label={ariaLabel}
          value={value[key] ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          style={{ minWidth: headers(key).width || 150 }}
          min={headers(key).min}
        />
      )
    }

    if (headers(key).type === 'select') {
      let initialValue = value[key] ?? ''
      if (key === 'country') {
        const countryValue = initialValue.toUpperCase()
        initialValue = countriesObject
          .find(item =>
            item.alpha2Code.toUpperCase() === countryValue ||
            item.alpha3Code.toUpperCase() === countryValue ||
            item.country.toUpperCase() === countryValue ||
            item.countryGerman.toUpperCase() === countryValue
          )?.alpha2Code
      }

      if (key === 'bundle') {
        const foundBundle = bundles.find(bundle =>
          String(bundle.merchantSku) === String(value[key]) ||
          bundle.id === value[key] ||
          bundle.name === value[key]
        )
        initialValue = foundBundle?.merchantSku || foundBundle?.id || ''
      }

      return (
        <select
          className={className}
          name={`orders[${index}].${key}`}
          aria-label={ariaLabel}
          onChange={handleChange}
          onBlur={handleBlur}
          value={initialValue}
          style={{ minWidth: headers(key).width || 150 }}
        >
          <option value="">{`Select ${headers(key).text || key}`}</option>
          {headers(key).options.map((option: { label: string, value: any }, index: number) => (
            <option key={`${index}-name`} value={option.value}>{option.label}</option>
          ))}
        </select>
      )
    }

    if (headers(key).type === 'select-search') {
      let initialValue = value[key] ?? ''
      if (key === 'country') {
        const countryValue = initialValue.toUpperCase()
        initialValue = countriesObject
          .find(item =>
            item.alpha2Code.toUpperCase() === countryValue ||
            item.alpha3Code.toUpperCase() === countryValue ||
            item.country.toUpperCase() === countryValue ||
            item.countryGerman.toUpperCase() === countryValue
          )?.alpha2Code
      }
      return (
        <Select
          menuPosition="fixed"
          name={`orders[${index}].${key}`}
          options={headers(key).options}
          onChange={(selectedOption: any) => {
            setFieldValue(`orders[${index}].${key}`, selectedOption.value)
          }}
          value={headers(key).options.find((option: { value: string, label: string }) => option.value === initialValue) || null}
          className={`${getIn(errors.orders, `[${index}].${key}`) ? 'is-invalid' : ''}`}
          styles={{
            control: (provided, state) => ({
              ...provided,
              minWidth: headers(key).width,
              borderColor: getIn(errors.orders, `[${index}].${key}`) ? '#dc3545' : provided.borderColor,
              '&:hover': {
                boxShadow: getIn(errors.orders, `[${index}].${key}`) ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                borderColor: getIn(errors.orders, `[${index}].${key}`) ? '#dc3545' : '#86b7fe'
              }
            })
          }}
        />
      )
    }

    if (headers(key).type === 'date') {
      let selectedDate = dayjs(dayjs(value[key], ['DD-MM-YYYY', 'YYYY-MM-DD', 'DD.MM.YYYY']).format('YYYY-MM-DD'))
      if (selectedDate.isValid()) {
        selectedDate = dayjs(dayjs(value[key], ['DD-MM-YYYY', 'YYYY-MM-DD', 'DD.MM.YYYY']).format('YYYY-MM-DD'))
      } else {
        selectedDate = dayjs(dayjs().format('YYYY-MM-DD'))
      }
      const dayOfWeek = selectedDate.day()
      // If date is Saturday, move to next Monday
      if (dayOfWeek === DaysOfWeek.Saturday) {
        selectedDate = selectedDate.add(2, 'days')
      }
      // If date is Sunday, move to next Monday
      if (dayOfWeek === DaysOfWeek.Sunday) {
        selectedDate = selectedDate.add(1, 'day')
      }

      return (
        <div style={{ minWidth: headers(key).width || 150 }}>
          <Calendar
            id={`orders[${index}].${key}`}
            name={`orders[${index}].${key}`}
            onChange={(date: Date) => {
              if (date) {
                setFieldValue(`orders[${index}].${key}`, dayjs(date).format())
              } else {
                setFieldValue(`orders[${index}].${key}`, date)
              }
            }}
            selected={value[key] ? selectedDate.toDate() : null}
            className={className}
            minDate={headers(key).min}
            dateFormat={'dd/MM/yyyy'}
            autoComplete={'off'}
            filterDate={isWeekday}
            placeholderText={headers(key)?.placeholder || 'Select a weekday'}
            calendarStartDay={DaysOfWeek.Monday}
          />
        </div>
      )
    }

    if (headers(key).type === 'select-creatable') {
      return (
        <CreatableSelect
          menuPosition="fixed"
          isClearable={true}
          components={{
            Input: (props) => (
              <components.Input {...props} maxLength={headers(key).maxLength} />
            )
          }}
          inputId={`orders[${index}].${key}`}
          aria-label={ariaLabel}
          name={`orders[${index}].${key}`}
          options={headers(key).options}
          value={value[key] ? { value: value[key], label: value[key] } : null}
          onChange={(selectedOption) => {
            setFieldValue(`orders[${index}].${key}`, selectedOption ? selectedOption.value : '')
          }}
          onBlur={handleBlur}
          className={`${getIn(errors.orders, `[${index}].${key}`) ? 'is-invalid' : ''}`}
          styles={{
            control: (provided, state) => ({
              ...provided,
              minWidth: headers(key).width,
              borderColor: getIn(errors.orders, `[${index}].${key}`) ? '#dc3545' : provided.borderColor,
              '&:hover': {
                boxShadow: getIn(errors.orders, `[${index}].${key}`) ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                borderColor: getIn(errors.orders, `[${index}].${key}`) ? '#dc3545' : '#86b7fe'
              }
            })
          }}
        />
      )
    }

    return (
      <input
        className={className}
        type={headers(key).type}
        name={`orders[${index}].${key}`}
        aria-label={ariaLabel}
        value={value[key] ?? ''}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{ minWidth: headers(key).width || 150 }}
        multiple={false}
      />
    )
  }

  const formatOrder = (order: any) => {
    const activeBundle = bundles.find(
      (bundle) =>
        String(bundle.merchantSku) === String(order.bundle) ||
            bundle.id === order.bundle ||
            bundle.name === order.bundle
    ) as Bundle

    if (!activeBundle) {
      throw new Error('Bundle not found')
    }

    const formattedOrder = {
      currency: 'EUR',
      orderNo: '0',
      shipped: dayjs(order.dateOfDispatch, ['DD-MM-YYYY', 'YYYY-MM-DD', 'DD.MM.YYYY'])
        .startOf('day')
        .format(),
      deliverydate: dayjs(order.dateOfDispatch, ['DD-MM-YYYY', 'YYYY-MM-DD', 'DD.MM.YYYY'])
        .startOf('day')
        .format(),
      note: order.note ?? null,
      costCenter: order.costCenter ? String(order.costCenter) : '',
      quantity: Number(order.quantity),
      orderLineRequests: [
        {
          itemName: activeBundle.name,
          articleNumber: String(activeBundle.merchantSku),
          itemNetSale: 0,
          itemVAT: 0,
          quantity: Number(order.quantity),
          type: 0,
          discount: 0,
          netPurchasePrice: 0
        }
      ],

      shippingAddressRequests: [
        {
          salutation: order.salutation ?? null,
          firstName: order.firstName ?? null,
          lastName: order.lastName ?? null,
          title: order.title ?? '',
          company: order.company ?? null,
          companyAddition: '',
          street: order.street ?? null,
          addressAddition: order.addressAddition ?? null,
          zipCode: String(order.zip) ?? '',
          place: order.city ?? null,
          phone: order.phone ?? null,
          state: '',
          country: order.country ?? null,
          iso: '',
          telephone: '',
          mobile: '',
          fax: '',
          email: order.email ?? null
        }
      ]
    }

    return formattedOrder
  }

  const refreshBundles = (companyId: string) => {
    const pageBundle = 1
    const perPageBundle = 100
    const controller = new AbortController()
    const signal = controller.signal

    if (token && companyId && isAllowedToReadBundles) {
      dispatch(getCompanyBundles({ companyId, token, page: pageBundle, perPage: perPageBundle, signal }))
    }
  }

  useEffect(() => {
    const length = createdPendingOrders?.length || 0
    if (length > 0) {
      const payload = {
        title: 'Success',
        message: `${length} ${length === 1 ? 'order' : 'orders'} created successfully`,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      setShowDialog(false)
      setResults([])
      setClear(!clear)
      dispatch(resetPendingOrders())
      dismissModal('bulkCreateOrderModal')
    }
  }, [createdPendingOrders])

  useEffect(() => {
    if (orderError && orderError?.errors.message) {
      const payload = {
        title: 'Error',
        message: orderError.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
    }
  }, [orderError])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getAllSalutations({ token, perPage: 100, page: 1, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getAllTitles({ token, perPage: 100, page: 1, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if (templateError) {
      const payload = {
        title: 'Info',
        message: templateError,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'info'
      }
      dispatch(setToast(payload))
    }
    setTemplateError(null)
  }, [templateError])

  useEffect(() => {
    companyId && refreshBundles(companyId)
  }, [companyId])

  useEffect(() => {
    const filtered = bundles.filter(bundle => bundle.campaign?.isExceedStockEnabled || getAvailableBundleStock(bundle.id) > 0)
    setFilteredBundles(filtered)
  }, [bundles])

  useEffect(() => {
    const controller = new AbortController()
    const perPage = 100
    const page = 1
    const signal = controller.signal
    token && dispatch(getCompanies({ token, perPage, page, signal }))
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <div className="main">
      <div className="row mb-2">
        <div className="d-flex flex-row justify-content-between">
          <a href={bulkImportTemplateLink} className="link-primary">
            Bulk Import Template Link
          </a>
          <a href={faqLink} target="_blank" className="link-primary" rel="noreferrer">
            FAQ
          </a>
        </div>
      </div>
      <div className="col mb-3">
        <label className="fw-semibold" htmlFor="encodingSelect">
          Change the encoding if your data is not rendered correctly:
        </label>
        <select
          id="encodingSelect"
          className="form-select"
          aria-label="Select encoding"
          value={encoding}
          onChange={(event) => {
            localStorage.setItem('encodingBulkUpload', event.target.value)
            setEncoding(event.target.value)
            if (csvFile) {
              Papa.parse(csvFile, {
                encoding: event.target.value,
                header: true,
                dynamicTyping: true,
                skipEmptyLines: true,
                transformHeader: (header: string) => header.trim(),
                transform: (value: string) => value.trim(),
                complete: function (results) {
                  setResults(results.data)
                  results.errors.length > 0 && setFileErrors([results.errors])
                }
              })
            }
          }}
        >
          <option value="ISO-8859-1">ISO-8859-1</option>
          <option value="UTF-8">UTF-8</option>
        </select>
      </div>
      <CSVReader
        disabled={(isLoadingCampaignBundles || !isBulkCreateEnabled)}
        key={clear}
        config={{
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
          encoding,
          transformHeader: (header: string) => header.trim(),
          transform: (value: string) => value.trim()
        }}
        onUploadAccepted={(
          results: { data: Array<any>; errors: Array<ParseError[]>; meta: Array<ParseMeta> },
          file: File
        ) => {
          const foundKeys = Object.keys(results.data[0])
          const areAllowedKeysPresent = foundKeys.every((item) => {
            if (item.includes('additionalItem')) return true
            if (item.includes('startDate')) return true
            return allowedKeys.includes(item)
          })

          if (areAllowedKeysPresent) {
            setCsvFile(file)
            const rows = results.data.map(item => {
              const newItem = { ...item }

              return newItem
            })
            setResults(rows)
            setFileErrors(results.errors)
          } else {
            setTemplateError(
              'A new template is available. Download the template from the link provided in the modal in order to continue.'
            )
          }
          setZoneHover(false)
        }}
        onDragOver={(event: DragEvent) => {
          event.preventDefault()
          setZoneHover(true)
        }}
        onDragLeave={(event: DragEvent) => {
          event.preventDefault()
          setZoneHover(false)
        }}
      >
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }: any) => (
          <>
            <div
              {...getRootProps()}
              style={Object.assign({}, styles.zone, zoneHover && styles.zoneHover)}
            >
              {acceptedFile
                ? (
                  <>
                    <div style={styles.file}>
                      <div style={styles.info}>
                        <span style={styles.size}>
                          {formatFileSize(acceptedFile.size)}
                        </span>
                        <span style={styles.name}>{acceptedFile.name}</span>
                      </div>
                      <div style={styles.progressBar}>
                        <ProgressBar />
                      </div>
                      <div
                        {...getRemoveFileProps()}
                        style={styles.remove}
                        onMouseOver={(event: Event) => {
                          event.preventDefault()
                          setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT)
                        }}
                        onMouseOut={(event: Event) => {
                          event.preventDefault()
                          setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR)
                        }}
                        onClick={(event: Event) => {
                          getRemoveFileProps()?.onClick(event)
                          setCsvFile(null)
                          setResults([])
                          setFileErrors([])
                        }}
                      >
                        <Remove color={removeHoverColor} />
                      </div>
                    </div>
                  </>
                  )
                : (
                    isLoadingCampaignBundles
                      ? 'Loading...'
                      : (
                          isBulkCreateEnabled
                            ? 'Drop CSV file here or click to upload'
                            : <div className="text-danger">
                              Bulk create is not enabled for selected company
                            </div>
                        )
                  )
              }
              {((isLoadingCampaignBundles)) && <Progress />}
            </div>
          </>
        )}
      </CSVReader>
      <Formik
        key={companyId}
        validationSchema={(role === userRoles.ADMIN) ? ordersSchemaAdmin : ordersSchema}
        enableReinitialize={true}
        initialValues={{
          orders: results,
          hasAgreed: 'no',
          companyId
        }}
        onSubmit={(values, actions): void => {
          const pendingOrders = values.orders.map((order: any) => formatOrder(order))
          const controller = new AbortController()
          const signal = controller.signal

          if (token && companyId) {
            dispatch(createCompanyBulkOrder({ companyId, token, pendingOrders, signal }))
          }
          actions.setSubmitting(false)
        }}
      >
        {({ values, handleChange, handleSubmit, errors, handleBlur, isSubmitting, setFieldValue }) => {
          const rowKeys = values.orders.length > 0
            ? Object.keys(values.orders[0]).filter(key => !key.includes('additionalItem') && !key.includes('startDate'))
            : []

          return (
            <div className="mt-4">
              {role === userRoles.ADMIN && (
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label htmlFor="company" className="form-label">
                        Company
                      </label>
                      <Select
                        className={errors.companyId ? 'is-invalid' : ''}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: errors.companyId ? '#dc3545' : provided.borderColor,
                            '&:hover': {
                              borderColor: errors.companyId ? '#dc3545' : provided.borderColor
                            }
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 1050,
                            position: 'absolute'
                          })
                        }}
                        isClearable
                        inputId="company"
                        name="companyId"
                        aria-label="Company"
                        options={companies}
                        getOptionLabel={(company) => `${company.name} - ${company.domain ?? 'Domain not set'}`}
                        getOptionValue={(company) => String(company.id)}
                        onChange={(selectedOption) => {
                          setCsvFile(null)
                          setResults([])
                          setFileErrors([])
                          setClear(!clear)
                          setFieldValue('companyId', selectedOption?.id ?? '')
                          setCompanyId(selectedOption?.id ?? null)
                          if (selectedOption?.id) {
                            setIsBulkCreateEnabled(selectedOption.isBulkCreateEnabled ?? false)
                          } else {
                            setIsBulkCreateEnabled(false)
                          }
                        }}
                        value={companies.find(company => company.id === values.companyId) || null}
                        onBlur={handleBlur}
                        isLoading={isLoading}
                      />
                      <div id="validationCompanyFeedback" className="invalid-feedback">
                        {errors.companyId}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="table-responsive">
              <table className="table table-hover">
                <thead className="sticky-top">
                  <tr>
                    {values.orders.length > 0 && <th>#</th>}
                    {values.orders.length > 0 &&
                      rowKeys.map((key, index) => {
                        if (!key.includes('additionalItem') && !key.includes('startDate')) {
                          return (
                            <th key={`header-${key}-${index}`} className="text-capitalize" scope="col">
                              {headers(key).text} <span className="small">{headers(key).secondaryText}</span>
                            </th>
                          )
                        }
                        return null
                      })}
                    {values.orders.length > 0 && <th>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  <FieldArray
                    name="orders"
                    render={arrayHelpers => (
                      values.orders.length > 0
                        ? (
                            values.orders.map((value, index) => {
                              return (
                                <tr key={`order-${index}-${values.companyId || companyId}`}>
                                  <td className={getIn(errors.orders, `${index}`) ? 'text-danger fw-bold' : ''}>
                                    {index + 1}
                                  </td>
                                  {Object.keys(value)
                                    .filter(key => !key.includes('additionalItem') && !key.includes('startDate'))
                                    .map(key => (
                                      <td key={`input-${key}-${index}`}>
                                        {renderInputs(index, value, key, errors, handleChange, handleBlur, setFieldValue)}
                                      </td>
                                    ))}
                                  <td>
                                    <div className="btn-group" role="group" aria-label="Order Action Buttons">
                                      <button
                                        type="button"
                                        title={`Remove Order on Row ${index + 1}`}
                                        className="btn"
                                        onClick={() => {
                                          setFileErrors([])
                                          arrayHelpers.remove(index)
                                        }}
                                        disabled={values.orders.length === 1}
                                      >
                                        <i className="bi bi-dash-circle text-danger"></i>
                                      </button>
                                      {index === values.orders.length - 1 && (
                                        <button
                                          type="button"
                                          title="Add New Order Row"
                                          className="btn"
                                          onClick={() => {
                                            arrayHelpers.push({
                                              company: '',
                                              salutation: '',
                                              title: '',
                                              firstName: '',
                                              lastName: '',
                                              email: '',
                                              phone: '',
                                              street: '',
                                              zip: '',
                                              city: '',
                                              addressAddition: '',
                                              country: '',
                                              note: '',
                                              dateOfDispatch: dayjs().add(dateOfDispatchMinimumDays, 'days').format('YYYY-MM-DD'),
                                              costCenter: '',
                                              quantity: 1,
                                              bundle: ''
                                            })
                                          }}
                                          disabled={false}
                                        >
                                          <i className="bi bi-plus-circle text-secondary"></i>
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          )
                        : null
                    )}
                  />
                </tbody>
              </table>

              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3 px-1">
                    <p className="mt-2">
                      By creating an order, you agree to our{' '}
                      <button
                        className="btn btn-link text-primary m-0 p-0"
                        data-bs-toggle="modal"
                        type="button"
                        data-bs-target="#termsModal"
                      >
                        terms and conditions
                      </button>.
                    </p>
                    <div className={`form-check form-check-inline ${errors.hasAgreed ? 'is-invalid' : ''}`}>
                      <input
                        className={`form-check-input ${errors.hasAgreed ? 'is-invalid' : ''}`}
                        type="radio"
                        name="hasAgreed"
                        id="inlineRadioYes"
                        onChange={handleChange}
                        value="yes"
                        checked={values.hasAgreed === 'yes'}
                      />
                      <label className="form-check-label" htmlFor="inlineRadioYes">
                        Yes
                      </label>
                    </div>
                    <div className={`form-check form-check-inline ${errors.hasAgreed ? 'is-invalid' : ''}`}>
                      <input
                        className={`form-check-input ${errors.hasAgreed ? 'is-invalid' : ''}`}
                        type="radio"
                        name="hasAgreed"
                        id="inlineRadioNo"
                        onChange={handleChange}
                        value="no"
                        checked={values.hasAgreed === 'no'}
                      />
                      <label className="form-check-label" htmlFor="inlineRadioNo">
                        No
                      </label>
                    </div>
                    <div id="validationHasAgreedFeedback" className="invalid-feedback">
                      {errors.hasAgreed}
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-1">
                {Object.keys(errors).length > 0 && (
                  <div className="text-danger small">
                    Kindly check the imported data and fix any errors
                  </div>
                )}
                {fileErrors.length > 0 && (
                  <ul className="list-group mt-2">
                    {fileErrors.map((fileError, index) => (
                      <li className="list-group-item text-danger small" key={`file-error-${index}`}>
                        Row {fileError[0].row + 1}: {fileError[0].message}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="py-1">
                {Object.keys(errors).length > 0 && (
                  <ul className="list-group mt-2">{errorParagraphs(errors.orders)}</ul>
                )}
              </div>
              {isCreatingBulkOrders && <Progress marginBottom={false} />}
              <div className="text-end py-2">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={() => {
                    if (!isBulkCreateEnabled) {
                      const payload = {
                        title: 'Bulk Create Disabled',
                        message: 'Bulk create is not enabled for selected company',
                        isVisible: true,
                        timestamp: dayjs().format('LT'),
                        type: 'warning'
                      }
                      dispatch(setToast(payload))
                    } else {
                      handleSubmit()
                    }
                  }}
                  disabled={isSubmitting || isCreatingBulkOrders || !isBulkCreateEnabled}
                >
                  Create Orders
                </button>
              </div>
            </div>
          )
        }}
      </Formik>
    </div>
  )
}

export default CompanyBulkOrder
