import React, { useEffect } from 'react'
import { object, string } from 'yup'
import { useFormik } from 'formik'
import AsyncSelect from 'react-select/async'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { countries } from '../../utils/countries'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Recipient, Salutation, Title } from '../../types'
import { openModal } from '../../utils/openModal'
import { dismissModal } from '../../utils/dismissModal'
import { RECIPIENT_ADDITION_SUCCESS_MESSAGE } from '../../constants/messages'
import { phoneValidationPattern } from '../../constants/regexPatterns'
import { SALUTATION_MAX_LENGTH, TITLE_MAX_LENGTH } from '../../constants/maxAndMinFieldValues'
interface RecipientEditorProps {
  id: string
  initialRecipient: Recipient
  save: Function
  loadCostCenterOptionsDebounced: (...args: any[]) => void
  setIsAddingNewRecipient: React.Dispatch<React.SetStateAction<boolean>>
  isAddingNewRecipient: boolean
}

const RecipientEditor = ({
  id,
  initialRecipient,
  save,
  loadCostCenterOptionsDebounced,
  setIsAddingNewRecipient,
  isAddingNewRecipient
}: RecipientEditorProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiCampaign.isLoadingRecipients)
  const isUpdating = useAppSelector((state) => state.apiRecipient.isLoading)
  const salutations = useAppSelector((state) => state.apiSalutation.salutations)
  const titles = useAppSelector((state) => state.apiTitle.titles)
  const message = useAppSelector((state) => state.apiCampaign.message)

  const token = currentUser?.token

  const dispatch = useAppDispatch()
  const recipientSchema = object({
    companyName: string()
      .min(2, 'Enter a valid company name')
      .max(32, 'Company Name is too long'),
    salutation: string().label('Salutation').max(SALUTATION_MAX_LENGTH),
    title: string().label('Title').max(TITLE_MAX_LENGTH),
    firstName: string()
      .min(2, 'Enter a valid first name')
      .max(32, 'First Name is too long')
      .when('companyName', {
        is: (companyName: string) => !companyName,
        then: string().required('First name is required'),
        otherwise: string()
      }),
    lastName: string()
      .min(2, 'Enter a valid last name')
      .max(32, 'Last Name is too long')
      .when(['companyName'], {
        is: (companyName: string) => !companyName,
        then: string().required('Last name is required'),
        otherwise: string()
      }),
    email: string().email('Enter a valid email'),
    phone: string()
      .nullable()
      .matches(phoneValidationPattern, 'Enter a valid phone number'),
    country: string().required('Country is required').oneOf(countries),
    city: string()
      .required('City is required')
      .max(32, 'City name is too long'),
    street: string()
      .required('Street and House Number are required')
      .max(64, 'Street and House Number are too long'),
    zip: string().label('Zip')
      .required('Zip is required')
      .max(32, 'Zip is too long'),
    addressAddition: string(),
    costCenter: string()
  })

  const saveRecipient = (campaignId: string, recipient: any, signal: AbortSignal) => {
    dispatch(save({ id: campaignId, token, recipient, signal }))
  }

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    isSubmitting,
    resetForm
  } = useFormik({
    initialValues: {
      ...initialRecipient,
      companyName: initialRecipient.companyName ?? '',
      salutation: initialRecipient.salutation ?? '',
      title: initialRecipient.title ?? '',
      firstName: initialRecipient.firstName ?? '',
      lastName: initialRecipient.lastName ?? '',
      phone: initialRecipient.phone ?? '',
      addressAddition: initialRecipient.addressAddition ?? '',
      costCenter: initialRecipient.costCenter ?? ''
    },
    onSubmit: (
      { companyName, salutation, title, firstName, lastName, email, phone, country, city, street, zip, addressAddition, costCenter },
      actions
    ) => {
      const controller = new AbortController()
      const signal = controller.signal
      const recipient = {
        companyName: companyName || null,
        salutation: salutation || null,
        title: title || null,
        firstName: firstName || null,
        lastName: lastName || null,
        email,
        phone: phone || null,
        country,
        city,
        street,
        zip,
        addressAddition: addressAddition || null,
        costCenter: costCenter || null
      }
      if (token && id) {
        saveRecipient(id, recipient, signal)
      }
      actions.setSubmitting(false)
    },
    validationSchema: recipientSchema,
    enableReinitialize: true
  })

  useEffect(() => {
    if (message === RECIPIENT_ADDITION_SUCCESS_MESSAGE) {
      resetForm()
    }
  }, [message])

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <div className="mb-3">
              <label
                htmlFor="recipientCompanyName"
                className="form-label"
              >
                Company Name
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.companyName}
                type="text"
                className={`form-control ${
                  errors.companyName &&
                  touched.companyName &&
                  errors.companyName
                    ? 'is-invalid'
                    : ''
                }`}
                id="recipientCompanyName"
                name="companyName"
                placeholder=""
              />
              <div
                id="validationRecipientCompanyNameFeedback"
                className="invalid-feedback"
              >
                {errors.companyName}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="recipientSalutation" className="form-label">
                Salutation
              </label>
              <div className="input-group">
                <CreatableSelect
                  components={{
                    Input: (props) => (
                      <components.Input {...props} maxLength={SALUTATION_MAX_LENGTH} />
                    )
                  }}
                  inputId="recipientSalutation"
                  aria-label="Salutation"
                  name="salutation"
                  options={salutations.map((salutation: Salutation) => ({
                    value: salutation.name,
                    label: salutation.name
                  }))}
                  value={
                    values.salutation
                      ? { value: values.salutation, label: values.salutation }
                      : null
                  }
                  onChange={(selectedOption) =>
                    setFieldValue('salutation', selectedOption ? selectedOption.value : '')
                  }
                  onBlur={handleBlur}
                  className={`flex-grow-1 ${touched.salutation && errors.salutation ? 'is-invalid' : ''}`}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: (errors.salutation && touched.salutation) ? '#dc3545' : provided.borderColor,
                      '&:hover': {
                        boxShadow: (errors.salutation && touched.salutation) ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                        borderColor: (errors.salutation && touched.salutation) ? '#dc3545' : '#86b7fe'
                      }
                    })
                  }}
                  placeholder="Select or type"
                  isClearable
                />
                <div
                  id="validationSalutationFeedback"
                  className="invalid-feedback"
                >
                  {errors.salutation}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="recipientTitle" className="form-label">
                Title
              </label>
              <div className="input-group">
                <CreatableSelect
                  components={{
                    Input: (props) => (
                      <components.Input {...props} maxLength={TITLE_MAX_LENGTH} />
                    )
                  }}
                  inputId="recipientTitle"
                  aria-label="Title"
                  name="title"
                  options={titles.map((title: Title) => ({
                    value: title.name,
                    label: title.name
                  }))}
                  value={
                    values.title
                      ? { value: values.title, label: values.title }
                      : null
                  }
                  onChange={(selectedOption) =>
                    setFieldValue('title', selectedOption ? selectedOption.value : '')
                  }
                  onBlur={handleBlur}
                  className={`flex-grow-1 ${touched.title && errors.title ? 'is-invalid' : ''}`}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: (errors.title && touched.title) ? '#dc3545' : provided.borderColor,
                      '&:hover': {
                        boxShadow: (errors.title && touched.title) ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                        borderColor: (errors.title && touched.title) ? '#dc3545' : '#86b7fe'
                      }
                    })
                  }}
                  placeholder="Select or type"
                  isClearable
                />
                <div
                  id="validationTitleFeedback"
                  className="invalid-feedback"
                >
                  {errors.title}
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="row">
          <div className="col">
            <div className="mb-3">
              <label
                htmlFor="recipientFirstName"
                className="form-label"
              >
                First Name
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                type="text"
                className={`form-control ${
                  errors.firstName &&
                  touched.firstName &&
                  errors.firstName
                    ? 'is-invalid'
                    : ''
                }`}
                id="recipientFirstName"
                name="firstName"
                placeholder=""
              />
              <div
                id="validationRecipientFirstNameFeedback"
                className="invalid-feedback"
              >
                {errors.firstName}
              </div>
            </div>
          </div>
          <div className="col">
            <div className="mb-3">
              <label
                htmlFor="recipientLastName"
                className="form-label"
              >
                Last Name
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                type="text"
                className={`form-control ${
                  errors.lastName &&
                  touched.lastName &&
                  errors.lastName
                    ? 'is-invalid'
                    : ''
                }`}
                id="recipientLastName"
                name="lastName"
                placeholder=""
              />
              <div
                id="validationRecipientLastNameFeedback"
                className="invalid-feedback"
              >
                {errors.lastName}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label
                htmlFor="recipientEmail"
                className="form-label"
              >
                Email Address
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                type="email"
                className={`form-control ${
                  errors.email &&
                  touched.email &&
                  errors.email
                    ? 'is-invalid'
                    : ''
                }`}
                id="recipientEmail"
                name="email"
                placeholder=""
                autoComplete="off"
              />
              <div
                id="validationRecipientEmailFeedback"
                className="invalid-feedback"
              >
                {errors.email}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="recipientPhone" className="form-label">
                Phone
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                type="text"
                className={`form-control ${
                  errors.phone &&
                  touched.phone &&
                  errors.phone
                    ? 'is-invalid'
                    : ''
                }`}
                id="recipientPhone"
                name="phone"
                placeholder=""
                autoComplete="off"
              />
              <div
                id="validationRecipientPhoneFeedback"
                className="invalid-feedback"
              >
                {errors.phone}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="mb-3">
              <label htmlFor="recipientCountry" className="form-label">
                Country
              </label>
              <Select
                className={`${
                  errors.country && touched.country && errors.country
                    ? 'is-invalid'
                    : ''
                }`}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor:
                      errors.country && touched.country
                        ? '#dc3545'
                        : provided.borderColor,
                    '&:hover': {
                      borderColor:
                        errors.country && touched.country
                          ? '#dc3545'
                          : provided.borderColor
                    }
                  })
                }}
                isClearable
                inputId="recipientCountry"
                name="country"
                aria-label="Country"
                options={countries.map((country) => ({
                  value: country,
                  label: country
                }))}
                onChange={(selectedOption) => {
                  setFieldValue('country', selectedOption ? selectedOption.value : '')
                }}
                onBlur={handleBlur}
                isLoading={isLoading}
                value={countries
                  .map((country) => ({ value: country, label: country }))
                  .find((option) => option.value === values.country)}
              />
              <div id="validationRecipientCountryFeedback" className="invalid-feedback">
                {errors.country}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="recipientCity" className="form-label">
                City
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
                type="text"
                className={`form-control ${
                  errors.city && touched.city && errors.city ? 'is-invalid' : ''
                }`}
                id="recipientCity"
                name="city"
                placeholder=""
              />
              <div id="validationRecipientCityFeedback" className="invalid-feedback">
                {errors.city}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label
                htmlFor="recipientStreet"
                className="form-label"
              >
                Street and House Number
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.street}
                type="text"
                className={`form-control ${
                  errors.street &&
                  touched.street &&
                  errors.street
                    ? 'is-invalid'
                    : ''
                }`}
                id="recipientStreet"
                name="street"
                placeholder=""
              />
              <div
                id="validationRecipientStreetFeedback"
                className="invalid-feedback"
              >
                {errors.street}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label
                htmlFor="recipientZip"
                className="form-label"
              >
                Zip
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.zip}
                type="text"
                className={`form-control ${
                  errors.zip &&
                  touched.zip &&
                  errors.zip
                    ? 'is-invalid'
                    : ''
                }`}
                id="recipientZip"
                name="zip"
                placeholder=""
              />
              <div
                id="validationRecipientZipFeedback"
                className="invalid-feedback"
              >
                {errors.zip}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label
                htmlFor="recipientAddressAddition"
                className="form-label"
              >
                Address Addition
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.addressAddition}
                type="text"
                className={`form-control ${
                  errors.addressAddition &&
                  touched.addressAddition &&
                  errors.addressAddition
                    ? 'is-invalid'
                    : ''
                }`}
                id="recipientAddressAddition"
                name="addressAddition"
                placeholder=""
              />
              <div
                id="validationRecipientAddressAdditionFeedback"
                className="invalid-feedback"
              >
                {errors.addressAddition}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label
                htmlFor="recipientCostCenter"
                className="form-label"
              >
                Cost Center
              </label>
              <AsyncSelect
                inputId="recipientCostCenter"
                cacheOptions
                loadOptions={loadCostCenterOptionsDebounced}
                defaultOptions
                onChange={(selectedOption: any) => {
                  setFieldValue('costCenter', selectedOption.value)
                }}
                placeholder="Select Cost Center"
                className={`${
                  ((errors.costCenter) && touched.costCenter)
                    ? 'is-invalid'
                    : ''
                }`}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderColor: (errors.costCenter && touched.costCenter) ? '#dc3545' : provided.borderColor,
                    '&:hover': {
                      boxShadow: (errors.costCenter && touched.costCenter) ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                      borderColor: (errors.costCenter && touched.costCenter) ? '#dc3545' : '#86b7fe'
                    }
                  })
                }}
                value={{ value: values.costCenter, label: values.costCenter }}
              />
              <div
                id="validationRecipientCostCenterFeedback"
                className="invalid-feedback"
              >
                {errors.costCenter}
              </div>
            </div>
          </div>
        </div>

        <div className="text-end">
          <button
            className="btn btn-secondary  mt-2 me-2"
            type="button"
            onClick={() => {
              if (isAddingNewRecipient) {
                setIsAddingNewRecipient(false)
                dismissModal('recipientAddressModal')
                openModal('recipientsModal')
              } else {
                dismissModal('recipientAddressModal')
              }
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary mt-2"
            disabled={
              isSubmitting || isUpdating || isLoading
            }
          >
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

export default RecipientEditor
