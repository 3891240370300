import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProductStockNotificationService from '../../../services/api/ProductStockNotificationService'
import { ApiProductStockNotificationState, ProductStockNotification } from '../../../types'
import {
  PRODUCT_STOCK_NOTIFICATION_DELETION_MESSAGE,
  PRODUCT_STOCK_NOTIFICATION_UPDATE_SUCCESS_MESSAGE
} from '../../../constants/messages'

const initialState: ApiProductStockNotificationState = {
  isLoading: false,
  error: null,
  message: null,
  productStockNotification: null,
  productStockNotifications: []
}

export const deleteProductStockNotification = createAsyncThunk('api/products/stock-notifications/delete', async ({ token, productStockNotificationId, signal }: { token: string, productStockNotificationId: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductStockNotificationService.deleteProductStockNotification({ token, productStockNotificationId, signal })
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateProductStockNotification = createAsyncThunk('api/products/stock-notifications/update', async ({ token, productStockNotificationId, productStockNotification, signal }: { token: string, productStockNotificationId: string, productStockNotification: ProductStockNotification, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductStockNotificationService.updateProductStockNotification({ token, productStockNotificationId, productStockNotification, signal })
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const productSlice = createSlice({
  name: 'api/product',
  initialState,
  reducers: {
    resetProductStockNotificationError: (state) => {
      state.error = null
    },
    resetProductStockNotificationMessage: (state) => {
      state.message = null
    },
    resetProductStockNotificationData: (state) => {
      state.error = null
      state.productStockNotification = null
      state.productStockNotifications = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteProductStockNotification.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteProductStockNotification.fulfilled, (state, action) => {
        state.isLoading = false
        state.message = PRODUCT_STOCK_NOTIFICATION_DELETION_MESSAGE
        state.error = null
      })
      .addCase(deleteProductStockNotification.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateProductStockNotification.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateProductStockNotification.fulfilled, (state, action) => {
        state.isLoading = false
        state.message = PRODUCT_STOCK_NOTIFICATION_UPDATE_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(updateProductStockNotification.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetProductStockNotificationError, resetProductStockNotificationMessage, resetProductStockNotificationData } = productSlice.actions

const { reducer } = productSlice
export default reducer
