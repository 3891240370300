import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import CampaignQuotaService from '../../../services/api/CampaignQuotaService'
import { CampaignQuotaState, Quota } from '../../../types'

const initialState: CampaignQuotaState = {
  campaignQuotas: [],
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  loading: false,
  error: null,
  totalQuota: 0
}

export const getAllCampaignQuotas = createAsyncThunk(
  'quota/getAllQuotas',
  async ({ token, perPage, page, campaignId }: { token: string, perPage: number, page: number, campaignId: string }, { rejectWithValue }) => {
    try {
      const data = await CampaignQuotaService.getAllCampaignQuotas({ token, perPage, page, campaignId })
      return data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
export const createCampaignQuota = createAsyncThunk(
  'quota/createQuota',
  async ({ token, campaignId, campaignQuota }: { token: string, campaignId: string, campaignQuota: Pick<Quota, 'orderedQuota' | 'orderedDate' | 'orderId'> }, { rejectWithValue }) => {
    try {
      const data = await CampaignQuotaService.createCampaignQuota({ token, campaignId, campaignQuota })
      return data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
export const editCampaignQuota = createAsyncThunk(
  'quota/editQuota',
  async ({ token, campaignQuotaId, campaignQuota }: { token: string, campaignQuotaId: string, campaignQuota: Pick<Quota, 'orderedQuota' | 'orderedDate' | 'orderId'> }, { rejectWithValue }) => {
    try {
      const data = await CampaignQuotaService.editCampaignQuota({ token, campaignQuotaId, campaignQuota })
      return data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
export const deleteCampaignQuota = createAsyncThunk(
  'quota/deleteQuota',
  async ({ token, campaignQuotaId }: { token: string, campaignQuotaId: string }, { rejectWithValue }) => {
    try {
      const data = await CampaignQuotaService.deleteCampaignQuota({ token, campaignQuotaId })
      return data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
const campaignQuotaSlice = createSlice({
  name: 'quota',
  initialState,
  reducers: {
    removeCampaignQuota: (state, action: PayloadAction<{ campaignQuotaId: string }>) => {
      state.loading = false
      state.campaignQuotas = state.campaignQuotas.filter(quota => quota.id !== action.payload.campaignQuotaId)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCampaignQuotas.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getAllCampaignQuotas.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.campaignQuotas = action.payload.campaignQuotas
        state.metadata = action.payload.meta
        state.totalQuota = action.payload.totalQuota
      })
      .addCase(getAllCampaignQuotas.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload.error
      })
      .addCase(createCampaignQuota.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(createCampaignQuota.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
      })
      .addCase(createCampaignQuota.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload.error
      })
      .addCase(editCampaignQuota.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(editCampaignQuota.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
      })
      .addCase(editCampaignQuota.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload.error
      })
      .addCase(deleteCampaignQuota.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(deleteCampaignQuota.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
      })
      .addCase(deleteCampaignQuota.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload.error
      })
  }
})
export const { removeCampaignQuota } = campaignQuotaSlice.actions
const { reducer } = campaignQuotaSlice
export default reducer
