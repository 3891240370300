import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

const apiClient = axios.create({
  baseURL: `${baseURL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  verifyAuthorization (token: string, signal: AbortSignal) {
    apiClient.defaults.baseURL = `${baseURL}/auth`
    const config = {
      signal,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      params: {
        token
      }
    }
    return apiClient.get('verify', config)
  }
}
