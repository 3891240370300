import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import parse from 'html-react-parser'
import { object, string } from 'yup'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import Placeholder from '../../../assets/images/placeholder.png'
import PendingOrder from '../../../components/MyInventory/Details/PendingOrder'
import InboundDelivery from '../../../components/MyInventory/Details/InboundDelivery'
import * as userRoles from '../../../constants/userRoles'
import * as appModules from '../../../constants/appModules'
import {
  editAProductById,
  getAllProductStockNotifications,
  getAProductById,
  getAProductInbounds,
  getAProductOutbounds,
  getAProductStock,
  getProductCustomisations,
  resetProductMessage
} from '../../../store/reducers/api/productReducer'
import { Module, Permission, ProductStockNotification } from '../../../types'
import hasPermission from '../../../utils/checkPermissions'
import { READ, READWRITE } from '../../../constants/permissions'
import { getAProductStockSerialNumbers } from '../../../store/reducers/api/dotnet/productReducer'
import SerialNumber from '../../../components/MyInventory/Details/SerialNumber'
import Pagination from '../../../components/Pagination'
import SkeletonTableRow from '../../../components/loaders/skeleton/SkeletonTableRow'
import OutboundOrder from '../../../components/MyInventory/Details/OutboundOrder'
import InboundOrder from '../../../components/MyInventory/Details/InboundOrder'
import Loader from './Loader'
import GraduatedPricesListView from '../../../components/MyInventory/GraduatedPrices/GraduatedPricesListView'
import {
  PRODUCT_GRADUATED_PRICE_CREATION_MESSAGE,
  PRODUCT_GRADUATED_PRICE_DELETION_MESSAGE,
  PRODUCT_GRADUATED_PRICE_UPDATE_MESSAGE,
  PRODUCT_STOCK_NOTIFICATION_DELETION_MESSAGE,
  PRODUCT_UPDATE_MESSAGE
} from '../../../constants/messages'
import { setToast } from '../../../store/reducers/toastReducer'
import { resetProductGraduatedPriceMessage } from '../../../store/reducers/api/productGraduatedPriceReducer'
import ProductDescriptionRichTextEditor from './ProductDescriptionRichTextEditor'
import { dismissModal } from '../../../utils/dismissModal'
import Progress from '../../../components/loaders/Progress'
import { PencilIcon } from '../../../components/icons/PencilIcon'
import { TrashIcon } from '../../../components/icons/TrashIcon'
import ProductStockNotificationEditor from '../../../components/Products/ProductStockNotificationEditor'
import { deleteProductStockNotification, resetProductStockNotificationMessage } from '../../../store/reducers/api/productStockNotificationReducer'
import DesignHub from '../components/DesignHub'

const InventoryDetails = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const product = useAppSelector((state) => state.apiProduct.product)
  const messageProduct = useAppSelector((state) => state.apiProduct.message)
  const stock = useAppSelector((state) => state.apiProduct.stock)
  const isLoading = useAppSelector((state) => state.apiProduct.isLoading)
  const stockProducts = useAppSelector((state) => state.dotnetApiProduct.stockProducts)
  const metadata = useAppSelector((state) => state.dotnetApiProduct.stockProductsMetadata)
  const isLoadingStockProducts = useAppSelector((state) => state.dotnetApiProduct.isLoading)
  const outbounds = useAppSelector((state) => state.apiProduct.outbounds)
  const isLoadingOutbounds = useAppSelector((state) => state.apiProduct.isLoadingOutbounds)
  const outboundsMetadata = useAppSelector((state) => state.apiProduct.outboundsMetadata)
  const inbounds = useAppSelector((state) => state.apiProduct.inbounds)
  const isLoadingInbounds = useAppSelector((state) => state.apiProduct.isLoadingInbounds)
  const inboundsMetadata = useAppSelector((state) => state.apiProduct.inboundsMetadata)
  const isLoadingProductGraduatedPrice = useAppSelector((state) => state.apiProductGraduatedPrice.isLoading)
  const messageProductGraduatedPrice = useAppSelector((state) => state.apiProductGraduatedPrice.message)
  const productStockNotifications = useAppSelector((state) => state.apiProduct.productStockNotifications)
  const productStockNotificationsMetadata = useAppSelector((state) => state.apiProduct.productStockNotificationsMetadata)
  const isLoadingProductStockNotifications = useAppSelector((state) => state.apiProduct.isLoadingProductStockNotifications)
  const isLoadingProductStockNotification = useAppSelector((state) => state.apiProductStockNotification.isLoading)

  const [perPage, setPerPage] = useState(5)
  const [page, setPage] = useState(1)

  const [perPageOutbounds, setPerPageOutbounds] = useState(5)
  const [pageOutbounds, setPageOutbounds] = useState(1)

  const [perPageInbounds, setPerPageInbounds] = useState(5)
  const [pageInbounds, setPageInbounds] = useState(1)

  const [editProductStockNotification, setEditProductStockNotification] = useState(false)
  const [selectedProductStockNotification, setSelectedProductStockNotification] = useState<ProductStockNotification | null>(null)
  const quillRef = useRef<any>(null)

  const allowedRoles = [userRoles.ADMIN, userRoles.COMPANYADMINISTRATOR]
  const companyAccessPermissions = profile?.company?.accessPermissions || []
  const defaultAccessPermissions = profile?.company?.defaultAccessPermissions || []

  const dispatch = useAppDispatch()
  const { productId } = useParams()

  const token = currentUser?.token
  const role = currentUser?.role || userRoles.USER
  const userId = currentUser?.id
  const companyOwnerId = currentUser?.company?.owner?.id

  const isOwner = companyOwnerId && userId === companyOwnerId

  const isAllowed = (module: Module, permission: Permission = READ) => {
    return isOwner || hasPermission(module, role, companyAccessPermissions, defaultAccessPermissions, permission)
  }

  const productDescriptionSchema = object().shape({
    description: string()
      .label('Description')
      .max(10000, (value) => `${value.label} must be at most ${value.max} ${value.max === 1 ? 'character' : 'characters'}`)
  })

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handleShowEntriesOutbounds = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageOutbounds(1)
    setPerPageOutbounds(Number(event.target.value))
  }

  const handlePageChangeOutbounds = (page: number) => {
    setPageOutbounds(page)
  }

  const handleShowEntriesInbounds = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageInbounds(1)
    setPerPageInbounds(Number(event.target.value))
  }

  const handlePageChangeInbounds = (page: number) => {
    setPageInbounds(page)
  }
  const [perPageProductStockNotification, setPerPageProductStockNotification] = useState(4)
  const [pageProductStockNotification, setPageProductStockNotification] = useState(1)

  const handleProductStockNotificationRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (isAllowed(appModules.PRODUCTSTOCKNOTIFICATIONS) && token && productId) {
      dispatch(getAllProductStockNotifications({ token, productId, perPage: perPageProductStockNotification, page: pageProductStockNotification, signal }))
    }
  }

  const handleProductStockNotificationShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageProductStockNotification(1)
    setPerPageProductStockNotification(Number(event.target.value))
  }
  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (isAllowed(appModules.PRODUCTSTOCKNOTIFICATIONS) && token && productId) {
      dispatch(getAllProductStockNotifications({ token, productId, perPage: perPageProductStockNotification, page: pageProductStockNotification, signal }))
    }
  }, [profile, perPageProductStockNotification, pageProductStockNotification])
  const {
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    isSubmitting
  } = useFormik({
    validationSchema: productDescriptionSchema,
    initialValues: {
      description: product?.description || ''
    },
    onSubmit: ({ description }, actions) => {
      const controller = new AbortController()
      const signal = controller.signal
      if (token && productId) {
        dispatch(editAProductById({ token, productId, product: { description }, signal }))
      }
      actions.setSubmitting(false)
    },
    enableReinitialize: true
  })

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productId) {
      dispatch(getAProductById({ token, productId, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [productId])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const allowedMessages = [PRODUCT_GRADUATED_PRICE_CREATION_MESSAGE, PRODUCT_GRADUATED_PRICE_UPDATE_MESSAGE, PRODUCT_GRADUATED_PRICE_DELETION_MESSAGE, PRODUCT_UPDATE_MESSAGE]

    if (token && productId) {
      if ((messageProductGraduatedPrice && allowedMessages.includes(messageProductGraduatedPrice))) {
        const payload = {
          title: 'Success',
          message: messageProductGraduatedPrice,
          isVisible: true,
          timestamp: dayjs().format('LT'),
          type: 'success'
        }
        dispatch(setToast(payload))
        dispatch(resetProductGraduatedPriceMessage())
        dispatch(getAProductById({ token, productId, signal }))
      }
      if ((messageProduct && allowedMessages.includes(messageProduct))) {
        dismissModal('productDescriptionModal')
        const payload = {
          title: 'Success',
          message: messageProduct,
          isVisible: true,
          timestamp: dayjs().format('LT'),
          type: 'success'
        }
        dispatch(setToast(payload))
        dispatch(resetProductMessage())
        dispatch(getAProductById({ token, productId, signal }))
      }
    }
  }, [messageProductGraduatedPrice, messageProduct])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const merchantSku = product?.merchantSku

    if (token && merchantSku && isAllowed(appModules.PRODUCTS)) {
      const encodedMerchantSku = encodeURIComponent(merchantSku)
      dispatch(getAProductStockSerialNumbers({ token, merchantSku: encodedMerchantSku, perPage, page, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [product, perPage, page])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productId && isAllowed(appModules.PRODUCTS)) {
      dispatch(getAProductStock({ token, productId, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [productId])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productId && isAllowed(appModules.PRODUCTS)) {
      dispatch(getAProductOutbounds({ token, perPage: perPageOutbounds, page: pageOutbounds, productId, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [productId, perPageOutbounds, pageOutbounds])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productId && isAllowed(appModules.PRODUCTS)) {
      dispatch(getAProductInbounds({ token, perPage: perPageInbounds, page: pageInbounds, productId, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [productId, perPageInbounds, pageInbounds])

  useEffect(() => {
    if (token && product?.id && isAllowed(appModules.PRODUCTCUSTOMISATIONS)) {
      const controller = new AbortController()
      const signal = controller.signal
      dispatch(getProductCustomisations({ token, signal, productId: product?.id, perPage: 1000, page: 1 }))
      return () => controller.abort()
    }
  }, [product, token])

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <div className="row">
          <div className="col-12">
            <div className="card overflow-hidden">
              {
                (isLoading)
                  ? (<Loader />)
                  : (
                    <div className="row p-4">
                      <div className="col-lg-5">
                        <span className="text-center d-block mb-4">
                          {
                            product && product.pictures && product?.pictures.length > 0
                              ? (
                                <img
                                  src={product.pictures[0].publicUrl}
                                  className="img-fluid"
                                  style={{ maxHeight: '300px', objectFit: 'contain', width: '100%' }}
                                  alt="Product-img"
                                  onError={(e) => {
                                    const target = e.target as HTMLImageElement
                                    target.onerror = null
                                    target.src = Placeholder
                                  }}
                                />
                                )
                              : (<img src={Placeholder} alt="Placeholder thumbnail" className="img-fluid" style={{ maxHeight: '300px', objectFit: 'contain', width: '100%' }} />)
                          }
                        </span>

                        <div className="d-lg-flex d-none justify-content-center">
                          {
                            product && product.pictures && product.pictures.length > 0
                              ? (
                                  product.pictures.map(picture => (
                                    <span key={picture.number} className={`${picture.number > 1 ? 'ms-2' : 'ms-0'}`}>
                                      <img
                                        src={picture.publicUrl}
                                        className="img-fluid img-thumbnail p-2"
                                        style={{ maxWidth: '75px' }}
                                        alt="Product"
                                        onError={(e) => {
                                          const target = e.target as HTMLImageElement
                                          target.onerror = null
                                          target.src = Placeholder
                                        }}
                                      />
                                    </span>
                                  ))
                                )
                              : (null)
                          }
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="ps-lg-4">
                          <h3 className="mt-0">{product?.name}</h3>
                          <p className="mb-1">
                            Article Number (SKU): {product?.merchantSku}
                          </p>

                              {
                                allowedRoles.includes(role) && (
                                  <div className="mt-4">
                                    <h4>Inventory</h4>
                                    <div className="row">
                                      <div className="col-md-4">
                                        <h6 className="font-14">In Stock / Available:</h6>
                                        <p className="text-sm lh-150">{stock?.stockLevel || 0} / {Math.max((stock?.stockLevel || 0) - (stock?.stockLevelReserved || 0), 0)}</p>
                                      </div>
                                      <div className="col-md-4">
                                        <h6 className="font-14">Reserved:</h6>
                                        <p className="text-sm lh-150">{stock?.stockLevelReserved || 0}</p>
                                      </div>
                                      <div className="col-md-4">
                                        <h6 className="font-14">Awaiting Delivery:</h6>
                                        <p className="text-sm lh-150">{stock?.stockLevelAnnounced || 0}</p>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                        </div>
                      </div>
                    </div>
                    )
              }
            </div>

            {isAllowed(appModules.PRODUCTCUSTOMISATIONS) && <DesignHub id={product?.id} />}
            <div className="card mt-4">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="card-title mb-0">Product Description</h5>
                  {role === userRoles.ADMIN && <button
                    className="btn btn-outline-primary btn-sm text-nowrap"
                    data-bs-toggle="modal"
                    data-bs-target="#productDescriptionModal"
                    disabled={isLoading}
                  >
                    <i className="bi bi-pencil-square"></i><span className="ms-1 d-none d-md-inline">Edit Description</span>
                  </button>}
                </div>
                <div className="card-text product-description">
                  {isLoading
                    ? (
                      <>
                        <p className="placeholder-glow">
                          <span className="placeholder col-7"></span>
                          <span className="placeholder col-4"></span>
                          <span className="placeholder col-4"></span>
                          <span className="placeholder col-6"></span>
                          <span className="placeholder col-8"></span>
                        </p>
                      </>
                      )
                    : (
                        product?.description
                          ? parse(product.description)
                          : <p>No description available.</p>
                      )}
                </div>
              </div>
            </div>
            <div className="card mt-4">
              <div className="m-4">
                <GraduatedPricesListView
                  isLoading={isLoading || isLoadingProductGraduatedPrice}
                  graduatedPrices={product?.graduatedPrices ?? []}
                  productId={product?.id}
                />
              </div>
            </div>
            <div className="card mt-4">
              <div className="m-4">
                <div className="navbar navbar-expand mt-2">
                  <p className="h5">
                    <i className="bi bi-archive me-1"></i> Serial Numbers <br />
                    <span className="small">Serial numbers included in the following orders</span>
                  </p>
                </div>
                <div className="table-responsive mt-1">
                  <table className="table table-bordered table-centered mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Order Number</th>
                        <th>Recipients</th>
                        <th>Serial Number</th>
                        <th>Date Dispatched</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        isLoadingStockProducts
                          ? (
                              Array.from(Array(perPage).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={4} actionQuantity={0} />)
                            )
                          : stockProducts.length > 0
                            ? (
                                stockProducts.map((stockProduct) => (
                                <SerialNumber stockProduct={stockProduct} key={stockProduct.id}/>
                                ))
                              )
                            : (
                              <tr>
                                <td colSpan={4} className="text-center">
                                  No serial numbers available yet
                                </td>
                              </tr>
                              )
                      }
                    </tbody>
                  </table>
                </div>
                <div className="mt-2">
                  <Pagination
                    isLoading={isLoadingStockProducts}
                    metadata={{
                      limit: metadata.perPage,
                      total: metadata.total,
                      offset: ((metadata.page - 1) * (metadata.perPage))
                    }}
                    page={page}
                    perPage={perPage}
                    perPageOptions={[5, 10, 25, 50]}
                    handlePageChange={handlePageChange}
                    handleShowEntries={handleShowEntries}
                    module="StockProducts"
                  />
                </div>
              </div>
            </div>

            <div className="card mt-4">
              <div className="m-4">
                <div className="navbar navbar-expand mt-2">
                  <p className="h5">
                    <i className="bi bi-archive me-1"></i> Pending Orders <br />
                    <span className="small">Product reserved in the following orders</span>
                  </p>
                </div>
                <div className="table-responsive mt-1">
                  <table className="table table-bordered table-centered mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Order ID</th>
                        <th>Recipients</th>
                        <th className="text-center">Number of boxes</th>
                        <th>Date Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        isLoading
                          ? (
                              Array.from(Array(5).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={4} actionQuantity={0} />)
                            )
                          : stock && stock?.stockReservedDetails.length > 0
                            ? (
                                stock.stockReservedDetails.map((pendingOrder) => (
                                <PendingOrder outboundId={pendingOrder.outboundId} key={pendingOrder.outboundId}/>
                                ))
                              )
                            : (
                              <tr>
                                <td colSpan={4} className="text-center">
                                  No pending orders available yet
                                </td>
                              </tr>
                              )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="card mt-4 overflow-hidden">
              <div className="m-4">
                <div className="navbar navbar-expand mt-2">
                  <p className="h5">
                    <i className="bi bi-archive me-1"></i> Product Delivery <br />
                    <span className="small">Status of inbound deliveries</span>
                  </p>
                </div>
                <div className="table-responsive mt-1">
                  <table className="table table-bordered table-centered mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Delivery ID</th>
                        <th>Ordered Quantity</th>
                        <th className="text-center">Delivered Quantity</th>
                        <th>Expected Date of Delivery</th>
                        <th>Date of Delivery</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        isLoading
                          ? (
                              Array.from(Array(5).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={5} actionQuantity={0} />)
                            )
                          : stock && stock.stockAnnouncedDetails.length > 0
                            ? (
                                stock.stockAnnouncedDetails.map((inbound) => (
                                <InboundDelivery
                                  key={inbound.inboundId}
                                  inboundId={inbound.inboundId}
                                  jfsku={String(product?.jfsku)}
                                />
                                ))
                              )
                            : (
                              <tr>
                                <td colSpan={5} className="text-center">
                                  No inbound deliveries available yet
                                </td>
                              </tr>
                              )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="card mt-4 overflow-hidden">
              <div className="m-4">
                <div className="navbar navbar-expand mt-2">
                  <p className="h5">
                    <i className="bi bi-clock-history me-1"></i> Product History <br />
                    <span className="small">Inbound orders</span>
                  </p>
                </div>
                <div className="table-responsive mt-1">
                  <table className="table table-bordered table-centered mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Order Id</th>
                        <th className="text-center">Delivered Quantity</th>
                        <th>Delivery Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        isLoadingInbounds
                          ? (
                              Array.from(Array(5).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={4} actionQuantity={0} />)
                            )
                          : inbounds && inbounds.length > 0
                            ? (
                                inbounds.map((inbound) => (
                                <InboundOrder
                                  key={inbound.inboundId}
                                  inbound={inbound}
                                  jfsku={String(product?.jfsku)}
                                />
                                ))
                              )
                            : (
                              <tr>
                                <td colSpan={4} className="text-center">
                                  No inbound orders available yet
                                </td>
                              </tr>
                              )
                      }
                    </tbody>
                  </table>
                </div>
                <div className="mt-2">
                  <Pagination
                    isLoading={isLoadingInbounds}
                    metadata={{
                      limit: inboundsMetadata.perPage,
                      total: inboundsMetadata.total,
                      offset: ((inboundsMetadata.page - 1) * (inboundsMetadata.perPage))
                    }}
                    page={pageInbounds}
                    perPage={perPageInbounds}
                    perPageOptions={[5, 10, 25, 50]}
                    handlePageChange={handlePageChangeInbounds}
                    handleShowEntries={handleShowEntriesInbounds}
                    module="ProductInbounds"
                  />
                </div>
              </div>
            </div>

            <div className="card mt-4 overflow-hidden">
              <div className="m-4">
                <div className="navbar navbar-expand mt-2">
                  <p className="h5">
                    <i className="bi bi-clock-history me-1"></i> Product History <br />
                    <span className="small">Outbound orders</span>
                  </p>
                </div>
                <div className="table-responsive mt-1">
                  <table className="table table-bordered table-centered mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Order Id</th>
                        <th className="text-center">Delivered Quantity</th>
                        <th>Shipping Date</th>
                        <th>Created By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        isLoadingOutbounds
                          ? (
                              Array.from(Array(5).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={4} actionQuantity={0} />)
                            )
                          : outbounds && outbounds.length > 0
                            ? (
                                outbounds.map((outbound) => (
                                <OutboundOrder
                                  key={outbound.outboundId}
                                  outbound={outbound}
                                />
                                ))
                              )
                            : (
                              <tr>
                                <td colSpan={4} className="text-center">
                                  No outbound orders available yet
                                </td>
                              </tr>
                              )
                      }
                    </tbody>
                  </table>
                </div>
                <div className="mt-2">
                  <Pagination
                    isLoading={isLoadingOutbounds}
                    metadata={{
                      limit: outboundsMetadata.perPage,
                      total: outboundsMetadata.total,
                      offset: ((outboundsMetadata.page - 1) * (outboundsMetadata.perPage))
                    }}
                    page={pageOutbounds}
                    perPage={perPageOutbounds}
                    perPageOptions={[5, 10, 25, 50]}
                    handlePageChange={handlePageChangeOutbounds}
                    handleShowEntries={handleShowEntriesOutbounds}
                    module="ProductOutbounds"
                  />
                </div>
              </div>
            </div>
            {isAllowed(appModules.PRODUCTSTOCKNOTIFICATIONS, READ) && (
            <div className="card mt-4">
              <div className="m-4">
                <div className="navbar navbar-expand mb-3 d-flex flex-column flex-sm-row align-items-start">
                  <p className="h5">
                    <i className="bi bi-bell me-2"></i>Product Stock Notifications
                  </p>
                  <ul className="navbar-nav ms-auto me-0 gap-2 gap-sm-3">
                    {
                      isAllowed(appModules.PRODUCTSTOCKNOTIFICATIONS, READWRITE) && (
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm text-nowrap"
                          data-bs-toggle="modal"
                          title="Add Product Stock Notification"
                          data-bs-target="#createProductStockNotificationModal"
                          onClick={() => {
                            setEditProductStockNotification(false)
                            setSelectedProductStockNotification({
                              id: '',
                              quantity: 0,
                              threshold: 0,
                              frequency: 1,
                              frequencyUnit: 'month',
                              recipients: []
                            })
                          }}
                        >
                          <i className="bi bi-plus-circle"></i>
                          <span className="ms-1"><span className="d-none d-md-inline-block me-1">Add</span> Notification</span>
                        </button>
                      )
                    }
                    <button
                      type="button"
                      title="Refresh"
                      aria-label="Refresh"
                      className="btn btn-outline-dark"
                      onClick={() => handleProductStockNotificationRefresh()}
                    >
                      <i className="fas fa-redo"></i>
                    </button>
                  </ul>
                </div>

                {isLoadingProductStockNotifications ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
                <div className="table-responsive">
                  <table className="table table-hover table-centered align-middle table-nowrap">
                    <thead>
                      <tr>
                        <th scope="col" className="text-nowrap">Notification Recipients</th>
                        <th className="text-nowrap" scope="col">Quantity (Below)</th>
                        <th className="text-nowrap" scope="col">Frequency</th>
                        {isAllowed(appModules.PRODUCTSTOCKNOTIFICATIONS, READWRITE) && <th scope="col" className="text-nowrap">
                          <div className="float-end">
                            Actions
                          </div>
                        </th>}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        productStockNotifications && productStockNotifications.length > 0
                          ? (
                              productStockNotifications.map((notification) => (
                                <tr key={notification.id} className={selectedProductStockNotification?.id === notification.id ? 'table-primary' : ''}>
                                  <td>
                                    {notification.recipients.slice(0, 1).map((recipient, index) => (
                                      <span key={index}>
                                        {recipient}
                                        {index < Math.min(notification.recipients.length, 3) - 1 && ', '}
                                      </span>
                                    ))}
                                    {notification.recipients.length > 1 && (
                                      <>
                                        <button
                                          className="btn btn-link p-0 ms-1"
                                          data-bs-toggle="modal"
                                          data-bs-target="#productStockNotificationRecipientsModal"
                                          title="View all notification recipients"
                                          onClick={() => setSelectedProductStockNotification(notification)}
                                        >
                                          View All
                                        </button>
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    {`${notification.quantity}`}
                                  </td>
                                  <td>
                                    {`${notification.frequency} ${notification.frequencyUnit}`}
                                  </td>
                                  {
                                    isAllowed(appModules.PRODUCTSTOCKNOTIFICATIONS, READWRITE) && (
                                      <td className="text-center">
                                        <div className="d-flex flex-row float-end" role="group" aria-label="Notification Action Buttons">
                                          <button
                                            type="button"
                                            title="Edit Product Stock Notification"
                                            className="btn btn-outline-dark btn-round me-2"
                                            data-bs-toggle="modal"
                                            data-bs-target="#createProductStockNotificationModal"
                                            onClick={() => {
                                              setEditProductStockNotification(true)
                                              setSelectedProductStockNotification(notification)
                                            }}
                                          >
                                            <PencilIcon/>
                                          </button>
                                          <button
                                            type="button"
                                            title="Delete Notification"
                                            className="btn btn-outline-danger btn-round"
                                            data-bs-toggle="modal"
                                            data-bs-target="#productNotificationDeleteConfirmationModal"
                                            onClick={() => {
                                              setEditProductStockNotification(false)
                                              setSelectedProductStockNotification(notification)
                                            }}
                                          >
                                            <TrashIcon/>
                                          </button>
                                        </div>
                                      </td>
                                    )
                                  }
                                </tr>
                              ))
                            )
                          : (
                            <tr>
                              <td colSpan={isAllowed(appModules.PRODUCTSTOCKNOTIFICATIONS, READWRITE) ? 4 : 3} className="text-center">
                                No product stock notifications available yet
                              </td>
                            </tr>
                            )
                      }
                    </tbody>
                  </table>
                </div>
                <Pagination
                  isLoading={isLoadingProductStockNotifications}
                  metadata={{
                    limit: productStockNotificationsMetadata.perPage,
                    total: productStockNotificationsMetadata.total,
                    offset: ((productStockNotificationsMetadata.page - 1) * (productStockNotificationsMetadata.perPage))
                  }}
                  page={pageProductStockNotification}
                  perPage={perPageProductStockNotification}
                  handlePageChange={(page) => setPageProductStockNotification(page)}
                  handleShowEntries={handleProductStockNotificationShowEntries}
                  module="ProductStockNotification"
                  perPageOptions={[5, 10, 25]}
                />
              </div>
            </div>
            )}
          </div>
        </div>
      </div>

      {/* Edit Description Modal */}
      <div className="modal fade" id="productDescriptionModal" tabIndex={-1} aria-labelledby="productDescriptionModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="productDescriptionModalLabel">Edit Product Description</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form onSubmit={handleSubmit}>
              {
                isLoading && (<Progress marginBottom={false} />)
              }
              <div className="modal-body">
                <ProductDescriptionRichTextEditor
                  ref={quillRef}
                  handleTextChange={(value) => {
                    setFieldValue('description', value)
                  }}
                  textContent={values.description}
                  stageWidth={'100%'}
                  className={`product-description ${
                    touched.description &&
                    errors.description
                      ? 'is-invalid'
                      : ''
                  }`}
                  border={(touched.description && errors.description) ? '1px solid #dc3545' : 'none'}
                  readOnly={false}
                />
                <div
                  id="validationProductDescriptionFeedback"
                  className="invalid-feedback"
                >
                  {errors.description}
                </div>
              </div>
              {
                isLoading && (<Progress marginBottom={false} />)
              }
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting || isLoading}
                >
                  <i className="bi bi-save me-1"></i> Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="modal fade" id="createProductStockNotificationModal" tabIndex={-1} aria-labelledby="createProductStockNotificationModalLabel" aria-modal="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="createProductStockNotificationModalLabel">
                <i className={`bi ${editProductStockNotification ? 'bi-pencil-square' : 'bi-plus-circle'} me-1`}></i>{' '}
                {`${editProductStockNotification ? 'Edit' : 'Add'} Stock Notification`}
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {(isLoadingProductStockNotifications || isLoadingProductStockNotification) && <Progress marginBottom={false} />}
            <div className="modal-body">
              <ProductStockNotificationEditor
                handleProductStockNotificationRefresh={handleProductStockNotificationRefresh}
                token={token || ''}
                productId={productId || ''}
                isLoading={isLoadingProductStockNotification || isLoadingProductStockNotifications}
                selectedProductStockNotification={selectedProductStockNotification}
                editLowStockNotification={editProductStockNotification}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="productNotificationDeleteConfirmationModal" tabIndex={-1} aria-labelledby="productStockNotificationDeleteConfirmationLabel" aria-modal="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title text-danger" id="productStockNotificationDeleteConfirmationLabel">
                <i className="bi bi-trash text-danger me-2"></i>Confirm Delete
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {(isLoadingProductStockNotifications || isLoadingProductStockNotification) && <Progress marginBottom={false} />}
            <div className="modal-body">
              <p>
                Are you sure you want to delete the notification with a quantity of <span className="fw-bold">{selectedProductStockNotification?.quantity}</span> and recipients <span className="fw-bold">{selectedProductStockNotification?.recipients.join(', ')}</span>?
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  if (token && selectedProductStockNotification?.id) {
                    const controller = new AbortController()
                    const signal = controller.signal
                    dispatch(deleteProductStockNotification({ token, productStockNotificationId: selectedProductStockNotification?.id, signal }))
                      .then(() => {
                        handleProductStockNotificationRefresh()
                        const payload = {
                          title: 'Success',
                          message: PRODUCT_STOCK_NOTIFICATION_DELETION_MESSAGE,
                          isVisible: true,
                          timestamp: dayjs().format('LT'),
                          type: 'success'
                        }
                        dispatch(setToast(payload))
                      }).finally(() => {
                        dispatch(resetProductStockNotificationMessage())
                        dismissModal('productNotificationDeleteConfirmationModal')
                      })
                  }
                }}
                disabled={isLoadingProductStockNotification || isLoadingProductStockNotifications}
                aria-label="Delete Product Stock Notification"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="productStockNotificationRecipientsModal"
        tabIndex={-1}
        aria-labelledby="productStockNotificationRecipientsModalLabel"
        aria-modal="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title " id="productStockNotificationRecipientsModalLabel">
                <i className="bi bi-envelope-at me-2"></i>Notification Recipients
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <ul className="list-group">
                {
                  selectedProductStockNotification?.recipients.map((recipient, index) => (
                    <li className="list-group-item" key={index}>{recipient}</li>
                  ))
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default InventoryDetails
