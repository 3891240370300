import React, { useEffect, useState, useMemo } from 'react'
import { Formik } from 'formik'
import { object, string, boolean } from 'yup'
import dayjs from 'dayjs'
import 'react-datepicker/dist/react-datepicker.css'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { setToast } from '../../../store/reducers/toastReducer'
import { dismissModal } from '../../../utils/dismissModal'
import {
  CAMPAIGN_ADDITIONAL_PRODUCT_SETTING_UPDATE_SUCCESS_MESSAGE,
  CAMPAIGN_ADDITIONAL_PRODUCT_SETTING_ADDITION_SUCCESS_MESSAGE
} from '../../../constants/messages'
import { CampaignAdditionalProductSetting } from '../../../types'
import { addAdditionalProductSetting } from '../../../store/reducers/api/campaignReducer'
import { editAdditionalProductSetting } from '../../../store/reducers/api/campaignAdditionalProductSettingReducer'
import * as userRoles from '../../../constants/userRoles'
interface CampaignAdditionalProductSettingEditorProps {
  token: string
  campaignId: string
  handleAdditionalProductSettingsRefresh: () => void
  isLoading: boolean
  selectedAdditionalProductSetting: CampaignAdditionalProductSetting
  editModeAdditionalProductSetting: boolean
}

const roleHierarchy = [
  { value: userRoles.USER, text: 'User' },
  { value: userRoles.EMPLOYEE, text: 'Employee' },
  { value: userRoles.CAMPAIGNMANAGER, text: 'Campaign Manager' },
  { value: userRoles.COMPANYADMINISTRATOR, text: 'Company Administrator' },
  { value: userRoles.ADMIN, text: 'Administrator' }
]

const CampaignAdditionalProductSettingEditor = ({
  handleAdditionalProductSettingsRefresh,
  token,
  campaignId,
  isLoading,
  selectedAdditionalProductSetting,
  editModeAdditionalProductSetting
}: CampaignAdditionalProductSettingEditorProps) => {
  const profile = useAppSelector((state) => state.profile.profile)
  const [role, setRole] = useState<string | null>(null)

  useEffect(() => {
    if (profile) {
      setRole(profile.role)
    }
  }, [profile])

  const filteredRoles = useMemo(() => {
    if (!role) return []
    const currentRoleIndex = roleHierarchy.findIndex((r) => r.value === role)
    if (role === userRoles.ADMIN) return roleHierarchy.slice(0, -1)
    return roleHierarchy.slice(0, currentRoleIndex + 1)
  }, [role])

  const campaignAdditionalProductSettingSchema = useMemo(
    () =>
      object().shape({
        role: string().label('Role').required().oneOf(filteredRoles.map((role) => role.value)),
        isSelectEnabled: boolean().label('Is Select Enabled').required()
      }),
    [filteredRoles]
  )

  const dispatch = useAppDispatch()

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{ ...selectedAdditionalProductSetting }}
        validationSchema={campaignAdditionalProductSettingSchema}
        onSubmit={(values, actions) => {
          const { role, isSelectEnabled } = values
          const controller = new AbortController()
          const signal = controller.signal

          if (editModeAdditionalProductSetting) {
            if (selectedAdditionalProductSetting.id) {
              dispatch(
                editAdditionalProductSetting({
                  token,
                  campaignAdditionalProductSettingId: selectedAdditionalProductSetting.id,
                  campaignAdditionalProductSetting: { role, isSelectEnabled },
                  signal
                })
              )
                .unwrap()
                .then(() => {
                  const payload = {
                    title: 'Success',
                    message: CAMPAIGN_ADDITIONAL_PRODUCT_SETTING_UPDATE_SUCCESS_MESSAGE,
                    isVisible: true,
                    timestamp: dayjs().format('LT'),
                    type: 'success'
                  }
                  dismissModal('campaignAdditionalProductSettingModal')
                  dispatch(setToast(payload))
                  handleAdditionalProductSettingsRefresh()
                })
                .catch(() => {
                  const payload = {
                    title: 'Error',
                    message: 'Failed to update additional product setting',
                    isVisible: true,
                    timestamp: dayjs().format('LT'),
                    type: 'danger'
                  }
                  dispatch(setToast(payload))
                })
            }
          } else {
            dispatch(
              addAdditionalProductSetting({
                token,
                campaignId,
                campaignAdditionalProductSetting: { role, isSelectEnabled },
                signal
              })
            )
              .unwrap()
              .then(() => {
                const payload = {
                  title: 'Success',
                  message: CAMPAIGN_ADDITIONAL_PRODUCT_SETTING_ADDITION_SUCCESS_MESSAGE,
                  isVisible: true,
                  timestamp: dayjs().format('LT'),
                  type: 'success'
                }
                dismissModal('campaignAdditionalProductSettingModal')
                dispatch(setToast(payload))
                handleAdditionalProductSettingsRefresh()
              })
              .catch(() => {
                const payload = {
                  title: 'Error',
                  message: 'Failed to add additional product setting',
                  isVisible: true,
                  timestamp: dayjs().format('LT'),
                  type: 'danger'
                }
                dispatch(setToast(payload))
              })
          }

          actions.setSubmitting(false)
        }}
      >
        {({
          handleBlur,
          errors,
          touched,
          handleSubmit,
          values,
          setFieldValue,
          handleChange
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="role" className="form-label">Role</label>
                <select
                  aria-label="Role"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.role}
                  className={`form-control ${
                    touched.role && errors.role
                      ? 'is-invalid'
                      : ''
                  }`}
                  id="role"
                  name="role"
                >
                  <option value="">Select Role</option>
                  {filteredRoles.map((role, index) => <option key={`${role.value}-${index}`} value={role.value}>{role.text}</option>)}
                </select>
                <div
                  className="invalid-feedback"
                >
                  {errors.role}
                </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Is Additional Product Selection Enabled</label>
              <div role="group" aria-labelledby="isSelectEnabled">
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id="isSelectEnabledTrue"
                    name="isSelectEnabled"
                    value="true"
                    className="form-check-input"
                    checked={values.isSelectEnabled === true}
                    onChange={() => setFieldValue('isSelectEnabled', true)}
                  />
                  <label className="form-check-label" htmlFor="isSelectEnabledTrue">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id="isSelectEnabledFalse"
                    name="isSelectEnabled"
                    value="false"
                    className="form-check-input"
                    checked={values.isSelectEnabled === false}
                    onChange={() => setFieldValue('isSelectEnabled', false)}
                  />
                  <label className="form-check-label" htmlFor="isSelectEnabledFalse">
                    No
                  </label>
                </div>
              </div>
              {touched.isSelectEnabled && errors.isSelectEnabled && (
                <div className="invalid-feedback d-block">{errors.isSelectEnabled}</div>
              )}
            </div>

            <div className="text-end">
              <button type="submit" className="btn btn-primary mt-4" disabled={isLoading}>
                <i className="bi bi-save text-white"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default CampaignAdditionalProductSettingEditor
