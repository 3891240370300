import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import Logo from '../../components/Logo'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Navigate, useNavigate, useLocation, Link } from 'react-router-dom'
import { resetPassword, resetApiAuthError } from '../../store/reducers/api/authReducer'
import Progress from '../../components/loaders/Progress'
import { resetUserMessage } from '../../store/reducers/api/usersReducer'

const ResetPassword = () => {
  const isLoading = useAppSelector((state) => state.apiAuth.isLoading)
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const error = useAppSelector((state) => state.apiAuth.error)
  const authMessage = useAppSelector((state) => state.apiAuth.message)
  const [tokenMessage, setTokenMessage] = useState('')

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { state }: { state: any } = useLocation()

  const resetPasswordSchema = object({
    password: string()
      .required('Password is required').min(6, 'Password must be at least 6 characters')
  })

  function useQuery () {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQuery()
  const token = query.get('token')

  useEffect(() => {
    if (token) {
      setTokenMessage('')
    } else {
      setTokenMessage('Token is missing. Kindly click on the reset link from the email you requested.')
    }
  }, [token])

  useEffect(() => {
    if (currentUser?.token) {
      dispatch(resetApiAuthError())
      dispatch(resetUserMessage())
      const origin = state?.from?.pathname || '/'
      navigate(origin, { })
    }
  }, [currentUser])

  if (currentUser?.token) {
    const origin = state?.from?.pathname || '/'
    return <Navigate to={origin} />
  }
  return (
    <main className="bg-dark">
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container">
              <Logo isWhite />
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <div className="card shadow-lg border-0 rounded-lg mt-5">
                    <div className="card-header">
                      <h3 className="text-center font-weight-light my-4">
                        Reset Password
                      </h3>
                      <p>Enter your new password</p>
                    </div>
                    {isLoading && <Progress marginBottom={false} />}
                    <div className="card-body">
                      {
                        (state?.message || authMessage || tokenMessage) && (
                          <div className={`alert alert-${(state?.message || authMessage) ? 'success' : 'danger'}`} role="alert">
                            {state?.message || authMessage || tokenMessage} {authMessage && (<Link to='/login'>Login</Link>)}
                          </div>
                        )
                      }
                      {
                        (error?.errors?.message) && (
                          <div className={'alert alert-danger'} role="alert">
                            {error?.errors?.message === 'jwt expired' ? (<span>Reset link has expired kindly request a new one <Link to='/forgot-password'>here</Link></span>) : error?.errors?.message}
                          </div>
                        )
                      }
                      <Formik
                        validationSchema={resetPasswordSchema}
                        initialValues={{ password: '' }}
                        onSubmit={({ password }, actions) => {
                          const controller = new AbortController()
                          const signal = controller.signal
                          const user = {
                            password
                          }
                          token && dispatch(resetPassword({ user, token, signal }))
                          actions.setSubmitting(false)
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="form-floating mb-3">
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                className={`form-control ${(errors.password && touched.password && errors.password) ? 'is-invalid' : ''}`}
                                id="inputPassword"
                                type="password"
                                name="password"
                                aria-describedby="validationPasswordFeedback"
                              />
                              <label htmlFor="inputPassword">New Password</label>
                              <div id="validationPasswordFeedback" className="invalid-feedback">
                                {errors.password}
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-end mt-4 mb-0">
                              <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={isSubmitting || isLoading}
                              >
                                Reset Password
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                    <div className="card-footer text-center py-3">
                      <div className="small">
                        <Link to="/register">Need an account? Sign up!</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </main>
  )
}

export default ResetPassword
