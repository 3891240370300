import React from 'react'
import { useNavigate } from 'react-router-dom'
import { formatPrice } from '../../utils/formatPrice'
import { Address, ShoppingCartAddress, ShoppingCartProduct } from '../../types'
import { germanyTaxRate } from '../../constants/taxRates'
import { calculateGraduatedPrice } from '../../utils/calculateGraduatedPrice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getTotalCartProducts } from '../../utils/getTotalCartProducts'
import { calculateShippingCost } from '../../utils/calculateShippingCost'
import { generateAddressText } from '../../utils/generateAddressText'
import Placeholder from '../../assets/images/placeholder.png'
import { resetShoppingAddresses, resetShoppingCart } from '../../store/reducers/shoppingCartReducer'

interface ShoppingOrderConfirmationProps {
  selectedBillingAddress: Partial<Address> | null
}
const ShoppingOrderConfirmation = ({ selectedBillingAddress }: ShoppingOrderConfirmationProps) => {
  const shoppingCartBundles = useAppSelector((state) => state.shoppingCart.bundles)
  const shoppingCartAddresses = useAppSelector((state) => state.shoppingCart.addresses)
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const shoppingCartProductsWithQuantity = shoppingCartProducts.map(shoppingCartProduct => ({ ...shoppingCartProduct, quantity: shoppingCartProduct.quantity ?? 1 }))

  const calculateTotalPrices = (cartProducts: ShoppingCartProduct[], shippingAddresses: ShoppingCartAddress[]) => {
    const subtotal = cartProducts.reduce((accumulator, currentValue) => accumulator + (calculateGraduatedPrice(currentValue.graduatedPrices, currentValue.quantity, currentValue.netRetailPrice.amount) * currentValue.quantity), 0)
    const shippingTotal = shippingAddresses.reduce((accumulator, address) => {
      const totalBundleQuantity = address.assignedBundleIds.reduce((total, bundleId) => {
        const bundle = shoppingCartBundles.find(b => b.id === bundleId)
        return total + (bundle?.quantity || 1)
      }, 0)
      const shippingCost = calculateShippingCost(address.country || 'Germany', totalBundleQuantity)
      return accumulator + (shippingCost || 0)
    }, 0)
    const taxTotal = (subtotal + shippingTotal) * germanyTaxRate
    const total = subtotal + shippingTotal + taxTotal
    return {
      subtotal,
      taxTotal,
      shippingTotal,
      total
    }
  }

  return (
    <div className="row py-4">
      <div className="col-12 col-lg-6">
        <div className="row text-center">
          <div className="text-primary mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </div>
          <h3>Thank you for your purchase!</h3>
          <p>We received your order.</p>
        </div>
        <div className="row">
          <h6 className="fw-bold">Billing Address</h6>
          {selectedBillingAddress && (
            <div className="mb-3">
              <div className="table-responsive rounded border">
                <table className="table table-borderless mb-0">
                  <tbody>
                    <tr>
                      <td className="fw-bold">Company:</td>
                      <td>{selectedBillingAddress.companyName ?? '---'}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold">Address:</td>
                      <td>{selectedBillingAddress.street}, {selectedBillingAddress.zip} {selectedBillingAddress.city}, {selectedBillingAddress.country}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold">Email:</td>
                      <td>{selectedBillingAddress.email}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <div className="row mb-3">
          <div className="col text-center">
            <button
              className="btn btn-primary"
              onClick={() => {
                dispatch(resetShoppingCart())
                dispatch(resetShoppingAddresses())
                navigate('/all-orders')
              }}
            >
              View All Orders
            </button>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6">
        <div className="border rounded p-2">
          <div className="row">
            <div className="d-flex justify-content-between mt-2">
              <h6 className="fw-bold">Order Summary</h6>
              <span className="small">{`${getTotalCartProducts(shoppingCartProductsWithQuantity)} ${getTotalCartProducts(shoppingCartProductsWithQuantity) === 1 ? 'item' : 'items'}`}</span>
            </div>
            <div className="mb-2">
              <div className="border rounded p-2">
                <h6 className="text-muted">Shipping Destination</h6>
                {shoppingCartAddresses.length > 0 && generateAddressText(shoppingCartAddresses[0])}
              </div>
            </div>
            <div className="mb-3">
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                {
                  shoppingCartProducts.map(product => (
                    <div className="col" key={product.splitId ?? product.id}>
                      <div className="card h-100 shadow-none">
                        <img
                          src={product.pictures && product.pictures.length > 0 ? product.pictures[0].publicUrl : Placeholder}
                          className="img-fluid rounded-top p-1"
                          style={{
                            objectFit: product.pictures && product.pictures.length > 0 ? 'contain' : 'cover',
                            maxHeight: 120
                          }}
                          alt={product.name}
                        />
                        <div className="card-body p-1">
                          <h6 className="card-title small">{product.name}</h6>
                        </div>
                        <div className="card-footer border-0 p-1">
                          <p className="card-text small">
                            {formatPrice('EUR', navigator.language).format(calculateGraduatedPrice(product.graduatedPrices, product.quantity, product.netRetailPrice.amount))}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <h6 className="fw-semibold text-muted">Subtotal</h6>
              <p className="fw-bold">{formatPrice('EUR', navigator.language).format(calculateTotalPrices(shoppingCartProductsWithQuantity, shoppingCartAddresses).subtotal)}</p>
            </div>
            <div className="d-flex justify-content-between">
              <h6 className="fw-semibold text-muted">Shipping</h6>
              <p className="fw-bold">{formatPrice('EUR', navigator.language).format(calculateTotalPrices(shoppingCartProductsWithQuantity, shoppingCartAddresses).shippingTotal)}</p>
            </div>
            <div className="d-flex justify-content-between">
              <h6 className="fw-semibold text-muted">Tax</h6>
              <p className="fw-bold">{formatPrice('EUR', navigator.language).format(calculateTotalPrices(shoppingCartProductsWithQuantity, shoppingCartAddresses).taxTotal)}</p>
            </div>
          </div>
          <div className="row border-top gx-3">
            <div className="d-flex justify-content-between my-2">
              <h6 className="fw-semibold text-muted">Total</h6>
              <p className="fw-bold">{formatPrice('EUR', navigator.language).format(calculateTotalPrices(shoppingCartProductsWithQuantity, shoppingCartAddresses).total)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShoppingOrderConfirmation
