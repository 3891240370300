import React, { useState } from 'react'
import Cropper from 'react-easy-crop'
import { getCroppedImg } from '../../utils/getCroppedImg'
import { CropArea } from '../../types'

const ImageCropper = ({ width, height, image, handleCrop }: { width: number; height: number; image: string; handleCrop: (input: File) => void }) => {
  const bodyHeight = `${height * 2}rem`
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)
  const onCropComplete = async (croppedArea: CropArea, croppedAreaPixels: CropArea) => {
    if (!image || isNaN(croppedAreaPixels.x) || isNaN(croppedAreaPixels.y) || isNaN(croppedAreaPixels.width) || isNaN(croppedAreaPixels.height)) {
      return
    }

    try {
      const imageUrl = image
      const updatedCroppedAreaPixels = { ...croppedAreaPixels, rotation }
      const res = await getCroppedImg(imageUrl, updatedCroppedAreaPixels)
      const response = await fetch(res)
      const blob = await response.blob()
      const file = new File([blob], 'croppedImage.jpg', { type: blob.type })
      handleCrop(file)
    } catch (error) {
    }
  }
  return (
    <div className='d-flex flex-column gap-2'>
      <div>
        <p>Click and drag on the image to select the area you want to crop.</p>
      </div>
      <div className="modal-body bg-dark" style={{ height: bodyHeight, maxHeight: '70lvh' }}>
      {image
        ? (
          <Cropper
            image={image}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            maxZoom={10}
            aspect={width / height}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
          )
        : (
          <p>No image selected</p>
          )}

      <div className="controls d-flex position-absolute  w-100 slider-opacity-hover align-items-center  justify-content-center">
        <label className="form-label d-flex gap-3 text-white align-items-center justify-content-center m-0 w-60 rounded ">
          <p className='m-0 '>Rotation</p>
          <input
            type="range"
            className="w-60 form-range cursor-pointer accentColor-primary slider-opacity-hover"
            min="0"
            max="360"
            step="1"
            value={rotation}
            onChange={(e) => setRotation(Number(e.target.value))}
          />
        </label>
      </div>
      <div className=" d-flex position-absolute bottom-0 start-0 w-100 slider-opacity-hover justify-content-center mb-3 ">
        <label className="form-label d-flex gap-3 justify-content-center text-white align-items-center m-0 w-100">
          <i
          className="bi bi-zoom-out cursor-pointer"
          onClick={() => setZoom((prevZoom) => Math.max(prevZoom - 0.1, 1))}
          ></i>
          <input
            type="range"
            className="w-60  form-range cursor-pointer accentColor-primary slider-opacity-hover"
            min="1"
            max="10"
            step="1"
            value={zoom}
            onChange={(e) => setZoom(Number(e.target.value))}
          />
        <i
        className="bi bi-zoom-in cursor-pointer"
        onClick={() => setZoom((prevZoom) => Math.max(prevZoom + 0.1, 1))}
        ></i>

        </label>
      </div>

      </div>

    </div>
  )
}

export default ImageCropper
