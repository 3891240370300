import React from 'react'
import { useAppSelector } from '../../../store/hooks'
import { NavLink } from 'react-router-dom'

const DesignHub = ({ id }:{ id: string | undefined }) => {
  const customisations = useAppSelector((state) => state.apiProduct.productCustomisation)
  const isProductCustomisationLoading = useAppSelector((state) => state.apiProduct.isProductCustomisationLoading)

  return (
    <div className="card p-4 d-flex flex-col gap-4 my-2 mt-4">
      <div className="d-flex align-items-center">
        <p className="h5 m-0">Branded Products Design Hub</p>
        {customisations.length > 0 && (
          <span className="mx-2 bg-danger rounded-circle d-inline-flex justify-content-center align-items-center px-2 ">
            <p className="m-0 p-0 text-white">{customisations.length}</p>
          </span>
        )}
      </div>
      { (isProductCustomisationLoading || !id)
        ? (
            <>
              <p className="placeholder-glow">
                <span className="placeholder col-4"></span>
                <span className="placeholder col-6"></span>
                <span className="placeholder col-8"></span>
              </p>
            </>
          )
        : (
            <NavLink className='btn border d-flex justify-content-between' to={`/branded-products/${id}`}> <p className='m-0'>View and Approve Designs</p> <i className="bi bi-chevron-double-right"></i></NavLink>
          )}
    </div>
  )
}

export default DesignHub
