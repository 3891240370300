import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { CreateProductCustomisationChatParams, ProductCustomisationChatsState } from '../../../types'
import ProductCustomisationChatService from '../../../services/api/ProductCustomisationChatService'

const initialState: ProductCustomisationChatsState = {
  chats: [],
  meta: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  status: 'idle',
  error: null
}

export const getAllProductCustomisationChats = createAsyncThunk(
  'productCustomisationChats/get',
  async (
    { token, productCustomisationId }: { token: string; productCustomisationId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await ProductCustomisationChatService.getProductCustomisationChats({
        token,
        productCustomisationId
      })
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.message || 'Failed to fetch chats')
    }
  }
)

export const createProductCustomisationChat = createAsyncThunk(
  'productCustomisationChats/create',
  async (
    { token, productCustomisationId, productCustomisationChat }: CreateProductCustomisationChatParams,
    { rejectWithValue }
  ) => {
    try {
      const response = await ProductCustomisationChatService.createProductCustomisationChat({
        token,
        productCustomisationId,
        productCustomisationChat
      })
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.message || 'Failed to create chat')
    }
  }
)

export const updateProductCustomisation = createAsyncThunk(
  'productCustomisationChats/update',
  async (
    {
      token,
      productCustomisationId,
      productCustomisationData
    }: { token: string; productCustomisationId: string; productCustomisationData: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await ProductCustomisationChatService.updateProductCustomisation({
        token,
        productCustomisationId,
        productCustomisationData
      })
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.message || 'Failed to update product customisation')
    }
  }
)

const productCustomisationChatsSlice = createSlice({
  name: 'productCustomisationChats',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProductCustomisationChats.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getAllProductCustomisationChats.fulfilled, (state, action: any) => {
        state.status = 'succeeded'
        state.chats = action.payload.productCustomisationChats
        state.meta = action.payload.meta
      })
      .addCase(getAllProductCustomisationChats.rejected, (state, action) => {
        state.status = 'failed'
      })

    builder
      .addCase(createProductCustomisationChat.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(createProductCustomisationChat.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.chats.push(action.payload)
      })
      .addCase(createProductCustomisationChat.rejected, (state, action) => {
        state.status = 'failed'
      })

    builder
      .addCase(updateProductCustomisation.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(updateProductCustomisation.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(updateProductCustomisation.rejected, (state, action) => {
        state.status = 'failed'
      })
  }
})

const { reducer } = productCustomisationChatsSlice
export default reducer
