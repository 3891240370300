import React from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { ShoppingCartBundle, ShoppingCartProduct } from '../../types'
import { formatPrice } from '../../utils/formatPrice'
import { calculateGraduatedPrice } from '../../utils/calculateGraduatedPrice'
import { getMinimumProductOrderQuantity } from '../../utils/getMinimumProductOrderQuantity'
import { getMaximumProductOrderQuantity } from '../../utils/getMaximumProductOrderQuantity'
import {
  setShoppingCart,
  setShoppingCartBundles,
  setShoppingCartLeftOverProducts
} from '../../store/reducers/shoppingCartReducer'
import { setToast } from '../../store/reducers/toastReducer'
import dayjs from 'dayjs'
import isProductQuantityValid from '../../utils/isProductQuantityValid'
interface ShoppingCartArticlesProps {
  isOneAddress: boolean
  onDragStart: (
    e: React.DragEvent<HTMLDivElement>,
    item: ShoppingCartProduct | ShoppingCartBundle
  ) => void
  assignedItemIds: string[]
}

const ShoppingCartArticles = ({
  onDragStart,
  assignedItemIds,
  isOneAddress
}: ShoppingCartArticlesProps) => {
  const shoppingCartProducts = useAppSelector(
    (state) => state.shoppingCart.products
  )
  const shoppingCartProductsWithQuantity = shoppingCartProducts.map(
    (shoppingCartProduct) => ({
      ...shoppingCartProduct,
      quantity: shoppingCartProduct.quantity ?? 1
    })
  )
  const filteredShoppingCartProductsWithQuantity = shoppingCartProductsWithQuantity.filter(
    (product) => product.leftOverQuantity > 0
  )
  const shoppingCartBundles = useAppSelector(
    (state) => state.shoppingCart.bundles
  )
  const filteredShoppingCartBundlesWithQuantity = shoppingCartBundles.filter(
    (bundle) => bundle.quantity > 0
  )
  const leftOverProducts = useAppSelector(
    (state) => state.shoppingCart.leftOverProducts
  )
  const filteredLeftOverProducts = leftOverProducts.filter(
    (product) => product.quantity > 0
  )
  const calculateTotalQuantityInBundles = ({
    shoppingCartProduct
  }: {
    shoppingCartProduct: ShoppingCartProduct
  }): number => {
    return shoppingCartBundles.reduce((total, bundle) => {
      const bundleProduct = bundle.products.find((p) =>
        shoppingCartProduct.splitId && p.splitId
          ? p.splitId === shoppingCartProduct.splitId
          : p.id === shoppingCartProduct.id
      )
      return (
        total + (bundleProduct ? bundleProduct.quantity * bundle.quantity : 0)
      )
    }, 0)
  }
  const maximumQuantity = 1000
  const dispatch = useAppDispatch()

  const calculateTotalPrice = (cartProducts: ShoppingCartProduct[]) => {
    const total = cartProducts.reduce(
      (accumulator, currentValue) =>
        accumulator +
        calculateGraduatedPrice(
          currentValue.graduatedPrices,
          currentValue.quantity,
          currentValue.netRetailPrice.amount
        ) *
          currentValue.quantity,
      0
    )
    return total
  }

  return (
    <div className="bg-light" style={{ maxWidth: '60.5rem' }}>
      <div>
        <div className="row">
          <div className="col">
            <div className="border rounded ">
              <div
                className="d-flex justify-content-center align-items-center border-bottom w-100 "
                style={{
                  paddingLeft: '2rem',
                  paddingRight: '2rem',
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem'
                }}
              >
                <p className="fw-bold me-auto my-auto">Products</p>
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ width: '50%' }}
                >
                  <p className="fw-bold col-4 text-center my-auto">Amount</p>
                  <p className="fw-bold col-4 text-center my-auto">Price</p>
                </div>
              </div>
              <div
                className="bg-light p-3 w-100"
                style={{
                  height: '27rem',
                  overflow: 'auto',
                  scrollbarWidth: 'thin',
                  msOverflowStyle: 'auto'
                }}
              >
                {shoppingCartBundles.length <= 0
                  ? (
                      filteredShoppingCartProductsWithQuantity.length > 0 && filteredShoppingCartBundlesWithQuantity.length <= 0
                        ? (
                            filteredShoppingCartProductsWithQuantity.map(
                              (shoppingCartProduct) => (
                        <div
                          key={`shopping-cart-product${
                            shoppingCartProduct.splitId ??
                            shoppingCartProduct.id
                          }`}
                          className="d-flex justify-content-between align-items-center bg-white p-2 mb-2 rounded w-100"
                          draggable
                          onDragStart={(e) =>
                            onDragStart(e, shoppingCartProduct)
                          }
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={(e) => {
                            e.preventDefault()
                          }}
                          style={{ cursor: 'grab' }}
                        >
                          <div className="col">
                            <span>{shoppingCartProduct.name}</span>
                          </div>
                          <div
                            className="d-flex justify-content-between"
                            style={{ width: '50%' }}
                          >
                            <div
                              className="col-2 text-center"
                              style={{ width: '4rem' }}
                            >
                              <div className="form-group col-12">
                                <input
                                  type="number"
                                  min={Math.max(
                                    calculateTotalQuantityInBundles({
                                      shoppingCartProduct
                                    }),
                                    getMinimumProductOrderQuantity(
                                      shoppingCartProduct.minimumOrderQuantity,
                                      shoppingCartProduct.graduatedPrices
                                    ),
                                    calculateTotalQuantityInBundles({
                                      shoppingCartProduct
                                    })
                                  )}
                                  max={
                                    shoppingCartProduct.isExceedStockEnabled
                                      ? getMaximumProductOrderQuantity(
                                        maximumQuantity,
                                        shoppingCartProduct.graduatedPrices
                                      )
                                      : Math.max(
                                        (shoppingCartProduct.stock
                                          ?.stockLevel || 0) -
                                            (shoppingCartProduct.stock
                                              ?.stockLevelReserved || 0),
                                        0
                                      ) || 1
                                  }
                                  value={shoppingCartProduct.leftOverQuantity}
                                  onChange={(e) => {
                                    const max =
                                      Math.max(
                                        (shoppingCartProduct.stock
                                          ?.stockLevel || 0) -
                                          (shoppingCartProduct.stock
                                            ?.stockLevelReserved || 0),
                                        0
                                      ) || 1
                                    let min = getMinimumProductOrderQuantity(
                                      shoppingCartProduct.minimumOrderQuantity,
                                      shoppingCartProduct.graduatedPrices
                                    )

                                    const totalShoppingCartProductInBundlesQuantity =
                                      calculateTotalQuantityInBundles({
                                        shoppingCartProduct
                                      })

                                    min = Math.max(
                                      min,
                                      totalShoppingCartProductInBundlesQuantity
                                    )
                                    const value = Number(e.target.value)
                                    const quantity =
                                      shoppingCartProduct.isExceedStockEnabled
                                        ? Math.max(
                                          Math.min(
                                            value,
                                            getMaximumProductOrderQuantity(
                                              maximumQuantity,
                                              shoppingCartProduct.graduatedPrices
                                            )
                                          ),
                                          min
                                        )
                                        : Math.max(Math.min(value, max), min)
                                    const foundProductInCartIndex =
                                      shoppingCartProductsWithQuantity.findIndex(
                                        (cartProduct) =>
                                          shoppingCartProduct.splitId &&
                                          cartProduct.splitId
                                            ? cartProduct.splitId ===
                                              shoppingCartProduct.splitId
                                            : cartProduct.id ===
                                              shoppingCartProduct.id
                                      )

                                    if (foundProductInCartIndex !== -1) {
                                      const updatedProductsInCart = [
                                        ...shoppingCartProductsWithQuantity
                                      ]
                                      updatedProductsInCart[
                                        foundProductInCartIndex
                                      ] = {
                                        ...updatedProductsInCart[
                                          foundProductInCartIndex
                                        ],
                                        quantity,
                                        leftOverQuantity:
                                        quantity > shoppingCartProduct.quantity
                                          ? updatedProductsInCart[
                                            foundProductInCartIndex
                                          ].leftOverQuantity + 1
                                          : updatedProductsInCart[
                                            foundProductInCartIndex
                                          ].leftOverQuantity - 1,
                                        isQuantityValid: isProductQuantityValid(quantity, shoppingCartProduct)
                                      }
                                      const payload = {
                                        products: updatedProductsInCart
                                      }
                                      dispatch(setShoppingCart(payload))
                                    }
                                  }}
                                  name="quantity"
                                  className="form-control"
                                  aria-label={'Cart Item Quantity'}
                                  autoComplete="off"
                                  disabled={false}
                                />
                              </div>
                            </div>
                            <div className="col-2 text-center w-40">
                              <span>
                                {formatPrice('EUR', navigator.language).format(
                                  calculateGraduatedPrice(
                                    shoppingCartProduct.graduatedPrices,
                                    shoppingCartProduct.quantity,
                                    shoppingCartProduct.netRetailPrice.amount
                                  ) * shoppingCartProduct.quantity
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                              )
                            )
                          )
                        : (
                    <div className="text-center h-100 d-flex justify-content-center align-items-center">
                      <p className="">No item has been added to your cart.</p>
                    </div>
                          )
                    )
                  : (
                  <>
                    {filteredShoppingCartBundlesWithQuantity.map(
                      (bundle, index) => (
                        <div
                          key={`shopping-cart-bundle${
                            bundle.splitId ?? bundle.id
                          }`}
                          className="d-flex justify-content-between align-items-center bg-white p-2 mb-2 rounded w-100"
                          draggable
                          onDragStart={(e) => onDragStart(e, bundle)}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={(e) => {
                            e.preventDefault()
                          }}
                          style={{ cursor: 'grab' }}
                        >
                          <div className="col">
                            <span>{bundle.name}</span>
                          </div>
                          <div
                            className="d-flex justify-content-between"
                            style={{ width: '50%' }}
                          >
                            <div
                              className="col-2 text-center"
                              style={{ width: '4rem' }}
                            >
                                <div className="form-group col-12">
                                  <input
                                    type="number"
                                    min={1}
                                    value={bundle.quantity}
                                    onChange={(e) => {
                                      const value = Number(e.target.value)
                                      const quantity = Math.max(value, 1)

                                      const maxQuantity = Math.min(
                                        ...bundle.products.map((product) =>
                                          product.isExceedStockEnabled
                                            ? getMaximumProductOrderQuantity(maximumQuantity, product.graduatedPrices)
                                            : Math.max(
                                              (product.stock?.stockLevel || 0) - (product.stock?.stockLevelReserved || 0),
                                              0
                                            ) || 1
                                        )
                                      )

                                      if (quantity > maxQuantity) {
                                        const payload = {
                                          title: 'Warning',
                                          message: 'You have reached the maximum quantity available for this bundle',
                                          isVisible: true,
                                          timestamp: dayjs().format('LT'),
                                          type: 'warning'
                                        }
                                        dispatch(setToast(payload))
                                        return
                                      }

                                      const updatedProducts = shoppingCartProducts.map((cartProduct) => {
                                        const productInBundle = bundle.products.find((bundleProduct) =>
                                          bundleProduct.splitId && cartProduct.splitId
                                            ? bundleProduct.splitId === cartProduct.splitId
                                            : !bundleProduct.splitId && !cartProduct.splitId && bundleProduct.id === cartProduct.id
                                        )

                                        if (productInBundle) {
                                          let productQuantity = cartProduct.quantity

                                          if (quantity > bundle.quantity) {
                                            productQuantity += 1
                                          } else if (quantity < bundle.quantity) {
                                            productQuantity -= 1
                                          }

                                          return { ...cartProduct, quantity: productQuantity, isQuantityValid: isProductQuantityValid(productQuantity, cartProduct) }
                                        }

                                        return cartProduct
                                      })

                                      dispatch(setShoppingCart({ products: updatedProducts }))

                                      const updatedBundles = shoppingCartBundles.map((b) =>
                                        b.splitId && bundle.splitId
                                          ? b.splitId === bundle.splitId
                                          : !b.splitId && !bundle.splitId && b.id === bundle.id
                                              ? { ...b, quantity, totalQuantity: quantity > bundle.quantity ? b.totalQuantity + 1 : b.totalQuantity - 1 }
                                              : b
                                      )
                                      dispatch(setShoppingCartBundles({ bundles: updatedBundles }))
                                    }}
                                    name="bundle-configurator-bundle-quantity"
                                    className="form-control"
                                    aria-label={'Bundle Quantity'}
                                    autoComplete="off"
                                    disabled={false}
                                  />
                                </div>
                            </div>
                            <div className="col-2 text-center w-40">
                              <span>
                                {formatPrice('EUR', navigator.language).format(
                                  calculateTotalPrice(
                                    bundle.products as ShoppingCartProduct[]
                                  ) * bundle.quantity
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                    {filteredLeftOverProducts.map((leftOverProduct) => (
                      <div
                        key={`left-over-product${
                          leftOverProduct.splitId ?? leftOverProduct.id
                        }`}
                        className="d-flex justify-content-between align-items-center bg-white p-2 mb-2 rounded w-100"
                        draggable
                        onDragStart={(e) => onDragStart(e, leftOverProduct)}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => {
                          e.preventDefault()
                        }}
                        style={{ cursor: 'grab' }}
                      >
                        <div className="col">
                          <span>{leftOverProduct.name}</span>
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ width: '50%' }}
                        >
                          <div
                            className="col-2 text-center"
                            style={{ width: '4rem' }}
                          >
                            <div className="form-group col-12">
                              <input
                                type="number"
                                min={1}
                                max={
                                  leftOverProduct.isExceedStockEnabled
                                    ? getMaximumProductOrderQuantity(
                                      maximumQuantity,
                                      leftOverProduct.graduatedPrices
                                    )
                                    : Math.max(
                                      (leftOverProduct.stock
                                        ?.stockLevel || 0) -
                                          (leftOverProduct.stock
                                            ?.stockLevelReserved || 0),
                                      0
                                    ) || 1
                                }
                                value={leftOverProduct.quantity}
                                onChange={(e) => {
                                  const value = Number(e.target.value)
                                  const quantity = Math.max(
                                    Math.min(value, maximumQuantity),
                                    1
                                  )
                                  let foundLeftOverProductInCartIndex = -1
                                  if (leftOverProduct.splitId) {
                                    foundLeftOverProductInCartIndex =
                                      leftOverProducts.findIndex(
                                        (cartProduct) =>
                                          cartProduct.splitId ===
                                          leftOverProduct.splitId
                                      )
                                  }
                                  if (foundLeftOverProductInCartIndex === -1) {
                                    foundLeftOverProductInCartIndex =
                                      leftOverProducts.findIndex(
                                        (cartProduct) =>
                                          cartProduct.id === leftOverProduct.id
                                      )
                                  }

                                  if (foundLeftOverProductInCartIndex !== -1) {
                                    const updatedLeftOverProductsInCart = [
                                      ...leftOverProducts
                                    ]
                                    updatedLeftOverProductsInCart[
                                      foundLeftOverProductInCartIndex
                                    ] = {
                                      ...updatedLeftOverProductsInCart[
                                        foundLeftOverProductInCartIndex
                                      ],
                                      quantity
                                    }
                                    const payload = {
                                      leftOverProducts:
                                        updatedLeftOverProductsInCart
                                    }
                                    const foundProductInCartIndex = shoppingCartProducts.findIndex(
                                      (cartProduct) =>
                                        leftOverProduct.splitId
                                          ? cartProduct.splitId === leftOverProduct.splitId
                                          : cartProduct.id === leftOverProduct.id
                                    )

                                    if (foundProductInCartIndex !== -1) {
                                      const updatedProductsInCart = [...shoppingCartProducts]
                                      updatedProductsInCart[foundProductInCartIndex] = {
                                        ...updatedProductsInCart[foundProductInCartIndex],
                                        quantity,
                                        isQuantityValid: isProductQuantityValid(quantity, leftOverProduct)
                                      }
                                      dispatch(setShoppingCart({ products: updatedProductsInCart }))
                                    }
                                    dispatch(
                                      setShoppingCartLeftOverProducts(payload)
                                    )
                                  }
                                }}
                                name="quantity"
                                className="form-control"
                                aria-label={'Left Over Product Quantity'}
                                autoComplete="off"
                                disabled={false}
                              />
                            </div>
                          </div>
                            <div className="col-2 text-center w-40">
                            <span>
                              {formatPrice('EUR', navigator.language).format(
                                calculateGraduatedPrice(
                                  leftOverProduct.graduatedPrices,
                                  leftOverProduct.quantity,
                                  leftOverProduct.netRetailPrice.amount
                                ) * leftOverProduct.quantity
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                    )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShoppingCartArticles
