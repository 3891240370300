import React, { ChangeEvent, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { Formik } from 'formik'
import { object, string } from 'yup'

import { useAppSelector, useAppDispatch } from '../../../store/hooks'
import { getAllJtlShippingMethods } from '../../../store/reducers/api/shippingMethodReducer'
import TableHead from '../../../components/Orders/Table/TableHead'
import TableBody from '../../../components/Orders/Table/TableBody'
import {
  COMPANY_DOMAIN_UNVERIFIED_MESSAGE,
  EMAIL_UNVERIFIED_MESSAGE
} from '../../../constants/messages'
import { setToast } from '../../../store/reducers/toastReducer'
import useDebounce from '../../../utils/hooks/useDebounce'
import * as userRoles from '../../../constants/userRoles'
import { useParams } from 'react-router-dom'
import Pagination from '../../../components/Pagination'
import Progress from '../../../components/loaders/Progress'
import {
  getCampaignOrders,
  getCampaignOrdersForBundle,
  resetCampaignOrders,
  setCampaignOrdersExportMetadata
} from '../../../store/reducers/api/campaignReducer'
import { getNormalizedOrderData } from '../../../utils/getNormalizedExportData'
import OrderExportModal from '../../../components/Orders/OrderExport/OrderExportModal'

dayjs.extend(localizedFormat)

const CampaignBundles = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiCampaign.isLoading)
  const orders = useAppSelector((state) => state.apiCampaign.campaignOrders)
  const metadata = useAppSelector((state) => state.apiCampaign.campaignOrdersMetadata)
  const error = useAppSelector((state) => state.apiCampaign.error)

  const storedPage = 1
  const storedPerPage = 10

  const token = currentUser?.token

  const [perPage, setPerPage] = useState(storedPerPage)
  const [page, setPage] = useState(storedPage)
  const [filter, setFilter] = useState('')
  const [exportType, setExportType] = useState<'allPages' | 'currentPage'>('currentPage')
  const [exportData, setExportData] = useState<Array<any>>([])

  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 800)

  const [selectedOutboundIds, setSelectedOutboundIds] = useState<Array<string>>([])

  const dispatch = useAppDispatch()
  const { campaignId, jfsku } = useParams()

  const checked = selectedOutboundIds.length !== 0 && (selectedOutboundIds.length === orders.filter((order) => order.status !== 'Shipped').length)

  const columns = [
    {
      name: 'Select',
      className: 'cursor-pointer',
      onChange: () => {
        if (checked) {
          setSelectedOutboundIds([])
        } else {
          if (orders.filter((order) => order.status !== 'Shipped').map((order) => order.outboundId).length === 0) {
            const payload = {
              title: 'Info',
              message: 'Select orders that have not yet been shipped',
              type: 'info',
              isVisible: true,
              timestamp: dayjs().format('LT')
            }
            dispatch(setToast(payload))
          } else {
            setSelectedOutboundIds(orders.filter((order) => order.status !== 'Shipped').map((order) => order.outboundId))
          }
        }
      },
      checked
    },
    {
      name: 'Name',
      className: ''
    },
    {
      name: 'Details',
      className: ''
    },
    {
      name: 'Status',
      className: ''
    },
    {
      name: 'Date Shipped',
      className: ''
    },
    {
      name: 'Date Delivered',
      className: ''
    },
    {
      name: 'Created By',
      className: ''
    },
    {
      name: 'Amount of boxes',
      className: 'text-center'
    },
    {
      name: 'Actions',
      className: 'text-center'
    }
  ]

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handleBundleOrdersRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && campaignId) {
      if (jfsku) {
        dispatch(getCampaignOrdersForBundle({ id: campaignId, jfsku, token, perPage, page, search: debouncedSearchTerm, filter, signal }))
      } else {
        dispatch(getCampaignOrders({ id: campaignId, token, perPage, page, search: debouncedSearchTerm, filter, signal }))
      }
    }
  }

  const filterSchema = object({
    email: string().email('Enter a valid Email'),
    lastName: string()
      .min(2, 'Enter a valid Last Name')
      .max(32, 'Last Name is too long'),
    city: string()
      .min(2, 'Enter a valid City Name')
      .max(32, 'City Name is too long'),
    company: string()
      .min(2, 'Enter a valid Company Name')
      .max(32, 'Company Name is too long')
  })

  const searchSchema = object({
    search: string()
      .max(12, 'Search Name is too long')
  })

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    dispatch(resetCampaignOrders())

    if (token && campaignId) {
      if (jfsku) {
        dispatch(getCampaignOrdersForBundle({ id: campaignId, jfsku, token, perPage, page, search: debouncedSearchTerm, filter, signal }))
      } else {
        dispatch(getCampaignOrders({ id: campaignId, token, perPage, page, search: debouncedSearchTerm, filter, signal }))
      }
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page, filter, debouncedSearchTerm, jfsku])

  useEffect(() => {
    if (error && error?.errors.message === EMAIL_UNVERIFIED_MESSAGE) {
      const payload = {
        title: 'Verification Pending',
        message: 'Please verify your email address in the Profile section',
        type: 'danger',
        isVisible: true,
        timestamp: dayjs().format('LT')
      }
      dispatch(setToast(payload))
    }

    if (error && error?.errors.message === COMPANY_DOMAIN_UNVERIFIED_MESSAGE) {
      const payload = {
        title: 'Verification Pending',
        message: "Please verify your company domain in the 'My Company' section",
        type: 'danger',
        isVisible: true,
        timestamp: dayjs().format('LT')
      }
      dispatch(setToast(payload))
    }
  }, [error])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (currentUser?.token) {
      dispatch(
        getAllJtlShippingMethods({
          token: currentUser?.token,
          perPage: 50,
          page: 1,
          signal
        })
      )
    }

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <div className="card">
          <div className="m-4">
            <div className="navbar navbar-expand mb-0">
              <p className="h5"><i className="bi bi-list-ul me-1"></i> Shipping Orders</p>
              <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                  <Formik
                    validationSchema={searchSchema}
                    enableReinitialize
                    initialValues={{
                      search: ''
                    }}
                    onSubmit={({ search }, actions) => {
                      setSearchTerm(search)
                      setPage(1)

                      actions.setSubmitting(false)
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="input-group">
                          <input
                            onChange={(event) => {
                              const search = event.target.value
                              handleChange(event)
                              setSearchTerm(search)
                              setPage(1)
                            }}
                            maxLength={12}
                            onBlur={handleBlur}
                            value={values.search}
                            className={`form-control ${
                              errors.search &&
                              touched.search &&
                              errors.search
                                ? 'is-invalid'
                                : ''
                            }`}
                            type="text"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="btnNavbarSearch"
                            name="search"
                          />
                          <button
                            className="btn btn-outline-dark"
                            id="btnNavbarSearch"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  data-bs-toggle="collapse"
                  data-bs-target="#filterOptions"
                  aria-expanded="false"
                  aria-controls="filterOptions"
                >
                  <i className="fas fa-filter"></i>
                </button>
                <button
                  type="button"
                  title="Export to CSV"
                  data-bs-toggle="modal"
                  data-bs-target="#exportToCSV"
                  onClick={() => {
                    setExportType('currentPage')
                    const csvData = getNormalizedOrderData(orders)
                    setExportData(csvData)
                    dispatch(setCampaignOrdersExportMetadata({
                      campaignId,
                      jfsku
                    }))
                  }}
                  aria-label="Export to CSV"
                  className="btn btn-outline-dark ms-2 ms-md-3"
                >
                  <i className="bi bi-filetype-csv"></i>
                </button>
                <button
                  type="button"
                  title="Refresh"
                  aria-label="Refresh"
                  className="btn btn-outline-dark ms-2 ms-md-3"
                  onClick={() => handleBundleOrdersRefresh()}
                >
                  <i className="fas fa-refresh"></i>
                </button>
              </ul>
            </div>
            <div className="accordion" id="filterAccordion">
              <div>
                <div
                  id="filterOptions"
                  className="accordion-collapse collapse"
                  data-bs-parent="#filterAccordion"
                >
                  <div className="accordion-body">
                    <Formik
                      validationSchema={filterSchema}
                      enableReinitialize
                      initialValues={{
                        email: '',
                        lastName: '',
                        city: '',
                        company: ''
                      }}
                      onSubmit={({ email, lastName, city, company }, actions) => {
                        const filterByEmail = email ? `filter[email]=${email}` : ''
                        const filterByLastName = lastName ? `filter[lastname]=${lastName}` : ''
                        const filterByCity = city ? `filter[city]=${city}` : ''
                        const filterByCompany = company ? `filter[company]=${company}` : ''

                        const filterString = [filterByEmail, filterByLastName, filterByCity, filterByCompany]
                          .filter((filter: string) => filter)
                          .join('&')

                        setFilter(filterString)
                        setPage(1)

                        actions.setSubmitting(false)
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        resetForm
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="inputEmail"
                                  className="form-label"
                                >
                                  Email
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                  type="email"
                                  className={`form-control ${
                                    errors.email &&
                                    touched.email &&
                                    errors.email
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id="inputEmail"
                                  name="email"
                                />
                                <div
                                  id="validationEmailFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.email}
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="inputLastName"
                                  className="form-label"
                                >
                                  Last Name
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.lastName}
                                  type="text"
                                  className={`form-control ${
                                    errors.lastName &&
                                    touched.lastName &&
                                    errors.lastName
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id="inputLastName"
                                  name="lastName"
                                />
                                <div
                                  id="validationLastNameFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.lastName}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="inputCity"
                                  className="form-label"
                                >
                                  City
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.city}
                                  type="text"
                                  className={`form-control ${
                                    errors.city &&
                                    touched.city &&
                                    errors.city
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id="inputCity"
                                  name="city"
                                />
                                <div
                                  id="validationCityFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.city}
                                </div>
                              </div>
                            </div>
                            {
                              (currentUser?.role === userRoles.ADMIN) && (
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="inputCompany"
                                      className="form-label"
                                    >
                                      Company
                                    </label>
                                    <input
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.company}
                                      type="text"
                                      className={`form-control ${
                                        errors.company &&
                                        touched.company &&
                                        errors.company
                                          ? 'is-invalid'
                                          : ''
                                      }`}
                                      id="inputCompany"
                                      name="company"
                                    />
                                    <div
                                      id="validationCompanyFeedback"
                                      className="invalid-feedback"
                                    >
                                      {errors.company}
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                          <hr />
                          <div className="d-flex justify-content-end">
                            <button
                              type="reset"
                              className="btn btn-secondary me-2"
                              disabled={isSubmitting}
                              onClick={() => {
                                resetForm()
                                setFilter('')
                              }}
                            >
                              Clear
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              {`Apply Filter${
                                values.email && values.lastName ? 's' : ''
                              }`}
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            {isLoading ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap">
                <TableHead columns={columns} />
                <TableBody
                  orders={orders}
                  isLoading={isLoading}
                  type="shipping-order"
                  colSpan={columns.length}
                  selectedOutboundIds={selectedOutboundIds}
                  setSelectedOutboundIds={setSelectedOutboundIds}
                />
              </table>
            </div>
            <Pagination
              isLoading={isLoading}
              metadata={{
                limit: metadata.perPage,
                total: metadata.total,
                offset: ((metadata.page - 1) * (metadata.perPage))
              }}
              page={page}
              perPage={perPage}
              handlePageChange={handlePageChange}
              handleShowEntries={handleShowEntries}
            />
          </div>
        </div>
      </div>
      {/* Modals */}
      <OrderExportModal
        exportType={exportType}
        setExportType={setExportType}
        exportData={exportData}
        component={jfsku ? 'campaignOrdersForBundle' : 'campaignOrders'}
      />
    </main>
  )
}

export default CampaignBundles
