import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CampaignAdditionalProductSettingService from '../../../services/api/CampaignAdditionalProductSettingService'
import { ApiCampaignAdditionalProductSettingState, CampaignAdditionalProductSetting } from '../../../types'

const initialState: ApiCampaignAdditionalProductSettingState = {
  isLoading: false,
  error: null,
  message: null,
  campaignAdditionalProductSetting: null
}

export const editAdditionalProductSetting = createAsyncThunk(
  'additionalProductSetting/edit',
  async ({ token, campaignAdditionalProductSettingId, campaignAdditionalProductSetting, signal }: { token: string, campaignAdditionalProductSettingId: string, campaignAdditionalProductSetting: CampaignAdditionalProductSetting, signal: AbortSignal }, { rejectWithValue }) => {
    try {
      const response = await CampaignAdditionalProductSettingService.editAdditionalProductSetting(campaignAdditionalProductSettingId, token, campaignAdditionalProductSetting, signal)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteAdditionalProductSetting = createAsyncThunk(
  'additionalProductSetting/delete',
  async ({ token, campaignAdditionalProductSettingId, signal }: { token: string, campaignAdditionalProductSettingId: string, signal: AbortSignal }, { rejectWithValue }) => {
    try {
      const response = await CampaignAdditionalProductSettingService.deleteAdditionalProductSetting(campaignAdditionalProductSettingId, token, signal)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)

const campaignSlice = createSlice({
  name: 'api/campaign',
  initialState,
  reducers: {
    resetCampaignAdditionalProductSettingError: (state) => {
      state.error = null
    },
    resetCampaignAdditionalProductSettingMessage: (state) => {
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(editAdditionalProductSetting.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(editAdditionalProductSetting.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(editAdditionalProductSetting.rejected, (state, action) => {
        state.isLoading = false
      })
    builder
      .addCase(deleteAdditionalProductSetting.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(deleteAdditionalProductSetting.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(deleteAdditionalProductSetting.rejected, (state, action) => {
        state.isLoading = false
      })
  }
})

export const {
  resetCampaignAdditionalProductSettingError, resetCampaignAdditionalProductSettingMessage
} = campaignSlice.actions

const { reducer } = campaignSlice
export default reducer
