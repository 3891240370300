import React, { ChangeEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import * as userRoles from '../../constants/userRoles'
import { addPrivacyRule, deletePrivacyRule, getPrivacyRules, resetPrivacyRuleError, updatePrivacyRule } from '../../store/reducers/api/privacyRuleReducer'
import Pagination from '../../components/Pagination'
import Progress from '../../components/loaders/Progress'
import { PencilIcon } from '../../components/icons/PencilIcon'
import { TrashIcon } from '../../components/icons/TrashIcon'
import { PrivacyRule } from '../../types'
import SkeletonTableRow from '../../components/loaders/skeleton/SkeletonTableRow'
import dayjs from 'dayjs'
import PrivacyRuleEditor from '../../components/PrivacyRule/PrivacyRuleEditor'
import { setToast } from '../../store/reducers/toastReducer'
import { dismissModal } from '../../utils/dismissModal'
import { PRIVACY_RULE_DELETE_MESSAGE, PRIVACY_RULE_UPDATE_MESSAGE } from '../../constants/messages'
import { modulesObject } from '../../constants/appModules'

export default function PrivacyRules () {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const privacyRules = useAppSelector((state) => state.apiPrivacyRule.privacyRules)
  const isLoading = useAppSelector((state) => state.apiPrivacyRule.isLoading)
  const metadata = useAppSelector((state) => state.apiPrivacyRule.metadata)
  const error = useAppSelector((state) => state.apiPrivacyRule.error)
  const message = useAppSelector((state) => state.apiPrivacyRule.message)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [initialPrivacyRule, setInitialPrivacyRule] = useState<Partial<PrivacyRule>>({
    module: '',
    role: '',
    isEnabled: true
  })

  const token = currentUser?.token

  const role = profile?.role || userRoles.USER

  const dispatch = useAppDispatch()

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handlePrivacyRuleRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token && role === userRoles.ADMIN) {
      dispatch(getPrivacyRules({ token, perPage, page, signal }))
    }
  }

  useEffect(() => {
    if (token && (role === userRoles.ADMIN)) {
      handlePrivacyRuleRefresh()
    }
  }, [page, perPage])

  useEffect(() => {
    const allowedMessages = [
      PRIVACY_RULE_UPDATE_MESSAGE,
      PRIVACY_RULE_DELETE_MESSAGE
    ]
    if (message && allowedMessages.includes(message)) {
      const payload = {
        title: 'Success',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dismissModal('privacyRuleEditModal')
      dismissModal('privacyRuleConfirmationModal')
      handlePrivacyRuleRefresh()
    }
  }, [message])

  useEffect(() => {
    if (error && error?.errors) {
      const payload = {
        title: 'Error',
        message: error?.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetPrivacyRuleError())
    }
  }, [error])

  return (
    <main className="container-fluid px-4 py-4">
      <div className="card">
      <div className="m-4">
          <div className="navbar navbar-expand mb-3">
            <p className="h5"><i className="bi bi-shield-check me-1"></i>Privacy Rules</p>
            <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
              <button
                type="button"
                title="Refresh"
                aria-label="Refresh"
                className="btn btn-outline-dark ms-2 ms-md-3"
                onClick={() => handlePrivacyRuleRefresh()}
              >
                <i className="fas fa-redo"></i>
              </button>
            </ul>
          </div>
          {(isLoading) ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
          <div className="table-responsive">
            <table className="table table-hover table-centered table-nowrap align-middle">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Module</th>
                  <th scope="col">Role</th>
                  <th scope="col" className="text-center">Enabled</th>
                  <th scope="col">Date Created</th>
                  <th scope="col" className="text-end">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  (isLoading)
                    ? (
                        Array.from(Array(10).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={6} actionQuantity={2} />)
                      )
                    : (
                        privacyRules.length > 0
                          ? (privacyRules.map((privacyRule: PrivacyRule) => (
                            <tr key={privacyRule.id} title={privacyRule.id}>
                              <td className="user-select-all" onClick={() => navigator?.clipboard.writeText(String(privacyRule.id))}>
                                {(privacyRule.id).substring(0, 8)}
                              </td>
                              <td className="text-truncate">
                                {modulesObject[privacyRule.module]}
                              </td>
                              <td className="text-nowrap">{userRoles.rolesObject[privacyRule.role]}</td>
                              <td className="text-center">
                                <span>
                                  {privacyRule.isEnabled ? <i className="bi bi-check-circle-fill text-success"></i> : <i className="bi bi-x-circle-fill text-danger"></i>}
                                </span>
                              </td>
                              <td className="text-nowrap">{dayjs(privacyRule.createdAt).format('ll')}</td>
                              <td className="text-end">
                                <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                                  <button
                                    className="btn btn-outline-dark btn-round me-2"
                                    type="button"
                                    title="Edit Privacy Rule"
                                    data-bs-toggle="modal"
                                    data-bs-target="#privacyRuleEditModal"
                                    onClick={() => {
                                      setInitialPrivacyRule(privacyRule)
                                    }}
                                  >
                                    <PencilIcon/>
                                  </button>
                                  <button
                                    className="btn btn-outline-danger btn-round"
                                    type="button"
                                    title="Delete Privacy Rule"
                                    data-bs-toggle="modal"
                                    data-bs-target="#privacyRuleConfirmationModal"
                                    onClick={() => {
                                      setInitialPrivacyRule(privacyRule)
                                    }}
                                  >
                                    <TrashIcon/>
                                  </button>
                                </div>
                              </td>
                            </tr>
                            ))
                            )
                          : (
                            <tr>
                              <td colSpan={6} className="text-center">
                                No privacy rule available yet
                              </td>
                            </tr>
                            )
                      )
                }
              </tbody>
            </table>
          </div>
          <Pagination
            isLoading={isLoading}
            metadata={{
              limit: metadata.perPage,
              total: metadata.total,
              offset: ((metadata.page - 1) * (metadata.perPage))
            }}
            page={page}
            perPage={perPage}
            handlePageChange={handlePageChange}
            handleShowEntries={handleShowEntries}
          />
        </div>
      </div>

      <div className="modal fade" id="addPrivacyRuleModal" tabIndex={-1} aria-labelledby="addPrivacyRuleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addPrivacyRuleModalLabel">
                <i className={'bi bi-plus-circle me-1'}></i>{' '}
                {'Add Privacy Rule'}
              </h5>
              <button type="button"
                onClick={() => dispatch(resetPrivacyRuleError())}
                className="btn-close" data-bs-dismiss="modal" aria-label="Close"
              >
              </button>
            </div>
            {(isLoading) && <Progress marginBottom={false} />}
            <div className="modal-body">
              <PrivacyRuleEditor
                id={''}
                initialPrivacyRule={initialPrivacyRule}
                save={addPrivacyRule}
                isEdit={false}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="privacyRuleEditModal" tabIndex={-1} aria-labelledby="PrivacyRuleEditModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="PrivacyRuleEditModalLabel">
                <i className={'bi bi-plus-circle me-1'}></i>{' '}
                {'Edit Privacy Rule'}
              </h5>
              <button type="button"
                onClick={() => dispatch(resetPrivacyRuleError())}
                className="btn-close" data-bs-dismiss="modal" aria-label="Close"
              >
              </button>
            </div>
            {isLoading && <Progress marginBottom={false} />}
            <div className="modal-body">
              <PrivacyRuleEditor
                id={String(initialPrivacyRule.id)}
                initialPrivacyRule={initialPrivacyRule}
                save={updatePrivacyRule}
                isEdit={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="privacyRuleConfirmationModal" tabIndex={-1} aria-labelledby="privacyRuleConfirmationModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title text-danger" id="privacyRuleConfirmationModalLabel">
                <i className="bi bi-trash text-danger me-2"></i>Confirm Delete
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoading && <Progress marginBottom={false} />}
            <div className="modal-body">
              {
                initialPrivacyRule.id
                  ? (
                    <p>
                      Are you sure you want to delete
                      <span className="fw-bold">{` '${(initialPrivacyRule.id).substring(0, 8)}' for module ${modulesObject[initialPrivacyRule?.module || '']} and role ${userRoles.rolesObject[initialPrivacyRule?.role || '']} `}</span>
                      privacy rule?
                    </p>
                    )
                  : (
                    <p>
                      No privacy rule selected
                    </p>
                    )
              }
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  if (token && initialPrivacyRule.id !== null) {
                    const controller = new AbortController()
                    const signal = controller.signal
                    dispatch(deletePrivacyRule({ privacyId: String(initialPrivacyRule.id), token, signal }))
                    handlePrivacyRuleRefresh()
                  }
                }}
                disabled={isLoading}
                aria-label="Delete"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
