import React, { useState } from 'react'
import { Formik } from 'formik'
import { number, object, string, array } from 'yup'
import dayjs from 'dayjs'
import CreatableSelect from 'react-select/creatable'
import { useAppDispatch } from '../../store/hooks'
import { setToast } from '../../store/reducers/toastReducer'
import { dismissModal } from '../../utils/dismissModal'
import { PRODUCT_STOCK_NOTIFICATION_CREATION_SUCCESS_MESSAGE, PRODUCT_STOCK_NOTIFICATION_UPDATE_SUCCESS_MESSAGE } from '../../constants/messages'
import { ProductStockNotification } from '../../types'
import { createProductStockNotification } from '../../store/reducers/api/productReducer'
import { resetProductStockNotificationMessage, updateProductStockNotification } from '../../store/reducers/api/productStockNotificationReducer'

interface ProductStockNotificationEditorProps {
  token: string
  productId: string
  handleProductStockNotificationRefresh: () => void
  isLoading: boolean
  selectedProductStockNotification: ProductStockNotification | null
  editLowStockNotification: boolean
}

const ProductStockNotificationEditor = ({
  handleProductStockNotificationRefresh,
  token,
  productId,
  isLoading,
  selectedProductStockNotification,
  editLowStockNotification
}: ProductStockNotificationEditorProps) => {
  const lowStockNotificationSchema = object().shape({
    recipients: array()
      .label('Recipient Emails')
      .of(string().email('Recipient Emails must be valid emails'))
      .min(1),
    frequency: number().min(1).label('Frequency').required(),
    frequencyUnit: string().label('Frequency Unit').oneOf(['hour', 'hours', 'day', 'days', 'week', 'weeks', 'month', 'months']).required(),
    quantity: number().min(1).label('Quantity').required()
  })

  const dispatch = useAppDispatch()
  const [recipientInput, setRecipientInput] = useState<string>('')

  const initialValues = {
    threshold: selectedProductStockNotification?.threshold || 0,
    recipients: selectedProductStockNotification?.recipients || [],
    frequency: selectedProductStockNotification?.frequency || 0,
    frequencyUnit: selectedProductStockNotification?.frequencyUnit || 'days',
    quantity: selectedProductStockNotification?.quantity || 0
  }

  const components = {
    DropdownIndicator: null
  }

  return (
    <div>
      <Formik
        enableReinitialize
        validationSchema={lowStockNotificationSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          const productStockNotification = {
            threshold: values.threshold,
            recipients: values.recipients,
            frequency: values.frequency,
            frequencyUnit: values.frequencyUnit as 'hour' | 'hours' | 'day' | 'days' | 'week' | 'weeks' | 'month' | 'months',
            quantity: values.quantity
          }
          const controller = new AbortController()
          const signal = controller.signal
          if (!editLowStockNotification) {
            dispatch(createProductStockNotification({ token, productId, productStockNotification, signal }))
              .unwrap()
              .then(() => {
                const payload = {
                  title: 'Success',
                  message: PRODUCT_STOCK_NOTIFICATION_CREATION_SUCCESS_MESSAGE,
                  isVisible: true,
                  timestamp: dayjs().format('LT'),
                  type: 'success'
                }
                dismissModal('createProductStockNotificationModal')
                dispatch(setToast(payload))
                handleProductStockNotificationRefresh()
                actions.resetForm()
                actions.setFieldValue('recipients', [])
              })
              .catch(() => {
                const payload = {
                  title: 'Error',
                  message: 'Failed to add notification',
                  isVisible: true,
                  timestamp: dayjs().format('LT'),
                  type: 'danger'
                }
                dispatch(setToast(payload))
              })
          } else {
            if (selectedProductStockNotification?.id) {
              dispatch(
                updateProductStockNotification({
                  token,
                  productStockNotificationId: selectedProductStockNotification.id,
                  productStockNotification,
                  signal
                })
              )
                .unwrap()
                .then(() => {
                  const payload = {
                    title: 'Success',
                    message: PRODUCT_STOCK_NOTIFICATION_UPDATE_SUCCESS_MESSAGE,
                    isVisible: true,
                    timestamp: dayjs().format('LT'),
                    type: 'success'
                  }
                  dismissModal('createProductStockNotificationModal')
                  dispatch(setToast(payload))
                  handleProductStockNotificationRefresh()
                })
                .catch(() => {
                  const payload = {
                    title: 'Error',
                    message: 'Failed to update notification',
                    isVisible: true,
                    timestamp: dayjs().format('LT'),
                    type: 'danger'
                  }
                  dispatch(setToast(payload))
                }).finally(() => {
                  actions.setSubmitting(false)
                  dispatch(resetProductStockNotificationMessage())
                })
            }
          }
        }}
      >
        {({
          handleChange,
          handleBlur,
          errors,
          touched,
          handleSubmit,
          values,
          setFieldValue
        }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          >
            <div className="">
              <div className="mb-3">
                <label className="form-label" htmlFor="productStockNotificationQuantity">
                  Notify me when remaining quantity is below
                </label>
                <input
                  type="number"
                  id="productStockNotificationQuantity"
                  name="quantity"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${
                    errors.quantity && touched.quantity ? 'is-invalid' : ''
                  }`}
                  value={values.quantity}
                  placeholder="Enter quantity value"
                />
                {errors.quantity && touched.quantity && (
                  <div className="invalid-feedback d-block">{errors.quantity}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="productStockNotificationRecipients">
                  Recipient Emails
                </label>
                <CreatableSelect
                  inputId="productStockNotificationRecipients"
                  name="recipients"
                  className={`${
                    touched.recipients && errors.recipients ? 'is-invalid' : ''
                  }`}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor:
                        errors.recipients && touched.recipients
                          ? '#dc3545'
                          : provided.borderColor,
                      '&:hover': {
                        borderColor:
                          errors.recipients && touched.recipients
                            ? '#dc3545'
                            : provided.borderColor
                      }
                    })
                  }}
                  components={components}
                  inputValue={recipientInput}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={(newValue) => {
                    setFieldValue(
                      'recipients',
                      newValue.map((option) => option)
                    )
                  }}
                  onInputChange={(newValue) => setRecipientInput(newValue)}
                  onKeyDown={(event) => {
                    if (!recipientInput) return
                    switch (event.key) {
                      case 'Enter':
                      case 'Tab':
                        setRecipientInput('')
                        setFieldValue('recipients', Array.from(new Set([...values.recipients, recipientInput])))
                        event.preventDefault()
                    }
                  }}
                  placeholder="Type an email and press enter..."
                  value={values.recipients}
                  getOptionLabel={(email) => email}
                  getOptionValue={(email) => email}
                />
                <div
                  id="validationProductStockNotificationRecipientsFeedback"
                  className="invalid-feedback"
                >
                  {errors.recipients}
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="productStockNotificationFrequency">
                  Frequency
                </label>
                <input
                  type="number"
                  id="productStockNotificationFrequency"
                  name="frequency"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${
                    errors.frequency && touched.frequency ? 'is-invalid' : ''
                  }`}
                  value={values.frequency}
                  placeholder="Enter frequency value"
                />
                {errors.frequency && touched.frequency && (
                  <div className="invalid-feedback d-block">{errors.frequency}</div>
                )}
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="productStockNotificationFrequencyUnit">
                  Frequency Unit
                </label>
                <select
                  id="productStockNotificationFrequencyUnit"
                  name="frequencyUnit"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-select ${
                    errors.frequencyUnit && touched.frequencyUnit ? 'is-invalid' : ''
                  }`}
                  value={values.frequencyUnit}
                >
                  <option value="">Select Frequency Unit</option>
                  <option value="day">Day</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                </select>
                {errors.frequencyUnit && touched.frequencyUnit && (
                  <div className="invalid-feedback d-block">{errors.frequencyUnit}</div>
                )}
              </div>
            </div>
            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-4"
                disabled={isLoading}
              >
                <i className="bi bi-save text-white"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default ProductStockNotificationEditor
