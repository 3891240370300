import React, { useEffect, useState, useCallback, ChangeEvent } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Tab } from 'bootstrap'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { ProductCustomisation } from '../../types'
import { createProductCustomisations, getProductCustomisations } from '../../store/reducers/api/productReducer'
import Progress from '../loaders/Progress'
import Pagination from '../Pagination'
import DropZone from '../Dropzone/DropZone'
import { dismissModal } from '../../utils/dismissModal'
import { setToast } from '../../store/reducers/toastReducer'
import SkeletonTableRow from '../loaders/skeleton/SkeletonTableRow'
import {
  getDownloadURLFirebase,
  getMetadataFirebase,
  refFirebase,
  storageFirebase,
  uploadBytesResumableFirebase
} from '../../config/firebaseConfig'

const firebaseStorageEnvironment = process.env.REACT_APP_FIREBASE_STORAGE_ENVIRONMENT || 'develop'

interface BrandedProductsViewerAndEditorProps {
  setSelectedProducts: React.Dispatch<React.SetStateAction<ProductCustomisation | null>>
  handleTab: (tab: string) => void
}

const BrandedProductsViewerAndEditor: React.FC<BrandedProductsViewerAndEditorProps> = ({ setSelectedProducts, handleTab }) => {
  const customisation = useAppSelector((state) => state.apiProduct.productCustomisation)
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isProductCustomisationLoading = useAppSelector((state) => state.apiProduct.isProductCustomisationLoading)
  const productCustomisationMetaData = useAppSelector((state) => state.apiProduct.productCustomisationMetaData)
  const token = currentUser?.token
  const { id } = useParams()
  const dispatch = useAppDispatch()

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(5)

  useEffect(() => {
    if (token && id) {
      const controller = new AbortController()
      const signal = controller.signal
      dispatch(getProductCustomisations({ token, signal, productId: id, perPage, page }))
      return () => controller.abort()
    }
  }, [token, id, page, perPage, dispatch])

  const handleRefresh = useCallback(() => {
    if (token && id) {
      const controller = new AbortController()
      const signal = controller.signal
      dispatch(getProductCustomisations({ token, signal, productId: id, perPage, page }))
      return () => controller.abort()
    }
  }, [token, id, dispatch, perPage, page])

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  }
  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const validationSchema = Yup.object().shape({
    available: Yup.string()
      .oneOf(['true', 'false'], 'Please select availability')
      .required('Required'),
    customisationDetail: Yup.string().required('Customisation detail is required'),
    color: Yup.string().required('Color is required'),
    designStatus: Yup.string().required('Design status is required'),
    customisationType: Yup.string()
      .oneOf(['engraving', 'print'], 'Please select a valid Customisation type')
      .required('Required'),
    photos: Yup.mixed()
      .required('Please upload an image')
      .test('fileSize', 'Select an image that is less than 5MB', (value: File) => {
        return value && value.size <= 5 * 1024 * 1024
      })
      .test('fileType', 'File is not an image', (value: File) => {
        return value && value.type.startsWith('image/')
      })
  })

  const [uploadedImage, setUploadedImage] = useState<{
    url: string
    filename: string
  } | null>(null)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [uploadError, setUploadError] = useState<string | null>(null)
  const fileNameGenerator = (filename: string) => {
    const ext = filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2)
    return `${id}-${Date.now()}.${ext}`
  }

  const uploadCustomisationImage = async (file: File): Promise<{
    url: string
    filename: string
    size: number
    mimeType: string
  }> => {
    return new Promise((resolve, reject) => {
      const fileSizeBytes = 5 * 1024 * 1024

      if (file.size > fileSizeBytes) {
        reject(new Error('Select an image that is less than 5MB'))
        return
      }
      if (!file.type.startsWith('image/')) {
        reject(new Error('File is not an image'))
        return
      }

      const imageFileName = fileNameGenerator(file.name)
      const storageRefPath = `${firebaseStorageEnvironment}/companies/images/${imageFileName}`
      const storageRef = refFirebase(storageFirebase, storageRefPath)

      const uploadTask = uploadBytesResumableFirebase(storageRef, file)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          setUploadProgress(percentage)
        },
        (error) => {
          setUploadError(error.message)
          setUploadProgress(0)
          reject(error)
        },
        () => {
          getDownloadURLFirebase(uploadTask.snapshot.ref)
            .then((url) => {
              getMetadataFirebase(storageRef)
                .then((metadata) => {
                  const image = {
                    url,
                    filename: metadata.name,
                    size: metadata.size,
                    mimeType: metadata.contentType || file.type
                  }
                  setUploadProgress(0)
                  setUploadError(null)
                  resolve(image)
                })
                .catch(reject)
            })
            .catch(reject)
        }
      )
    })
  }

  return (
    <div>
      <div className="card mt-4">
        <div className="m-4">
          <div className="navbar navbar-expand mb-3 d-flex flex-column flex-sm-row align-items-start">
            <p className="h5">
              <i className="bi bi-bookmark-star me-1"></i> Branded Products
            </p>
            <ul className="navbar-nav ms-auto me-0 gap-2 gap-sm-3">
              <button
                type="button"
                className="btn btn-outline-primary btn-sm text-nowrap"
                data-bs-toggle="modal"
                title="Add Customisation"
                data-bs-target="#createProductCustomisationModal"
              >
                <i className="bi bi-plus-circle"></i>
                <span className="ms-1">
                  <span className="d-none d-md-inline-block me-1">Add</span>Customisation
                </span>
              </button>

              <button
                type="button"
                title="Refresh"
                aria-label="Refresh"
                className="btn btn-outline-dark"
                onClick={() => handleRefresh()}
              >
                <i className="fas fa-redo"></i>
              </button>
            </ul>
          </div>

          {isProductCustomisationLoading
            ? (
            <Progress />
              )
            : (
            <hr className="border border-primary border-1 opacity-50" />
              )}

          <div className="table-responsive">
            <table className="table table-hover table-centered align-middle table-nowrap">
              <thead>
                <tr>
                  <th scope="col" >
                    Customisation Detail
                  </th>
                  <th scope="col" className="text-center">
                    Color
                  </th>
                  <th scope="col" className="text-center">
                    Design Status
                  </th>
                  <th scope="col" className="text-center">
                    Customisation Type
                  </th>
                  <th scope="col" className="text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
              {isProductCustomisationLoading
                ? (
                    Array.from({ length: perPage }, (_, index) => (
                  <SkeletonTableRow key={index} colSpan={6} actionQuantity={2} />
                    ))
                  )
                : customisation.length > 0
                  ? (
                      customisation.map((custom) => (
                  <tr key={custom.id}>
                    <td>
                      <div className="p-1">{custom.customisationDetail}</div>
                    </td>
                    <td className="text-center">
                      <div className="p-1">{custom.color}</div>
                    </td>
                    <td className="text-center">
                      <div
                        className={`rounded p-1 ${
                          custom.designStatus === 'Approved'
                            ? 'bg-sent'
                            : custom.designStatus === 'In Progress'
                            ? 'bg-inprogress'
                            : 'bg-delivered'
                        }`}
                      >
                        {custom.designStatus}
                      </div>
                    </td>
                    <td className="text-center">
                      <div className="p-1">{custom.customisationType}</div>
                    </td>
                    <td className="text-center">
                      <p
                        className="text-primary h6 p-1"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedProducts(custom)
                          handleTab('Print Preview')
                          const triggerEl = document.querySelector('#brandedProductsTab button[data-bs-target="#print-preview"]')
                          if (triggerEl) {
                            const tab = new Tab(triggerEl)
                            tab.show()
                          }
                        }}
                      >
                        View Details
                      </p>
                    </td>
                  </tr>
                      ))
                    )
                  : (
                <tr>
                  <td colSpan={5} className="text-center">
                    No customisations available yet
                  </td>
                </tr>
                    )}

              </tbody>
            </table>
          </div>

          <Pagination
            isLoading={isProductCustomisationLoading}
            metadata={{
              limit: productCustomisationMetaData?.perPage,
              total: productCustomisationMetaData?.total,
              offset:
                productCustomisationMetaData?.page && productCustomisationMetaData?.perPage
                  ? (productCustomisationMetaData.page - 1) * productCustomisationMetaData.perPage
                  : 0
            }}
            page={page}
            perPage={perPage}
            handlePageChange={handlePageChange}
            handleShowEntries={handleShowEntries}
            module="productCustomisations"
            perPageOptions={[5, 10, 25]}
          />
        </div>
      </div>

      <div
        className="modal fade"
        id="createProductCustomisationModal"
        tabIndex={-1}
        aria-labelledby="createProductCustomisationModalLabel"
        aria-modal="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="createProductCustomisationModalLabel">
                <i className="bi bi-plus-circle me-1"></i> Add Product Customisation
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>

              <Formik
                initialValues={{
                  available: 'true',
                  customisationDetail: '',
                  color: '',
                  designStatus: 'In Progress',
                  customisationType: 'engraving',
                  photos: null
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  if (token && id) {
                    const productCustomisation: ProductCustomisation = {
                      price: 0,
                      isApproved: values.designStatus === 'Approved',
                      photos: uploadedImage ? [uploadedImage] : [],
                      available: values.available === 'true',
                      customisationDetail: values.customisationDetail,
                      color: values.color,
                      designStatus: values.designStatus,
                      customisationType: values.customisationType
                    }

                    try {
                      const controller = new AbortController()
                      await dispatch(
                        createProductCustomisations({
                          token,
                          productId: id,
                          productCustomisation,
                          signal: controller.signal
                        })
                      ).unwrap()
                      resetForm()
                      setUploadedImage(null)
                      setUploadProgress(0)
                      dismissModal('createProductCustomisationModal')
                      const payload = {
                        title: 'Success',
                        message: 'Product Customisation created successfully!',
                        isVisible: true,
                        timestamp: dayjs().format('LT'),
                        type: 'success'
                      }
                      dispatch(setToast(payload))
                      handleRefresh()
                    } catch (err) {
                      const payload = {
                        title: 'Error',
                        message: 'Failed to create product Customisation. Please try again.',
                        isVisible: true,
                        timestamp: dayjs().format('LT'),
                        type: 'danger'
                      }
                      dispatch(setToast(payload))
                    } finally {
                      setSubmitting(false)
                    }
                  }
                }}
              >
              {({ isSubmitting, errors, touched, setFieldValue, handleSubmit, values, handleChange }) => (
                <form onSubmit={handleSubmit}>
                  <div className="modal-body">
                    <div className="mb-3">
                      <label htmlFor="customisationDetail" className="form-label">
                        Customisation Detail
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.customisationDetail && touched.customisationDetail ? 'is-invalid' : ''}`}
                        id="customisationDetail"
                        name="customisationDetail"
                        value={values.customisationDetail}
                        onChange={handleChange}
                      />
                      {errors.customisationDetail && touched.customisationDetail && (
                        <div className="invalid-feedback">{errors.customisationDetail}</div>
                      )}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="photos" className="form-label">
                        Image
                      </label>
                      <div className={`${errors.photos && touched.photos ? 'is-invalid' : ''}`}>
                        <DropZone
                          handleUpload={async (file: File) => {
                            try {
                              const image = await uploadCustomisationImage(file)
                              setUploadedImage({
                                url: image.url,
                                filename: image.filename
                              })
                              setUploadProgress(100)
                              setUploadError(null)
                              setFieldValue('photos', file)
                            } catch (error: any) {
                              setUploadError(error.message)
                              setFieldValue('photos', null)
                            }
                          }}
                          uploadProgress={uploadProgress}
                          uploadError={uploadError}
                          id="CustomisationImage"
                          hasError={!!(errors.photos && touched.photos)}
                        />
                      </div>
                      {errors.photos && touched.photos && (
                        <div className="invalid-feedback">{errors.photos}</div>
                      )}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="color" className="form-label">
                        Color
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.color && touched.color ? 'is-invalid' : ''}`}
                        id="color"
                        name="color"
                        value={values.color}
                        onChange={handleChange}
                      />
                      {errors.color && touched.color && (
                        <div className="invalid-feedback">{errors.color}</div>
                      )}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="designStatus" className="form-label">
                        Design Status
                      </label>
                      <select
                        className={`form-select ${errors.designStatus && touched.designStatus ? 'is-invalid' : ''}`}
                        id="designStatus"
                        name="designStatus"
                        value={values.designStatus}
                        disabled
                        onChange={handleChange}
                      >
                        <option value="In Progress">In Progress</option>
                        <option value="Approved">Approved</option>
                        <option value="Disapproved">Disapproved</option>
                      </select>
                      {errors.designStatus && touched.designStatus && (
                        <div className="invalid-feedback">{errors.designStatus}</div>
                      )}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="customisationType" className="form-label">
                        Customisation Type
                      </label>
                      <select
                        className={`form-select ${errors.customisationType && touched.customisationType ? 'is-invalid' : ''}`}
                        id="customisationType"
                        name="customisationType"
                        value={values.customisationType}
                        onChange={handleChange}
                      >
                        <option value="engraving">Engraving</option>
                        <option value="print">Print</option>
                      </select>
                      {errors.customisationType && touched.customisationType && (
                        <div className="invalid-feedback">{errors.customisationType}</div>
                      )}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                      Close
                    </button>
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandedProductsViewerAndEditor
