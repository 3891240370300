import React from 'react'
import { object, string, boolean } from 'yup'
import { Formik } from 'formik'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Select from 'react-select'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { ProductCategory } from '../../types'
import DropZone from '../Dropzone/DropZone'
import * as userRoles from '../../constants/userRoles'

dayjs.extend(utc)
interface SetImageProps {
  url: string;
  filename: string;
  size: number;
  mimeType: string;
}
type ProductCategoryEditorProps = {
  id: string,
  initialProductCategory: Partial<ProductCategory>,
  save: Function,
  isEdit: boolean,
  openCropModal: (file: File) => void
  uploadError: string | null,
  uploadProgress: number,
  image: SetImageProps | null,
  isImageUploading: boolean
}

const ProductCategoryEditor = ({ id, initialProductCategory, save, openCropModal, uploadError, uploadProgress, image, isEdit, isImageUploading }: ProductCategoryEditorProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiCompany.isLoadingProductCategories)
  const isLoadingCategoryEdit = useAppSelector((state) => state.apiProductCategory.isLoading)
  const profile = useAppSelector((state) => state.profile.profile)
  const companies = useAppSelector((state) => state.apiCompany.companies)

  const dispatch = useAppDispatch()

  const token = currentUser?.token
  const role = profile?.role || userRoles.USER

  const productCategorySchema = object({
    name: string().label('Name').required().max(64),
    description: string().label('Description').max(255).nullable(),
    isHidden: boolean().label('Is Hidden').default(false)
  })

  const saveProductCategory = (id: string, productCategory: Partial<ProductCategory>, signal: AbortSignal) => {
    dispatch(save({ companyId: id, productCategoryId: id, token, productCategory, signal }))
  }

  return (
    <div>
      <Formik
        validationSchema={productCategorySchema}
        enableReinitialize
        initialValues={{
          ...initialProductCategory,
          companyId: initialProductCategory.company?.id
        }}
        onSubmit={(
          { name, description, isHidden, companyId },
          actions
        ) => {
          const controller = new AbortController()
          const signal = controller.signal

          const productCategory: any = {
            name,
            description,
            isHidden
          }
          if (role === userRoles.ADMIN) {
            productCategory.companyId = companyId || null
          }
          if (image?.url && image?.filename) {
            productCategory.picture = { url: image.url, filename: image.filename }
          }
          if (token) {
            saveProductCategory(id, productCategory, signal)
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
          setFieldValue,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            {role === userRoles.ADMIN && (
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label
                      htmlFor="companyProductCategory"
                      className="form-label"
                    >
                      Company
                    </label>
                    <Select
                      className={`${
                        touched.companyId &&
                        errors.companyId
                          ? 'is-invalid'
                          : ''
                      }`}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: (errors.companyId && touched.companyId) ? '#dc3545' : provided.borderColor,
                          '&:hover': {
                            borderColor: (errors.companyId && touched.companyId) ? '#dc3545' : provided.borderColor
                          }
                        })
                      }}
                      isClearable
                      inputId="companyProductCategory"
                      name="companyId"
                      aria-label="Company"
                      options={companies}
                      getOptionLabel={(company) => `${company.name} - ${company.domain ?? 'Domain not set'}`}
                      getOptionValue={(company) => String(company.id)}
                      onChange={(selectedOption) => {
                        const selectedCompanyId = selectedOption?.id ?? ''
                        setFieldValue('companyId', selectedCompanyId)
                      }}
                      onBlur={handleBlur}
                      isLoading={isLoading}
                      value={companies.find((company) => company.id === values.companyId)}
                    />
                    <div
                      id="validationAccessPermissionCompanyFeedback"
                      className="invalid-feedback"
                    >
                      {errors.companyId}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="productCategoryName"
                    className="form-label"
                  >
                    Name
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    type="text"
                    className={`form-control ${
                      (touched.name &&
                      errors.name)
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="productCategoryName"
                    name="name"
                    placeholder=""
                    maxLength={64}
                    autoComplete="off"
                  />
                  <div
                    id="validationProductCategoryNameFeedback"
                    className="invalid-feedback"
                  >
                    {errors.name}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="productCategoryImage"
                    className="form-label"
                  >
                    Image
                  </label>
                  <DropZone
                    handleUpload={openCropModal}
                    uploadProgress={uploadProgress}
                    uploadError={uploadError}
                    id="productCategoryImage"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="productCategoryDescription"
                    className="form-label"
                  >
                    Description
                  </label>
                  <textarea
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description ?? ''}
                    className={`form-control ${
                      (touched.description &&
                      errors.description)
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="productCategoryDescription"
                    name="description"
                    placeholder=""
                    maxLength={255}
                    autoComplete="off"
                  />
                  <div
                    id="validationProductCategoryDescriptionFeedback"
                    className="invalid-feedback"
                  >
                    {errors.description}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <p className="mt-2 mb-1">Is this a hidden category?</p>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="isHidden"
                      id="inlineCategoryHiddenRadioYes"
                      onChange={() => {
                        setFieldValue('isHidden', true)
                      }}
                      value="true"
                      autoComplete="off"
                      checked={values.isHidden}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineCategoryHiddenRadioYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="isHidden"
                      id="inlineCategoryHiddenRadioNo"
                      onChange={() => {
                        setFieldValue('isHidden', false)
                      }}
                      value="false"
                      autoComplete="off"
                      checked={!values.isHidden}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineCategoryHiddenRadioNo"
                    >
                      No
                    </label>
                  </div>

                  <div
                    id="validationCategoryHidden"
                    className="invalid-feedback"
                  >
                    {errors.isHidden}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={
                  isSubmitting || isLoading || isImageUploading || isLoadingCategoryEdit
                }
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default ProductCategoryEditor
