import dayjs from 'dayjs'
import { Link, NavLink } from 'react-router-dom'
import React, { MouseEventHandler } from 'react'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import LogoWhite from '../../assets/images/logo-white.png'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { logoutCurrentUser } from '../../store/reducers/api/authReducer'
import * as userRoles from '../../constants/userRoles'

dayjs.extend(localizedFormat)

const Navbar = () => {
  const profile = useAppSelector((state) => state.profile.profile)

  const role = profile?.role || userRoles.USER

  const dispatch = useAppDispatch()

  const logOut = () => {
    dispatch(logoutCurrentUser())
  }

  const toggleNavBar: MouseEventHandler<HTMLButtonElement> = () => {
    // Toggle the side navigation
    const sidebarToggle = document.body.querySelector('#sidebarToggle')
    if (sidebarToggle) {
      document.body.classList.toggle('sb-sidenav-toggled')
    }
  }
  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark">
      {/* Navbar Brand */}
      <Link className="navbar-brand text-center" to="/">
        {profile?.company && profile.company.logo?.url ? <img className="img-navbar-logo" data-html2canvas-ignore src={profile.company.logo.url} /> : <img className="img-navbar-logo" data-html2canvas-ignore src={LogoWhite} />}
      </Link>
      {/* <!-- Sidebar Toggle--> */}
      <button
        className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
        id="sidebarToggle"
        onClick={toggleNavBar}
      >
        <i className="fas fa-bars"></i>
      </button>
      {/* <!-- Navbar--> */}
      <ul className="navbar-nav ms-auto me-0 me-md-3 my-2 my-md-0">
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle nav-custom-dropdown"
            id="navbarDropdown"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div className="avatar">
              <div className="avatar-letters">
                {`${profile?.firstName.charAt(0) || ''}${profile?.lastName.charAt(0) || ''}`}
              </div>
            </div>
          </a>
          <ul
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby="navbarDropdown"
          >
            <li>
              <NavLink className="dropdown-item py-2" to="/profile">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person me-1" viewBox="0 0 16 16">
                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                </svg>
                <span className='align-middle'>Profile</span>
              </NavLink>
            </li>
            {
              role === userRoles.ADMIN && (
                <>
                  <li>
                    <NavLink className="dropdown-item py-2" to="/maintenance-modes">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-wrench-adjustable-circle me-1" viewBox="0 0 16 16">
                        <path d="M12.496 8a4.5 4.5 0 0 1-1.703 3.526L9.497 8.5l2.959-1.11q.04.3.04.61"/>
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-1 0a7 7 0 1 0-13.202 3.249l1.988-1.657a4.5 4.5 0 0 1 7.537-4.623L7.497 6.5l1 2.5 1.333 3.11c-.56.251-1.18.39-1.833.39a4.5 4.5 0 0 1-1.592-.29L4.747 14.2A7 7 0 0 0 15 8m-8.295.139a.25.25 0 0 0-.288-.376l-1.5.5.159.474.808-.27-.595.894a.25.25 0 0 0 .287.376l.808-.27-.595.894a.25.25 0 0 0 .287.376l1.5-.5-.159-.474-.808.27.596-.894a.25.25 0 0 0-.288-.376l-.808.27z"/>
                      </svg>
                      <span className='align-middle'>Maintenance</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item py-2 d-flex align-items-center" to="/privacy-rules">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-shield-check me-1" viewBox="0 0 16 16">
                      <path d="M5.338 1.59a61 61 0 0 0-2.837.856.48.48 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.7 10.7 0 0 0 2.287 2.233c.346.244.652.42.893.533q.18.085.293.118a1 1 0 0 0 .101.025 1 1 0 0 0 .1-.025q.114-.034.294-.118c.24-.113.547-.29.893-.533a10.7 10.7 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.8 11.8 0 0 1-2.517 2.453 7 7 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7 7 0 0 1-1.048-.625 11.8 11.8 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 63 63 0 0 1 5.072.56"/>
                      <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
                    </svg>
                    <span className='align-middle'>Privacy</span>
                    </NavLink>
                  </li>
                </>
              )
            }
            <li>
              <span className="dropdown-item btn mt-1 py-2" onClick={logOut}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-power me-1" viewBox="0 0 16 16">
                  <path d="M7.5 1v7h1V1h-1z"/>
                  <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z"/>
                </svg>
                <span className='align-middle' onClick={logOut}>Log Out</span>
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar
