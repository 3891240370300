import React, { useState, Dispatch, SetStateAction } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import ShoppingCartArticles from './ShoppingCartArticles'
import {
  ShoppingCartAddress,
  ShoppingCartBundle,
  ShoppingCartProduct
} from '../../types'
import { TrashIcon } from '../icons/TrashIcon'
import {
  decrementProductQuantity,
  incrementProductQuantity,
  setAssignedProducts,
  setShoppingAddresses,
  setShoppingCart,
  setShoppingCartBundles,
  setShoppingCartLeftOverProducts
} from '../../store/reducers/shoppingCartReducer'
import Calendar from 'react-datepicker'
import { DaysOfWeek } from '../../enums/daysOfTheWeek'
import { isWeekday } from '../../utils/isWeekday'
import { getDateOfDispatchMinimumDate } from '../../utils/getDateOfDispatchMinimumDate'
interface ShoppingCartManagerProps {
  assignedItemIds: string[]
  handleDragStart: (
    e: React.DragEvent<HTMLDivElement>,
    item: ShoppingCartProduct | ShoppingCartBundle
  ) => void
  isOneAddress: boolean
  selectedShoppingCartAddress: ShoppingCartAddress
  setSelectedShoppingCartAddress: Dispatch<SetStateAction<ShoppingCartAddress>>
}
const ShoppingCartManager: React.FC<ShoppingCartManagerProps> = ({
  assignedItemIds,
  handleDragStart,
  isOneAddress,
  setSelectedShoppingCartAddress,
  selectedShoppingCartAddress
}) => {
  const shoppingCartAddresses = useAppSelector(
    (state) => state.shoppingCart.addresses
  )
  const assignedProducts = useAppSelector(
    (state) => state.shoppingCart.assignedProducts
  )
  const shoppingCartProducts = useAppSelector(
    (state) => state.shoppingCart.products
  )
  const shoppingCartLeftOverProducts = useAppSelector(
    (state) => state.shoppingCart.leftOverProducts
  )
  const shoppingCartBundles = useAppSelector(
    (state) => state.shoppingCart.bundles
  )
  const [draggedProductId, setDraggedProductId] = useState('')
  const dispatch = useAppDispatch()
  const [showModal, setShowModal] = useState(false)
  const [modalAddress, setModalAddress] = useState<ShoppingCartAddress | null>(
    null
  )
  const [shippingDate, setShippingDate] = useState<Date | null>(null)
  const isBulkOrder = useAppSelector((state) => state.shoppingCart.isBulkOrder)

  const [updatedAddressesInCartStore, setUpdatedAddressesInCartStore] =
    useState<ShoppingCartAddress[] | undefined>(undefined)
  const [isDateFilled, setIsDateFilled] = useState<boolean>(true)

  const getBundleDetails = (productId: string) => {
    const productDetail = shoppingCartBundles.find((product) =>
      product.splitId
        ? product.splitId === productId
        : product.id === productId
    )
    return productDetail
  }
  const getProductDetails = (productId: string) => {
    let productDetail = shoppingCartLeftOverProducts.find((product) =>
      product.splitId
        ? product.splitId === productId
        : product.id === productId
    )
    if (!productDetail) {
      productDetail = shoppingCartProducts.find((product) =>
        product.splitId
          ? product.splitId === productId
          : product.id === productId
      )
    }
    return productDetail
  }
  const handleDrop = (e: {
    preventDefault: () => void
    dataTransfer: { getData: (arg0: string) => any }
  }) => {
    e.preventDefault()
    const productId = e.dataTransfer.getData('productId')
    setDraggedProductId(productId)
    const isBundle = shoppingCartBundles.some((bundle) =>
      bundle.splitId ? bundle.splitId === productId : bundle.id === productId
    )
    if (isBulkOrder) {
      const foundShoppingAddressIndex = shoppingCartAddresses.findIndex(
        (cartAddress) => cartAddress.id === selectedShoppingCartAddress.id
      )
      if (foundShoppingAddressIndex !== -1) {
        const updatedAddressesInCart = [...shoppingCartAddresses]
        const updatedSelectedAssignmentAddress =
          updatedAddressesInCart[foundShoppingAddressIndex]
        const assignedProductIds =
          updatedSelectedAssignmentAddress.assignedBulkProductsIds

        if (!assignedProductIds.includes(productId)) {
          const updatedAssignedProductIds = [...assignedProductIds, productId]
          updatedAddressesInCart[foundShoppingAddressIndex] = {
            ...updatedSelectedAssignmentAddress,
            assignedBulkProductsIds: updatedAssignedProductIds
          }
          setUpdatedAddressesInCartStore(updatedAddressesInCart)
          if (!updatedSelectedAssignmentAddress.shippingDate) {
            setModalAddress(updatedSelectedAssignmentAddress)
            setShowModal(true)
          } else {
            const updatedAssignedProducts = {
              ...assignedProducts,
              [selectedShoppingCartAddress.id]: [
                ...(assignedProducts[selectedShoppingCartAddress.id] || []),
                {
                  id: productId,
                  quantity: getProductDetails(productId)?.leftOverQuantity || 0,
                  isBundle: false
                }
              ]
            }
            dispatch(
              setAssignedProducts({ assignedProducts: updatedAssignedProducts })
            )
            const updatedShoppingCartProducts = shoppingCartProducts.map(
              (product) =>
                product.id === productId
                  ? { ...product, leftOverQuantity: 0 }
                  : product
            )
            dispatch(setShoppingCart({ products: updatedShoppingCartProducts }))
            const payload = {
              addresses: updatedAddressesInCart
            }
            dispatch(setShoppingAddresses(payload))
            setSelectedShoppingCartAddress(
              updatedAddressesInCart[foundShoppingAddressIndex]
            )
          }
        } else {
          const updatedAssignedProductIds = [...assignedProductIds]
          updatedAddressesInCart[foundShoppingAddressIndex] = {
            ...updatedSelectedAssignmentAddress,
            assignedBulkProductsIds: updatedAssignedProductIds
          }
          setUpdatedAddressesInCartStore(updatedAddressesInCart)
          if (!updatedSelectedAssignmentAddress.shippingDate) {
            setModalAddress(updatedSelectedAssignmentAddress)
            setShowModal(true)
          } else {
            const existingProduct = assignedProducts[
              selectedShoppingCartAddress.id
            ]?.find((product) => product.id === productId)
            const updatedAssignedProducts = {
              ...assignedProducts,
              [selectedShoppingCartAddress.id]: existingProduct
                ? assignedProducts[selectedShoppingCartAddress.id].map(
                  (product) =>
                    product.id === productId
                      ? {
                          ...product,
                          quantity:
                            product.quantity +
                            (getProductDetails(productId)?.leftOverQuantity ??
                              0)
                        }
                      : product
                )
                : [
                    ...(assignedProducts[selectedShoppingCartAddress.id] || []),
                    {
                      id: productId,
                      quantity:
                        getProductDetails(productId)?.leftOverQuantity ?? 0,
                      isBundle: false
                    }
                  ]
            }
            dispatch(
              setAssignedProducts({ assignedProducts: updatedAssignedProducts })
            )
            const updatedShoppingCartProducts = shoppingCartProducts.map(
              (product) =>
                product.id === productId
                  ? { ...product, leftOverQuantity: 0 }
                  : product
            )
            dispatch(setShoppingCart({ products: updatedShoppingCartProducts }))
            const payload = {
              addresses: updatedAddressesInCart
            }
            dispatch(setShoppingAddresses(payload))
            setSelectedShoppingCartAddress(
              updatedAddressesInCart[foundShoppingAddressIndex]
            )
          }
        }
      }
    } else {
      if (selectedShoppingCartAddress && productId && !isBundle) {
        const foundShoppingAddressIndex = shoppingCartAddresses.findIndex(
          (cartAddress) => cartAddress.id === selectedShoppingCartAddress.id
        )
        if (foundShoppingAddressIndex !== -1) {
          const updatedAddressesInCart = [...shoppingCartAddresses]
          const updatedSelectedAssignmentAddress =
            updatedAddressesInCart[foundShoppingAddressIndex]
          const assignedProductIds =
            updatedSelectedAssignmentAddress.assignedLeftOverProductIds

          if (!assignedProductIds.includes(productId)) {
            const updatedAssignedProductIds = [...assignedProductIds, productId]
            updatedAddressesInCart[foundShoppingAddressIndex] = {
              ...updatedSelectedAssignmentAddress,
              assignedLeftOverProductIds: updatedAssignedProductIds
            }
            setUpdatedAddressesInCartStore(updatedAddressesInCart)
            if (!updatedSelectedAssignmentAddress.shippingDate) {
              setModalAddress(updatedSelectedAssignmentAddress)
              setShowModal(true)
            } else {
              const updatedAssignedProducts = {
                ...assignedProducts,
                [selectedShoppingCartAddress.id]: [
                  ...(assignedProducts[selectedShoppingCartAddress.id] || []),
                  {
                    id: productId,
                    quantity:
                      getProductDetails(productId)?.leftOverQuantity || 0,
                    isBundle: false
                  }
                ]
              }
              const updatedShoppingCartProducts = shoppingCartProducts.map(
                (product) =>
                  product.id === productId
                    ? { ...product, leftOverQuantity: 0 }
                    : product
              )
              const updatedShoppingCartLeftOverProducts =
                shoppingCartLeftOverProducts.map((product) =>
                  product.id === productId
                    ? { ...product, quantity: 0 }
                    : product
                )
              dispatch(
                setAssignedProducts({
                  assignedProducts: updatedAssignedProducts
                })
              )
              if (
                updatedShoppingCartLeftOverProducts.some(
                  (product) => product.id === productId
                )
              ) {
                dispatch(
                  setShoppingCartLeftOverProducts({
                    leftOverProducts: updatedShoppingCartLeftOverProducts
                  })
                )
              } else {
                dispatch(
                  setShoppingCart({ products: updatedShoppingCartProducts })
                )
              }
              const payload = {
                addresses: updatedAddressesInCart
              }
              dispatch(setShoppingAddresses(payload))
              setSelectedShoppingCartAddress(
                updatedAddressesInCart[foundShoppingAddressIndex]
              )
            }
          } else {
            const updatedAssignedProductIds = [...assignedProductIds]
            updatedAddressesInCart[foundShoppingAddressIndex] = {
              ...updatedSelectedAssignmentAddress,
              assignedLeftOverProductIds: updatedAssignedProductIds
            }
            setUpdatedAddressesInCartStore(updatedAddressesInCart)
            if (!updatedSelectedAssignmentAddress.shippingDate) {
              setModalAddress(updatedSelectedAssignmentAddress)
              setShowModal(true)
            } else {
              const existingProduct = assignedProducts[
                selectedShoppingCartAddress.id
              ]?.find((product) => product.id === productId)
              const updatedAssignedProducts = {
                ...assignedProducts,
                [selectedShoppingCartAddress.id]: existingProduct
                  ? assignedProducts[selectedShoppingCartAddress.id].map(
                    (product) =>
                      product.id === productId
                        ? {
                            ...product,
                            quantity:
                              product.quantity +
                              (getProductDetails(productId)
                                ?.leftOverQuantity ?? 0)
                          }
                        : product
                  )
                  : [
                      ...(assignedProducts[selectedShoppingCartAddress.id] ||
                        []),
                      {
                        id: productId,
                        quantity:
                          getProductDetails(productId)?.leftOverQuantity ?? 0,
                        isBundle: false
                      }
                    ]
              }
              dispatch(
                setAssignedProducts({
                  assignedProducts: updatedAssignedProducts
                })
              )
              const updatedShoppingCartProducts = shoppingCartProducts.map(
                (product) =>
                  product.id === productId
                    ? { ...product, leftOverQuantity: 0 }
                    : product
              )
              const updatedShoppingCartLeftOverProducts =
                shoppingCartLeftOverProducts.map((product) =>
                  product.id === productId
                    ? { ...product, quantity: 0 }
                    : product
                )
              if (
                updatedShoppingCartLeftOverProducts.some(
                  (product) => product.id === productId
                )
              ) {
                dispatch(
                  setShoppingCartLeftOverProducts({
                    leftOverProducts: updatedShoppingCartLeftOverProducts
                  })
                )
              } else {
                dispatch(
                  setShoppingCart({ products: updatedShoppingCartProducts })
                )
              }
              const payload = {
                addresses: updatedAddressesInCart
              }
              dispatch(setShoppingAddresses(payload))
              setSelectedShoppingCartAddress(
                updatedAddressesInCart[foundShoppingAddressIndex]
              )
            }
          }
        }
      } else if (selectedShoppingCartAddress && productId && isBundle) {
        const foundShoppingAddressIndex = shoppingCartAddresses.findIndex(
          (cartAddress) => cartAddress.id === selectedShoppingCartAddress.id
        )
        if (foundShoppingAddressIndex !== -1) {
          const updatedAddressesInCart = [...shoppingCartAddresses]
          const updatedSelectedAssignmentAddress =
            updatedAddressesInCart[foundShoppingAddressIndex]
          const assignedBundleIds =
            updatedSelectedAssignmentAddress.assignedBundleIds
          const bundle = shoppingCartBundles.find((bundle) =>
            bundle.splitId
              ? bundle.splitId === productId
              : bundle.id === productId
          )

          if (bundle && !assignedBundleIds.includes(productId)) {
            const updatedassignedBundleIds = [
              ...assignedBundleIds,
              ...(bundle.splitId ? [bundle.splitId] : [bundle.id])
            ]
            updatedAddressesInCart[foundShoppingAddressIndex] = {
              ...updatedSelectedAssignmentAddress,
              assignedBundleIds: updatedassignedBundleIds
            }
            setUpdatedAddressesInCartStore(updatedAddressesInCart)
            if (!updatedSelectedAssignmentAddress.shippingDate) {
              setModalAddress(updatedSelectedAssignmentAddress)
              setShowModal(true)
            } else {
              const payload = {
                addresses: updatedAddressesInCart
              }
              const updatedAssignedProducts = {
                ...assignedProducts,
                [selectedShoppingCartAddress.id]: [
                  ...(assignedProducts[selectedShoppingCartAddress.id] || []),
                  {
                    id: productId,
                    quantity: getBundleDetails(productId)?.quantity || 0,
                    isBundle: true
                  }
                ]
              }
              const updatedShoppingCartBundles = shoppingCartBundles.map(
                (bundle) =>
                  bundle.id === productId ? { ...bundle, quantity: 0 } : bundle
              )
              dispatch(
                setShoppingCartBundles({ bundles: updatedShoppingCartBundles })
              )
              dispatch(
                setAssignedProducts({
                  assignedProducts: updatedAssignedProducts
                })
              )
              dispatch(setShoppingAddresses(payload))
              setSelectedShoppingCartAddress(
                updatedAddressesInCart[foundShoppingAddressIndex]
              )
            }
          } else if (bundle) {
            const updatedassignedBundleIds = [...assignedBundleIds]
            updatedAddressesInCart[foundShoppingAddressIndex] = {
              ...updatedSelectedAssignmentAddress,
              assignedBundleIds: updatedassignedBundleIds
            }
            setUpdatedAddressesInCartStore(updatedAddressesInCart)
            if (!updatedSelectedAssignmentAddress.shippingDate) {
              setModalAddress(updatedSelectedAssignmentAddress)
              setShowModal(true)
            } else {
              const existingBundle = assignedProducts[
                selectedShoppingCartAddress.id
              ]?.find((product) => product.id === productId)
              const updatedAssignedProducts = {
                ...assignedProducts,
                [selectedShoppingCartAddress.id]: existingBundle
                  ? assignedProducts[selectedShoppingCartAddress.id].map(
                    (product) =>
                      product.id === productId
                        ? {
                            ...product,
                            quantity:
                              product.quantity +
                              (getBundleDetails(productId)?.quantity ?? 0)
                          }
                        : product
                  )
                  : [
                      ...(assignedProducts[selectedShoppingCartAddress.id] ||
                        []),
                      {
                        id: productId,
                        quantity: getBundleDetails(productId)?.quantity ?? 0,
                        isBundle: true
                      }
                    ]
              }
              dispatch(
                setAssignedProducts({
                  assignedProducts: updatedAssignedProducts
                })
              )
              const updatedShoppingCartBundles = shoppingCartBundles.map(
                (bundle) =>
                  bundle.id === productId ? { ...bundle, quantity: 0 } : bundle
              )
              dispatch(
                setShoppingCartBundles({ bundles: updatedShoppingCartBundles })
              )
              const payload = {
                addresses: updatedAddressesInCart
              }
              dispatch(setShoppingAddresses(payload))
              setSelectedShoppingCartAddress(
                updatedAddressesInCart[foundShoppingAddressIndex]
              )
            }
          }
        }
      }
    }
  }

  const handleSaveShippingDate = () => {
    if (modalAddress && shippingDate && updatedAddressesInCartStore) {
      const updatedAddresses = updatedAddressesInCartStore.map((address) =>
        address.id === modalAddress.id
          ? { ...address, shippingDate: shippingDate.toISOString() }
          : address
      )
      dispatch(setShoppingAddresses({ addresses: updatedAddresses }))
      setSelectedShoppingCartAddress(
        updatedAddresses.find((address) => address.id === modalAddress.id)!
      )
      setShowModal(false)
      setShippingDate(null)

      if (draggedProductId) {
        const isBundle = shoppingCartBundles.some((bundle) =>
          bundle.splitId
            ? bundle.splitId === draggedProductId
            : bundle.id === draggedProductId
        )
        const updatedAssignedProducts = {
          ...assignedProducts,
          [modalAddress.id]: [
            ...(assignedProducts[modalAddress.id] || []),
            {
              id: draggedProductId,
              quantity: isBundle
                ? getBundleDetails(draggedProductId)?.quantity || 0
                : getProductDetails(draggedProductId)?.leftOverQuantity || 0,
              isBundle
            }
          ]
        }
        dispatch(
          setAssignedProducts({ assignedProducts: updatedAssignedProducts })
        )
        if (isBundle) {
          const updatedShoppingCartBundles = shoppingCartBundles.map((bundle) =>
            bundle.id === draggedProductId ? { ...bundle, quantity: 0 } : bundle
          )
          dispatch(
            setShoppingCartBundles({ bundles: updatedShoppingCartBundles })
          )
        } else {
          const updatedShoppingCartProducts = shoppingCartProducts.map(
            (product) =>
              product.id === draggedProductId
                ? { ...product, leftOverQuantity: 0 }
                : product
          )
          const updatedShoppingCartLeftOverProducts =
            shoppingCartLeftOverProducts.map((product) =>
              product.id === draggedProductId
                ? { ...product, quantity: 0 }
                : product
            )
          if (
            updatedShoppingCartLeftOverProducts.some(
              (product) => product.id === draggedProductId
            )
          ) {
            dispatch(
              setShoppingCartLeftOverProducts({
                leftOverProducts: updatedShoppingCartLeftOverProducts
              })
            )
          } else {
            dispatch(setShoppingCart({ products: updatedShoppingCartProducts }))
          }
        }
      }
    }
  }

  const handleDragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault()
  }

  return (
    <div className="overflow-auto">
      <div className="d-flex justify-content-between my-3 w-100">
        <div
          className="col-md-5 my-2 mx-2 d-flex flex-column justify-content-between"
        >
          <div className="flex-grow-1"></div>
          <ShoppingCartArticles
            onDragStart={handleDragStart}
            assignedItemIds={assignedItemIds}
            isOneAddress={isOneAddress}
          />
        </div>
        <i className="bi bi-arrow-right fs-1 my-auto text-muted p-3"></i>
        <div className="col-md-6">
          <div className="title col">
            <div className="nav nav-pills">
              <div
                style={{
                  width: '50rem',
                  whiteSpace: 'nowrap',
                  scrollbarWidth: 'thin',
                  msOverflowStyle: 'auto',
                  overflowY: 'hidden',
                  position: 'relative'
                }}
                className="w-100 d-flex hide-scrollbar"
              >
                {!isOneAddress &&
                  shoppingCartAddresses.length > 0 &&
                  shoppingCartAddresses.map((shoppingCartAddress, index) => (
                    <a
                      key={index}
                      role="button"
                      style={{ minWidth: '33.33%', overflow: 'clip' }}
                      className={`nav-link ${
                        shoppingCartAddress.id ===
                        selectedShoppingCartAddress.id
                          ? 'active text-white'
                          : ''
                      } border `}
                      onClick={() =>
                        setSelectedShoppingCartAddress(shoppingCartAddress)
                      }
                    >
                      <div className="w-100" style = {{ overflow: 'clip' }}>
                        <p
                          style={{ fontSize: '0.875rem' }}
                          className={`fw-normal mb-1 ${
                            shoppingCartAddress.id ===
                            selectedShoppingCartAddress.id
                              ? 'text-white'
                              : 'text-muted'
                          }`}
                        >
                          {shoppingCartAddress.street}
                        </p>
                        <h6
                          style={{ fontSize: '0.875rem' }}
                          className={` w-bold ${
                            shoppingCartAddress.id ===
                            selectedShoppingCartAddress.id
                              ? 'text-white'
                              : 'text-black'
                          }`}
                        >
                          <div
                            title={
                              (shoppingCartAddress.country?.length || 0) + (shoppingCartAddress.city?.length || 0) > 20
                                ? `${shoppingCartAddress.country}, ${shoppingCartAddress.city}`
                                : undefined
                            }
                          >
                            {`${shoppingCartAddress.country}, ${shoppingCartAddress.city}`}
                          </div>
                        </h6>
                      </div>
                    </a>
                  ))}
              </div>
            </div>
            <div
              className="table-responsive border rounded bg-light"
              style={{ height: '30rem', position: 'relative' }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <div
                className="d-flex flex-column align-items-center justify-content-center h-100 bg-light"
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  backgroundColor: 'white',
                  padding: '1rem',
                  overflow: 'auto'
                }}
              >
                <i
                  className="bi bi-inbox text-primary"
                  style={{ fontSize: '2rem' }}
                ></i>
                <p className="text-center">
                  Drag Products to this area to assign them a shipping
                </p>
              </div>
              <div
                style={{
                  zIndex: 10,
                  position: 'absolute',
                  top: 0,
                  width: '100%'
                }}
              >
                <table className="table align-middle mb-0 bg-dark">
                  <tbody>
                    <>
                      {shoppingCartAddresses.length > 0 &&
                        selectedShoppingCartAddress?.assignedBundleIds?.length >
                          0 &&
                        selectedShoppingCartAddress.assignedBundleIds.map(
                          (assignedBundleId) => (
                            <tr
                              key={`shopping-details-assigned-bundle-${assignedBundleId}`}
                            >
                              <td className="col-4">
                                {getBundleDetails(assignedBundleId)?.name}
                              </td>
                              <td className=" col-4">
                                <div className="form-group col-12">
                                  <input
                                    type="number"
                                    min={1}
                                    value={
                                      assignedProducts[
                                        selectedShoppingCartAddress.id
                                      ]?.find(
                                        (product) =>
                                          product.id === assignedBundleId
                                      )?.quantity || 0
                                    }
                                    onChange={(e) => {
                                      const newQuantity = parseInt(
                                        e.target.value,
                                        10
                                      )
                                      const currentQuantity =
                                        assignedProducts[
                                          selectedShoppingCartAddress.id
                                        ]?.find(
                                          (product) =>
                                            product.id === assignedBundleId
                                        )?.quantity || 0

                                      if (newQuantity > currentQuantity) {
                                        dispatch(
                                          incrementProductQuantity({
                                            productId: assignedBundleId,
                                            addressId:
                                              selectedShoppingCartAddress.id,
                                            isBundle: true
                                          })
                                        )
                                      } else if (
                                        newQuantity < currentQuantity
                                      ) {
                                        dispatch(
                                          decrementProductQuantity({
                                            productId: assignedBundleId,
                                            addressId:
                                              selectedShoppingCartAddress.id,
                                            isBundle: true
                                          })
                                        )
                                      }
                                    }}
                                    name="bundle-configurator-bundle-quantity"
                                    className="form-control"
                                    aria-label={'Bundle Quantity'}
                                    autoComplete="off"
                                    disabled={false}
                                  />
                                </div>
                              </td>
                              <td className="text-end col-4">
                                <div className="d-flex flex-row float-end">
                                  <button
                                    type="button"
                                    title="Remove Assigned Bundle"
                                    className="btn btn-outline-danger btn-round"
                                    onClick={() => {
                                      const foundShoppingAddressIndex =
                                        shoppingCartAddresses.findIndex(
                                          (cartAddress) =>
                                            cartAddress.id ===
                                            selectedShoppingCartAddress.id
                                        )
                                      if (foundShoppingAddressIndex !== -1) {
                                        const updatedAddressesInCart = [
                                          ...shoppingCartAddresses
                                        ]
                                        const updatedSelectedAssignmentAddress =
                                          updatedAddressesInCart[
                                            foundShoppingAddressIndex
                                          ]
                                        const assignedBundleIds =
                                          updatedSelectedAssignmentAddress.assignedBundleIds

                                        const updatedAssignedBundleIds =
                                          assignedBundleIds.filter(
                                            (bundleId) =>
                                              bundleId !== assignedBundleId
                                          )
                                        updatedAddressesInCart[
                                          foundShoppingAddressIndex
                                        ] = {
                                          ...updatedAddressesInCart[
                                            foundShoppingAddressIndex
                                          ],
                                          assignedBundleIds:
                                            updatedAssignedBundleIds
                                        }
                                        const updatedAssignedProducts = {
                                          ...assignedProducts,
                                          [selectedShoppingCartAddress.id]:
                                            assignedProducts[
                                              selectedShoppingCartAddress.id
                                            ]?.filter(
                                              (product) =>
                                                product.id !== assignedBundleId
                                            )
                                        }
                                        dispatch(
                                          setAssignedProducts({
                                            assignedProducts:
                                              updatedAssignedProducts
                                          })
                                        )
                                        const updatedShoppingCartBundles =
                                          shoppingCartBundles.map((bundle) =>
                                            bundle.id === assignedBundleId
                                              ? {
                                                  ...bundle,
                                                  quantity:
                                                    bundle.quantity +
                                                    (assignedProducts[
                                                      selectedShoppingCartAddress
                                                        .id
                                                    ]?.find(
                                                      (product) =>
                                                        product.id ===
                                                        assignedBundleId
                                                    )?.quantity || 0)
                                                }
                                              : bundle
                                          )
                                        dispatch(
                                          setShoppingCartBundles({
                                            bundles: updatedShoppingCartBundles
                                          })
                                        )
                                        dispatch(
                                          setShoppingAddresses({
                                            addresses: updatedAddressesInCart
                                          })
                                        )
                                        setSelectedShoppingCartAddress(
                                          updatedAddressesInCart[
                                            foundShoppingAddressIndex
                                          ]
                                        )
                                      }
                                    }}
                                  >
                                    <TrashIcon />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                      {shoppingCartAddresses.length > 0 &&
                        selectedShoppingCartAddress?.assignedLeftOverProductIds
                          ?.length > 0 &&
                        selectedShoppingCartAddress.assignedLeftOverProductIds.map(
                          (assignedLeftOverProductId) => (
                            <tr
                              key={`shopping-details-assigned-product-${assignedLeftOverProductId}`}
                            >
                              <td>
                                {
                                  getProductDetails(assignedLeftOverProductId)
                                    ?.name
                                }
                              </td>
                              <td>
                                <div className="form-group col-12">
                                  <input
                                    type="number"
                                    min={1}
                                    value={
                                      assignedProducts[
                                        selectedShoppingCartAddress.id
                                      ]?.find(
                                        (product) =>
                                          product.id ===
                                          assignedLeftOverProductId
                                      )?.quantity || 0
                                    }
                                    onChange={(e) => {
                                      const newQuantity = parseInt(
                                        e.target.value,
                                        10
                                      )
                                      const currentQuantity =
                                        assignedProducts[
                                          selectedShoppingCartAddress.id
                                        ]?.find(
                                          (product) =>
                                            product.id ===
                                            assignedLeftOverProductId
                                        )?.quantity || 0
                                      if (newQuantity > currentQuantity) {
                                        dispatch(
                                          incrementProductQuantity({
                                            productId:
                                              assignedLeftOverProductId,
                                            addressId:
                                              selectedShoppingCartAddress.id,
                                            isBundle: false
                                          })
                                        )
                                      } else if (
                                        newQuantity < currentQuantity
                                      ) {
                                        dispatch(
                                          decrementProductQuantity({
                                            productId:
                                              assignedLeftOverProductId,
                                            addressId:
                                              selectedShoppingCartAddress.id,
                                            isBundle: false
                                          })
                                        )
                                      }
                                    }}
                                    name="bundle-configurator-bundle-quantity"
                                    className="form-control"
                                    aria-label={'Bundle Quantity'}
                                    autoComplete="off"
                                    disabled={false}
                                  />
                                </div>
                              </td>
                              <td className="text-end">
                                <div className="d-flex flex-row float-end">
                                  <button
                                    type="button"
                                    title="Remove Assigned Article"
                                    className="btn btn-outline-danger btn-round"
                                    onClick={() => {
                                      const foundShoppingAddressIndex =
                                        shoppingCartAddresses.findIndex(
                                          (cartAddress) =>
                                            cartAddress.id ===
                                            selectedShoppingCartAddress.id
                                        )
                                      if (foundShoppingAddressIndex !== -1) {
                                        const updatedAddressesInCart = [
                                          ...shoppingCartAddresses
                                        ]
                                        const updatedSelectedAssignmentAddress =
                                          updatedAddressesInCart[
                                            foundShoppingAddressIndex
                                          ]
                                        const assignedLeftOverProductIds =
                                          updatedSelectedAssignmentAddress.assignedLeftOverProductIds

                                        const updatedAssignedLeftOverProductIds =
                                          assignedLeftOverProductIds.filter(
                                            (leftOverProductId) =>
                                              leftOverProductId !==
                                              assignedLeftOverProductId
                                          )
                                        updatedAddressesInCart[
                                          foundShoppingAddressIndex
                                        ] = {
                                          ...updatedAddressesInCart[
                                            foundShoppingAddressIndex
                                          ],
                                          assignedLeftOverProductIds:
                                            updatedAssignedLeftOverProductIds
                                        }

                                        dispatch(
                                          setShoppingAddresses({
                                            addresses: updatedAddressesInCart
                                          })
                                        )
                                        const updatedAssignedProducts = {
                                          ...assignedProducts,
                                          [selectedShoppingCartAddress.id]:
                                            assignedProducts[
                                              selectedShoppingCartAddress.id
                                            ]?.filter(
                                              (product) =>
                                                product.id !==
                                                assignedLeftOverProductId
                                            )
                                        }
                                        const updatedShoppingCartProducts =
                                          shoppingCartLeftOverProducts.map(
                                            (product) =>
                                              product.id ===
                                              assignedLeftOverProductId
                                                ? {
                                                    ...product,
                                                    quantity:
                                                      product.quantity +
                                                      (assignedProducts[
                                                        selectedShoppingCartAddress
                                                          .id
                                                      ]?.find(
                                                        (product) =>
                                                          product.id ===
                                                          assignedLeftOverProductId
                                                      )?.quantity || 0)
                                                  }
                                                : product
                                          )
                                        dispatch(
                                          setShoppingCartLeftOverProducts({
                                            leftOverProducts:
                                              updatedShoppingCartProducts
                                          })
                                        )
                                        dispatch(
                                          setAssignedProducts({
                                            assignedProducts:
                                              updatedAssignedProducts
                                          })
                                        )
                                        setSelectedShoppingCartAddress(
                                          updatedAddressesInCart[
                                            foundShoppingAddressIndex
                                          ]
                                        )
                                      }
                                    }}
                                  >
                                    <TrashIcon />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                      {shoppingCartAddresses.length > 0 &&
                        selectedShoppingCartAddress?.assignedBulkProductsIds
                          ?.length > 0 &&
                        selectedShoppingCartAddress.assignedBulkProductsIds.map(
                          (assignedBulkProductsId) => (
                            <tr
                              key={`shopping-details-assigned-product-${assignedBulkProductsId}`}
                            >
                              <td>
                                {
                                  getProductDetails(assignedBulkProductsId)
                                    ?.name
                                }
                              </td>
                              <td>
                                <div className="form-group col-12">
                                  <input
                                    type="number"
                                    min={1}
                                    value={
                                      assignedProducts[
                                        selectedShoppingCartAddress.id
                                      ]?.find(
                                        (product) =>
                                          product.id === assignedBulkProductsId
                                      )?.quantity || 0
                                    }
                                    onChange={(e) => {
                                      const newQuantity = parseInt(
                                        e.target.value,
                                        10
                                      )
                                      const currentQuantity =
                                        assignedProducts[
                                          selectedShoppingCartAddress.id
                                        ]?.find(
                                          (product) =>
                                            product.id ===
                                            assignedBulkProductsId
                                        )?.quantity || 0
                                      if (newQuantity > currentQuantity) {
                                        dispatch(
                                          incrementProductQuantity({
                                            productId: assignedBulkProductsId,
                                            addressId:
                                              selectedShoppingCartAddress.id,
                                            isBundle: false
                                          })
                                        )
                                      } else if (
                                        newQuantity < currentQuantity
                                      ) {
                                        dispatch(
                                          decrementProductQuantity({
                                            productId: assignedBulkProductsId,
                                            addressId:
                                              selectedShoppingCartAddress.id,
                                            isBundle: false
                                          })
                                        )
                                      }
                                    }}
                                    name="bundle-configurator-bundle-quantity"
                                    className="form-control"
                                    aria-label={'Bundle Quantity'}
                                    autoComplete="off"
                                    disabled={false}
                                  />
                                </div>
                              </td>
                              <td className="text-end">
                                <div className="d-flex flex-row float-end">
                                  <button
                                    type="button"
                                    title="Remove Assigned Article"
                                    className="btn btn-outline-danger btn-round"
                                    onClick={() => {
                                      const foundShoppingAddressIndex =
                                        shoppingCartAddresses.findIndex(
                                          (cartAddress) =>
                                            cartAddress.id ===
                                            selectedShoppingCartAddress.id
                                        )
                                      if (foundShoppingAddressIndex !== -1) {
                                        const updatedAddressesInCart = [
                                          ...shoppingCartAddresses
                                        ]
                                        const updatedSelectedAssignmentAddress =
                                          updatedAddressesInCart[
                                            foundShoppingAddressIndex
                                          ]
                                        const assignedBulkProductsIds =
                                          updatedSelectedAssignmentAddress.assignedBulkProductsIds

                                        const updatedAssignedLeftOverProductIds =
                                          assignedBulkProductsIds.filter(
                                            (Product) =>
                                              Product !== assignedBulkProductsId
                                          )
                                        updatedAddressesInCart[
                                          foundShoppingAddressIndex
                                        ] = {
                                          ...updatedAddressesInCart[
                                            foundShoppingAddressIndex
                                          ],
                                          assignedBulkProductsIds:
                                            updatedAssignedLeftOverProductIds
                                        }

                                        dispatch(
                                          setShoppingAddresses({
                                            addresses: updatedAddressesInCart
                                          })
                                        )
                                        const updatedAssignedProducts = {
                                          ...assignedProducts,
                                          [selectedShoppingCartAddress.id]:
                                            assignedProducts[
                                              selectedShoppingCartAddress.id
                                            ]?.filter(
                                              (product) =>
                                                product.id !==
                                                assignedBulkProductsId
                                            )
                                        }
                                        const updatedShoppingCartProducts =
                                          shoppingCartProducts.map((product) =>
                                            product.id ===
                                            assignedBulkProductsId
                                              ? {
                                                  ...product,
                                                  leftOverQuantity:
                                                    product.leftOverQuantity +
                                                    (assignedProducts[
                                                      selectedShoppingCartAddress
                                                        .id
                                                    ]?.find(
                                                      (product) =>
                                                        product.id ===
                                                        assignedBulkProductsId
                                                    )?.quantity || 0)
                                                }
                                              : product
                                          )
                                        dispatch(
                                          setShoppingCart({
                                            products:
                                              updatedShoppingCartProducts
                                          })
                                        )
                                        dispatch(
                                          setAssignedProducts({
                                            assignedProducts:
                                              updatedAssignedProducts
                                          })
                                        )
                                        setSelectedShoppingCartAddress(
                                          updatedAddressesInCart[
                                            foundShoppingAddressIndex
                                          ]
                                        )
                                      }
                                    }}
                                  >
                                    <TrashIcon />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                    </>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <>
            <div
              className="modal-backdrop fade show"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            ></div>
            <div
              className="modal fade show d-flex align-items-center justify-content-center"
              id="shoppingCartManagerModal"
              tabIndex={-1}
              aria-labelledby="shoppingCartManagerModalLabel"
              aria-hidden="true"
              style={{ display: 'block' }}
            >
              <div
                className="modal-dialog"
                style={{ width: '500px', height: '200px' }}
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h1
                      className="modal-title fs-5"
                      id="shoppingCartManagerModalLabel"
                    >
                      <i className="bi bi-plus-circle me-1"></i>Select a
                      shipping date
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => {
                        setShippingDate(null)
                        setShowModal(false)
                      }}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="w-100">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label
                            htmlFor="shippingDate"
                            className="form-label text-muted"
                          >
                            Shipping Date
                          </label>
                          <div>
                            <Calendar
                              id="shippingDate"
                              name="shippingDate"
                              filterDate={isWeekday}
                              minDate={getDateOfDispatchMinimumDate()}
                              onChange={(date: Date) => setShippingDate(date)}
                              selected={shippingDate}
                              className={`form-control ${
                                !isDateFilled && 'border-danger'
                              }`}
                              dateFormat={'dd/MM/yyyy'}
                              autoComplete={'off'}
                              calendarStartDay={DaysOfWeek.Monday}
                              placeholderText="Select Date"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        if (shippingDate) {
                          handleSaveShippingDate()
                        } else {
                          setIsDateFilled((prev) => {
                            return !prev
                          })
                        }
                      }}
                    >
                      <i className="bi bi-save me-1"></i>Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ShoppingCartManager
