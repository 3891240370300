import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import ShoppingCart from './ShoppingCart'
import ShoppingShippingDetails from './ShoppingShippingDetails'
import { dismissModal } from '../../utils/dismissModal'
import { openModal } from '../../utils/openModal'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import ShoppingDeliveryAddressPicker from './ShoppingDeliveryAddressPicker'
import {
  Address,
  ShoppingCartAddress,
  ShoppingCartProduct,
  SplitProducts
} from '../../types'
import {
  resetShoppingAddresses,
  resetShoppingCart,
  setShoppingAddresses,
  setShoppingCart,
  setShoppingCartBundles,
  setShoppingCartLeftOverProducts,
  setSplitProduct
} from '../../store/reducers/shoppingCartReducer'
import ShoppingDeliveryAddressEditor from './ShoppingDeliveryAddressEditor'
import ShoppingDetailsAssignArticles from './ShoppingDetailsAssignArticles'
import AddressEditor from './AddressEditor'
import { updateUserAddress } from '../../store/reducers/api/usersReducer'
import Progress from '../loaders/Progress'
import { setToast } from '../../store/reducers/toastReducer'
import ShoppingPayment from './ShoppingPayment'
import Terms from '../Terms'
import BundleConfiguratorProductPicker from './BundleConfigurator/BundleConfiguratorProductPicker'
import ShoppingOrderConfirmation from './ShoppingOrderConfirmation'
import { TrashIcon } from '../icons/TrashIcon'
import { generateSplitId } from '../../utils/idGenerator'
import { getMaximumProductOrderQuantity } from '../../utils/getMaximumProductOrderQuantity'
import { getMinimumProductOrderQuantity } from '../../utils/getMinimumProductOrderQuantity'
import { addToPreMadeBox, removeFromPreMadeBox } from '../../store/reducers/PreMadeBoxReducer'
import { validateShoppingCartBundles } from '../../utils/validateShoppingCartBundles'
import { resetItemError, resetItemMessage } from '../../store/reducers/api/wawiAPI/itemReducer'
import { ARTICLE_ITEM_CREATION_SUCCESS_MESSAGE } from '../../constants/messages'
import { getAllSalutations } from '../../store/reducers/api/salutationReducer'
import { getAllTitles } from '../../store/reducers/api/titleReducer'

interface ShoppingModalsProps {
  setSelectedAddress: React.Dispatch<
    React.SetStateAction<Partial<ShoppingCartAddress> | null>
  >
  selectedAddress: Partial<ShoppingCartAddress> | null
  isAllowedToWriteDeliveryAddresses: boolean
  loadCostCenterOptionsDebounced: any
  selectedMultipleAddresses: string[] | null
  setSelectedMultipleAddresses: React.Dispatch<
    React.SetStateAction<string[] | null>
  >
}
const ShoppingModals = ({
  setSelectedAddress,
  selectedAddress,
  isAllowedToWriteDeliveryAddresses,
  loadCostCenterOptionsDebounced,
  selectedMultipleAddresses,
  setSelectedMultipleAddresses
}: ShoppingModalsProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const splitProduct = useAppSelector((state) => state.shoppingCart.splitProduct)
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)
  const shoppingCartLeftOverProducts = useAppSelector((state) => state.shoppingCart.leftOverProducts)
  const deliveryAddresses = useAppSelector((state) => state.apiUsers.addresses)
  const shoppingCartAddresses = useAppSelector((state) => state.shoppingCart.addresses)
  const shoppingCartBundles = useAppSelector((state) => state.shoppingCart.bundles)
  const isBulkOrder = useAppSelector((state) => state.shoppingCart.isBulkOrder)
  const isLoadingUserAddress = useAppSelector((state) => state.apiUsers.isLoading)
  const legalTexts = useAppSelector((state) => state.apiCompany.legalTexts)
  const isLoadingItem = useAppSelector((state) => state.apiItem.isLoading)
  const itemMessage = useAppSelector((state) => state.apiItem.message)
  const itemError = useAppSelector((state) => state.apiItem.error)

  const shoppingCartProductsWithQuantity = shoppingCartProducts.map((shoppingCartProduct) => ({ ...shoppingCartProduct, quantity: shoppingCartProduct.quantity ?? 1 }))

  const [selectedAssignmentAddress, setSelectedAssignmentAddress] =
    useState<ShoppingCartAddress | null>(null)
  const [shippingMode, setShippingMode] = useState<
    'inventory' | 'delivery' | null
  >('delivery')
  const [areBundlesValid, setAreBundlesValid] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [selectedBillingAddress, setSelectedBillingAddress] =
    useState<Partial<Address> | null>(null)
  const [isOneAddress, setIsOneAddress] = useState<boolean>(true)
  const [splitProducts, setSplitProducts] = useState<SplitProducts | null>(null)
  const [addressEditMode, setAddressEditMode] = useState<boolean>(false)

  const billingOrBillingAndDeliveryAddress = profile?.addresses?.find(
    (address) =>
      address.type === 'billing' || address.type === 'billingAndDelivery'
  )
  const dispatch = useAppDispatch()

  const maximumQuantity = 1000

  const token = currentUser?.token

  const handleConfirmAndPay = () => {
    const addressFillingMap = Object.fromEntries(shoppingCartAddresses.map(address => [address.id, (address.assignedLeftOverProductIds.length > 0 || address.assignedBulkProductsIds.length > 0 || address.assignedBundleIds.length > 0) ? 1 : 0]))
    const allAddressesFilled = Object.values(addressFillingMap).every(value => value === 1)
    if (
      !allAddressesFilled &&
      !isOneAddress
    ) {
      dispatch(
        setToast({
          title: 'delivery destination Required',
          message: 'Please fill in all delivery destinations.',
          isVisible: true,
          timestamp: dayjs().format('LT'),
          type: 'danger',
          delay: 3000
        })
      )
    } else if (
      (!isOneAddress && isBulkOrder &&
        shoppingCartProducts.some(product => product.leftOverQuantity !== 0)) ||
      (!isBulkOrder &&
        (shoppingCartBundles.some(product => product.quantity !== 0) ||
          shoppingCartLeftOverProducts.some(product => product.quantity !== 0)))
    ) {
      dispatch(
        setToast({
          title: 'Not all items assigned',
          message: 'Please add remaining items to a delivery destination.',
          isVisible: true,
          timestamp: dayjs().format('LT'),
          type: 'danger',
          delay: 3000
        })
      )
    } else {
      openModal('shoppingPaymentModal')
      dismissModal('shoppingShippingDetailsModal')
    }
  }

  useEffect(() => {
    setAreBundlesValid(validateShoppingCartBundles(shoppingCartBundles))
  }, [shoppingCartBundles])

  useEffect(() => {
    setSelectedBillingAddress(
      billingOrBillingAndDeliveryAddress as ShoppingCartAddress | null
    )
  }, [billingOrBillingAndDeliveryAddress])

  const calculateTotalQuantityInBundles = ({
    splitProduct
  }: {
    splitProduct: ShoppingCartProduct | null | undefined
  }): number => {
    return shoppingCartBundles.reduce((total, bundle) => {
      const bundleProduct = bundle.products.find((p) =>
        splitProduct?.splitId && p.splitId
          ? p.splitId === splitProduct.splitId
          : p.id === splitProduct?.id
      )
      return (
        total + (bundleProduct ? bundleProduct.quantity * bundle.quantity : 0)
      )
    }, 0)
  }

  useEffect(() => {
    if (itemError && itemError.errors && itemError.errors.message) {
      dispatch(setToast({
        title: 'Error',
        message: itemError.errors.message,
        isVisible: true,
        type: 'danger'
      }))
      dispatch(resetItemError())
    }
  }, [itemError])

  useEffect(() => {
    if (itemMessage && itemMessage === ARTICLE_ITEM_CREATION_SUCCESS_MESSAGE) {
      dispatch(setToast({
        title: 'Success',
        message: itemMessage,
        isVisible: true,
        type: 'success'
      }))
      dispatch(resetItemMessage())
    }
  }, [itemMessage])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getAllSalutations({ token, perPage: 50, page: 1, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getAllTitles({ token, perPage: 50, page: 1, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      <div
        className="modal fade"
        id="shoppingCartModal"
        data-bs-backdrop="static"
        tabIndex={-1}
        aria-labelledby="shoppingCartModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingCartModalLabel">
                <i className="bi bi-cart me-1"></i>Shopping Cart
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <ShoppingCart />
            </div>
            {isLoadingItem && <Progress marginBottom={false} />}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  if (shoppingCartProducts.length > 0) {
                    if (!isBulkOrder && !areBundlesValid) {
                      const toastPayload = {
                        title: 'Warning',
                        message: 'Please ensure the bundles are complete.',
                        isVisible: true,
                        timestamp: dayjs().format('LT'),
                        type: 'warning',
                        delay: 2000
                      }
                      dispatch(setToast(toastPayload))
                      return
                    }
                    if (shoppingCartProducts.some(product => !product.isQuantityValid)) {
                      const toastPayload = {
                        title: 'Invalid Quantity',
                        message: `Please enter a valid quantity for ${shoppingCartProducts.filter(product => !product.isQuantityValid).map(product => product.name).join(', ')}.`,
                        isVisible: true,
                        timestamp: dayjs().format('LT'),
                        type: 'warning',
                        delay: 2000
                      }
                      dispatch(setToast(toastPayload))
                      return
                    }

                    dismissModal('shoppingCartModal')
                    openModal('shoppingShippingDetailsModal')
                  } else {
                    const toastPayload = {
                      title: 'Warning',
                      message: 'Please ensure your cart is not empty.',
                      isVisible: true,
                      timestamp: dayjs().format('LT'),
                      type: 'warning',
                      delay: 2000
                    }
                    dispatch(setToast(toastPayload))
                  }
                }}
                disabled={
                  shoppingCartProducts.length === 0 ||
                  (!isBulkOrder && !areBundlesValid)
                }
              >
                Checkout
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="shoppingShippingDetailsModal"
        data-bs-backdrop="static"
        tabIndex={-1}
        aria-labelledby="shoppingShippingDetailsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                id="shoppingShippingDetailsModalLabel"
              >
                <i className="bi bi-cart me-1"></i>Shopping Cart
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <ShoppingShippingDetails
                setIsOneAddress={setIsOneAddress}
                setSelectedAddress={setSelectedAddress}
                isOneAddress={isOneAddress}
                setSelectedAssignmentAddress={setSelectedAssignmentAddress}
                shippingMode={shippingMode}
                setShippingMode={setShippingMode}
                setEditMode={setEditMode}
              />
            </div>
            {
              shoppingCartAddresses.length === 0
                ? (
                  <div className="text-danger small text-end mx-3">
                    Please add a delivery address
                  </div>
                  )
                : shoppingCartAddresses.some(address => !address.shippingDate) && (
                <div className="text-danger small text-end mx-3">
                  Please select a shipping date
                </div>
                )
            }
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  dismissModal('shoppingShippingDetailsModal')
                  openModal('shoppingCartModal')
                }}
              >
                Back
              </button>
              <button
                type="button"
                className="btn btn-primary d-flex justify-content-between align-items-center"
                onClick={() => handleConfirmAndPay()}
                disabled={shoppingCartAddresses.length === 0 || shoppingCartAddresses.some(address => !address.shippingDate)}
              >
                <span>Confirm and Pay</span>
                <i className="bi bi-arrow-right ms-2 text-white"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="shoppingDeliveryAddressPickerModal"
        data-bs-backdrop="static"
        tabIndex={-1}
        aria-labelledby="shoppingDeliveryAddressPickerModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                id="shoppingDeliveryAddressPickerModalLabel"
              >
                <i className="bi bi-truck me-1"></i>Shipping Details - Select
                delivery address
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {isLoadingUserAddress
              ? (
                <Progress marginBottom={false} />
                )
              : (
              <hr className="border border-1 opacity-50 m-0"></hr>
                )}
            <div className="modal-body">
              <ShoppingDeliveryAddressPicker
                selectedMultipleAddresses={selectedMultipleAddresses}
                setSelectedMultipleAddresses={setSelectedMultipleAddresses}
                isOneAddress={isOneAddress}
                isLoading={isLoadingUserAddress}
                setSelectedAddress={setSelectedAddress}
                selectedAddress={selectedAddress}
                isAllowedToWriteDeliveryAddresses={isAllowedToWriteDeliveryAddresses}
                setAddressEditMode={setAddressEditMode}
              />
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  if (selectedAddress) {
                    setSelectedAddress(null)
                    const updatedAddresses = shoppingCartAddresses.filter(
                      (address) => address.id !== selectedAddress.id
                    )
                    dispatch(
                      setShoppingAddresses({ addresses: updatedAddresses })
                    )
                  }
                }}
                disabled={!selectedAddress}
              >
                Reset Selection
              </button>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    dismissModal('shoppingDeliveryAddressPickerModal')
                    openModal('shoppingShippingDetailsModal')
                    if (selectedAddress) {
                      const updatedAddresses = shoppingCartAddresses.filter(
                        (address) => address.id !== selectedAddress.id
                      )
                      const payload = {
                        addresses: updatedAddresses
                      }
                      dispatch(setShoppingAddresses(payload))
                      setSelectedAddress(null)
                    }
                  }}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    dismissModal('shoppingDeliveryAddressPickerModal')
                    openModal('shoppingShippingDetailsModal')
                    if (isOneAddress && selectedAddress?.id) {
                      const updatedAddress: ShoppingCartAddress = {
                        ...selectedAddress,
                        id: selectedAddress.id,
                        shippingDate: '',
                        assignedBundleIds: shoppingCartBundles.map(
                          (bundle) => bundle.id
                        ),
                        assignedLeftOverProductIds:
                          shoppingCartLeftOverProducts.map(
                            (product) => product.id
                          ),
                        assignedBulkProductsIds: shoppingCartProducts.map(
                          (product) => product.id
                        ),
                        vat: null,
                        type: 'delivery',
                        country: selectedAddress.country ?? '',
                        companyName: selectedAddress.companyName ?? '',
                        salutation: selectedAddress.salutation ?? '',
                        firstName: selectedAddress.firstName ?? '',
                        lastName: selectedAddress.lastName ?? '',
                        email: selectedAddress.email ?? '',
                        phone: selectedAddress.phone ?? '',
                        street: selectedAddress.street ?? '',
                        zip: selectedAddress.zip ?? '',
                        city: selectedAddress.city ?? '',
                        addressAddition: selectedAddress.addressAddition ?? '',
                        note: '',
                        costCenter: selectedAddress.costCenter ?? ''
                      }
                      const updatedAddresses = [updatedAddress]
                      const payload = { addresses: updatedAddresses }
                      if (isOneAddress) {
                        dispatch(resetShoppingAddresses())
                      }
                      dispatch(setShoppingAddresses(payload))
                    } else if (!isOneAddress) {
                      const updatedShippingAddresses = [
                        ...shoppingCartAddresses
                      ]
                      selectedMultipleAddresses?.forEach((addressId) => {
                        const address = deliveryAddresses.find(
                          (deliveryAddress) => deliveryAddress.id === addressId
                        )
                        if (address) {
                          const updatedAddress: ShoppingCartAddress = {
                            ...address,
                            type: 'delivery',
                            shippingDate: '',
                            assignedBundleIds: [],
                            assignedLeftOverProductIds: [],
                            assignedBulkProductsIds: [],
                            vat: null,
                            note: ''
                          }
                          updatedShippingAddresses.push(updatedAddress)
                        }
                      })
                      dispatch(
                        setShoppingAddresses({
                          addresses: updatedShippingAddresses
                        })
                      )
                      setSelectedMultipleAddresses([])
                    }
                  }}
                  disabled={
                    (selectedMultipleAddresses ?? []).length <= 0 &&
                    !selectedAddress
                  }
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="shoppingDeliveryAddressEditorModal"
        data-bs-backdrop="static"
        tabIndex={-1}
        aria-labelledby="shoppingDeliveryAddressEditorModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                id="shoppingDeliveryAddressEditorModalLabel"
              >
                <i className="bi bi-cart me-1"></i>Shipping Details - {editMode ? 'Edit' : 'Add'} Delivery Address
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <ShoppingDeliveryAddressEditor
                isOneAddress={isOneAddress}
                shippingAddress={selectedAddress}
                setSelectedDeliveryAddress={setSelectedAddress}
                setSelectedAddress={setSelectedAddress}
                editMode={editMode}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="shoppingDetailsAssignArticlesModal"
        data-bs-backdrop="static"
        tabIndex={-1}
        aria-labelledby="shoppingDetailsAssignArticlesModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                id="shoppingDetailsAssignArticlesModalLabel"
              >
                <i className="bi bi-cart me-1"></i>Shopping Details - Assign
                Articles
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {selectedAssignmentAddress
                ? (
                  <ShoppingDetailsAssignArticles
                    selectedAssignmentAddress={selectedAssignmentAddress}
                    setSelectedAssignmentAddress={setSelectedAssignmentAddress}
                  />
                  )
                : (
                <p>No assignment address selected</p>
                  )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  dismissModal('shoppingDetailsAssignArticlesModal')
                  openModal('shoppingShippingDetailsModal')
                }}
              >
                Back
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  dismissModal('shoppingDetailsAssignArticlesModal')
                  openModal('shoppingShippingDetailsModal')
                }}
              >
                Assign Articles
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="addressEditorModal"
        data-bs-backdrop="static"
        tabIndex={-1}
        aria-labelledby="addressEditorModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="addressEditorModalLabel">
                <i className="bi bi-cart me-1"></i>Shopping Details - Delivery
                Address Editor
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {isLoadingUserAddress ? (<Progress marginBottom={false} />) : (<hr className="border border-1 opacity-50 m-0"></hr>)}
            <div className="modal-body">
              <AddressEditor
                initialAddress={addressEditMode ? selectedAddress : null}
                id={profile?.id || ''}
                save={updateUserAddress}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="shoppingPaymentModal"
        data-bs-backdrop="static"
        tabIndex={-1}
        aria-labelledby="shoppingPaymentModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingPaymentModalLabel">
                <i className="bi bi-cart me-1"></i>Shopping Cart
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <hr className="border border-1 opacity-50 m-0"></hr>
            <div className="modal-body">
              <ShoppingPayment
                billingAddress={selectedBillingAddress}
                loadCostCenterOptionsDebounced={loadCostCenterOptionsDebounced}
                shippingMode={shippingMode}
                setSelectedBillingAddress={setSelectedBillingAddress}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="termsModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="termsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-3" id="termsModalLabel">
                Terms and Conditions
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-toggle="modal"
                data-bs-target="#shoppingPaymentModal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Terms
                terms={
                  legalTexts.find((legalText) => legalText.type === 'terms')
                    ?.template
                }
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#shoppingPaymentModal"
              >
                Understood
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="bundleConfiguratorProductPickerModal"
        data-bs-backdrop="static"
        tabIndex={-1}
        aria-labelledby="bundleConfiguratorProductPickerModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                id="bundleConfiguratorProductPickerModalLabel"
              >
                <i className="bi bi-cart me-1"></i>Shopping Cart
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <BundleConfiguratorProductPicker />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  dismissModal('bundleConfiguratorProductPickerModal')
                  openModal('shoppingCartModal')
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="shoppingOrderConfirmationModal"
        data-bs-backdrop="static"
        tabIndex={-1}
        aria-labelledby="shoppingOrderConfirmationModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                id="shoppingOrderConfirmationModalLabel"
              >
                <i className="bi bi-cart me-1"></i>Shopping Cart
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  dispatch(resetShoppingCart())
                  dispatch(resetShoppingAddresses())
                }}
              ></button>
            </div>
            <div className="modal-body">
              <ShoppingOrderConfirmation
                selectedBillingAddress={selectedBillingAddress}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="splitAmountModal"
        data-bs-backdrop="static"
        tabIndex={-1}
        aria-labelledby="splitAmountModal"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: '577px' }}
        >
          <div
            className="modal-content"
            style={{ height: '400px', width: '577px' }}
          >
            <div className="modal-header">
              <h1
                className="modal-title fs-5 d-flex align-items-center"
                id="splitAmountModalLabel"
              >
                <i className="bi bi-pencil-fill me-2"></i> Split Amount
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  openModal('shoppingShippingDetailsModal')
                  dismissModal('splitAmountModal')
                  setSplitProducts(null)
                }}
              ></button>
            </div>
            <div
              className="modal-body"
              style={{
                display: 'grid',
                placeItems: '',
                height: '100%',
                padding: '0'
              }}
            >
              <div className="w-100">
                <div
                  className="d-flex justify-content-between align-items-center bg-light w-100 p-3 border-bottom border-1"
                  style={{ height: '50px' }}
                >
                  <h6 className="fw-bold">{splitProduct?.product?.name}</h6>
                  <div
                    className="d-flex justify-content-center align-items-center gap-3"
                    style={{ color: '#808080' }}
                  >
                    remaining Amount
                    <input
                      type="number"
                      min={Math.max(
                        calculateTotalQuantityInBundles({
                          splitProduct: splitProduct?.product
                        }),
                        getMinimumProductOrderQuantity(
                          splitProduct?.product?.minimumOrderQuantity || 0,
                          splitProduct?.product?.graduatedPrices || []
                        ),
                        calculateTotalQuantityInBundles({
                          splitProduct: splitProduct?.product
                        })
                      )}
                      max={
                        splitProduct?.product?.isExceedStockEnabled
                          ? getMaximumProductOrderQuantity(
                            maximumQuantity,
                            splitProduct?.product?.graduatedPrices
                          )
                          : Math.max(
                            (splitProduct?.product?.stock?.stockLevel || 0) -
                                (splitProduct?.product?.stock
                                  ?.stockLevelReserved || 0),
                            0
                          ) || 1
                      }
                      value={splitProduct?.product?.quantity}
                      onChange={(e) => {
                        const max =
                          Math.max(
                            (splitProduct?.product?.stock?.stockLevel || 0) -
                              (splitProduct?.product?.stock
                                ?.stockLevelReserved || 0),
                            0
                          ) || 1
                        let min = getMinimumProductOrderQuantity(
                          splitProduct?.product?.minimumOrderQuantity || 1,
                          splitProduct?.product?.graduatedPrices || []
                        )

                        const totalShoppingCartProductInBundlesQuantity =
                          calculateTotalQuantityInBundles({
                            splitProduct: splitProduct?.product
                          })

                        min = Math.max(
                          min,
                          totalShoppingCartProductInBundlesQuantity
                        )
                        const value = Number(e.target.value)
                        const quantity = splitProduct?.product
                          ?.isExceedStockEnabled
                          ? Math.max(
                            Math.min(
                              value,
                              getMaximumProductOrderQuantity(
                                maximumQuantity,
                                splitProduct?.product?.graduatedPrices || []
                              )
                            ),
                            min
                          )
                          : Math.max(Math.min(value, max), min)
                        let foundProductInCartIndex = -1
                        if (splitProduct?.type === 'bundle') {
                          foundProductInCartIndex =
                            shoppingCartBundles.findIndex((cartBundle) =>
                              splitProduct?.product?.splitId &&
                              cartBundle.splitId
                                ? cartBundle.splitId ===
                                  splitProduct.product.splitId
                                : cartBundle.id === splitProduct?.product?.id
                            )
                        } else if (splitProduct?.type === 'product') {
                          foundProductInCartIndex =
                            shoppingCartProductsWithQuantity.findIndex(
                              (cartProduct) =>
                                splitProduct?.product?.splitId &&
                                cartProduct.splitId
                                  ? cartProduct.splitId ===
                                    splitProduct.product.splitId
                                  : cartProduct.id === splitProduct?.product?.id
                            )
                        } else if (splitProduct?.type === 'leftOverProduct') {
                          foundProductInCartIndex =
                            shoppingCartLeftOverProducts.findIndex(
                              (cartProduct) =>
                                splitProduct?.product?.splitId &&
                                cartProduct.splitId
                                  ? cartProduct.splitId ===
                                    splitProduct.product.splitId
                                  : cartProduct.id === splitProduct?.product?.id
                            )
                        }
                        if (foundProductInCartIndex !== -1) {
                          const updatedProductsInCart = [
                            ...shoppingCartProductsWithQuantity
                          ]
                          updatedProductsInCart[foundProductInCartIndex] = {
                            ...updatedProductsInCart[foundProductInCartIndex],
                            quantity
                          }

                          if (splitProduct) {
                            const updatedSplitProduct = {
                              ...splitProduct,
                              product: { ...splitProduct.product, quantity }
                            }
                            dispatch(setSplitProduct(updatedSplitProduct))
                          }
                        }
                      }}
                      name="quantity"
                      className="form-control"
                      aria-label={'Cart Item Quantity'}
                      autoComplete="off"
                      disabled={false}
                      style={{ width: '4rem' }}
                    />
                  </div>

                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '100px' }}
                  >
                    <button
                      type="button"
                      className="btn btn-primary d-flex justify-content-center align-items-center "
                      style={{
                        width: '31px',
                        height: '31px',
                        padding: 0
                      }}
                      onClick={() => {
                        if (
                          splitProduct?.product &&
                          splitProduct.product.quantity > 1
                        ) {
                          const updatedProduct = {
                            ...splitProduct,
                            product: {
                              ...splitProduct.product,
                              quantity: splitProduct.product.quantity - 1
                            }
                          }

                          dispatch(setSplitProduct(updatedProduct))
                          setSplitProducts((prev) => {
                            const currentProducts = prev
                              ? [...prev.products]
                              : []
                            const newLocalId = prev ? (prev.lastId || 0) + 1 : 1
                            const newProduct = {
                              ...splitProduct.product,
                              quantity: 1,
                              isSplit: true,
                              splitId: generateSplitId()
                            }
                            const updatedSplitProducts = {
                              products: [...currentProducts, newProduct],
                              lastId: newLocalId
                            } as SplitProducts

                            updatedSplitProducts.products =
                              updatedSplitProducts.products.map((product) => {
                                if (product.stock) {
                                  return {
                                    ...product,
                                    stock: {
                                      ...product.stock,
                                      stockLevelReserved:
                                        (product.stock.stockLevelReserved ||
                                          0) + 1
                                    }
                                  }
                                }
                                return product
                              })
                            const updatedSplitProduct = {
                              ...splitProduct,
                              product: {
                                ...splitProduct.product,
                                stock: {
                                  ...splitProduct?.product?.stock,
                                  stockLevelReserved:
                                    (splitProduct?.product?.stock
                                      ?.stockLevelReserved || 0) + 1
                                },
                                quantity:
                                  (splitProduct?.product?.quantity || 0) - 1
                              }
                            }
                            dispatch(setSplitProduct(updatedSplitProduct))
                            return updatedSplitProducts
                          })
                        }
                      }}
                    >
                      <i className="bi bi-plus fs-2"></i>
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    maxHeight: '200px',
                    overflowY: 'auto',
                    paddingLeft: '0.5rem',
                    paddingRight: '0.5rem'
                  }}
                >
                  <table className="table p-3">
                    <thead className="p-3">
                      <tr className="p-3">
                        <th scope="col">Product</th>
                        <th scope="col">Split Quantity</th>
                        <th scope="col">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {splitProducts &&
                        splitProducts.products.length > 0 &&
                        splitProducts.products.map((product) => (
                          <tr key={product.splitId || product.id}>
                            <td>{product.name}</td>
                            <td>
                              <input
                              type="number"
                              value={product.quantity}
                              onChange={(e) => {
                                const newQuantity = Number(e.target.value)
                                const previousQuantity = product.quantity

                                if (splitProduct && previousQuantity > 0) {
                                  const availableQuantity =
                                  (splitProduct.product?.quantity || 0) +
                                  previousQuantity

                                  if (
                                    newQuantity <= availableQuantity &&
                                    newQuantity >= 1
                                  ) {
                                    const updatedQuantity =
                                    availableQuantity - newQuantity
                                    dispatch(
                                      setSplitProduct({
                                        ...splitProduct,
                                        product: {
                                          ...splitProduct.product,
                                          quantity: updatedQuantity
                                        }
                                      })
                                    )
                                    setSplitProducts((prev) => {
                                      if (prev) {
                                        const updatedProducts =
                                          prev.products.map((p) =>
                                            p.splitId === product.splitId
                                              ? {
                                                  ...p,
                                                  quantity: newQuantity
                                                }
                                              : p
                                          )
                                        return {
                                          ...prev,
                                          products: updatedProducts
                                        } as SplitProducts
                                      }
                                      return null
                                    })
                                  }
                                }
                              }}
                                min={1}
                                max={
                                  splitProduct?.product
                                    ? splitProduct.product.quantity +
                                      product.quantity
                                    : 0
                                }
                                className="form-control"
                                style={{ width: '80px' }}
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-outline-danger btn-round-sm"
                                onClick={() => {
                                  if (splitProduct) {
                                    const updatedProduct = {
                                      ...splitProduct,
                                      product: {
                                        ...splitProduct.product,
                                        quantity:
                                          (splitProduct.product?.quantity ||
                                            0) + product.quantity
                                      }
                                    }
                                    dispatch(setSplitProduct(updatedProduct))
                                  }
                                  setSplitProducts((prev) => {
                                    if (prev) {
                                      const filteredProducts =
                                        prev.products.filter(
                                          (p) => p.splitId !== product.splitId
                                        )
                                      return {
                                        ...prev,
                                        products: filteredProducts
                                      } as SplitProducts
                                    }
                                    return null
                                  })
                                }}
                              >
                                <TrashIcon />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 p-3">
              <div> </div>
              <button
                type="button"
                className="btn btn-primary d-flex justify-content-center align-items-center"
                onClick={() => {
                  if (
                    splitProduct?.product?.specifications &&
                    splitProduct?.product?.specifications.isBillOfMaterials
                  ) {
                    dispatch(removeFromPreMadeBox(splitProduct?.product?.id))
                  }
                  if (
                    splitProducts &&
                    splitProduct &&
                    splitProduct.product &&
                    splitProduct.product.quantity <= 0
                  ) {
                    if (splitProduct?.type === 'product') {
                      const updatedProducts = shoppingCartProducts.filter(
                        (product) =>
                          splitProduct?.product?.splitId
                            ? splitProduct?.product?.id !== product.splitId
                            : splitProduct?.product?.id !== product.id
                      )
                      const updatedShoppingProducts = [
                        ...updatedProducts,
                        ...splitProducts.products
                      ]
                      dispatch(
                        setShoppingCart({ products: updatedShoppingProducts })
                      )
                    } else if (splitProduct?.type === 'bundle') {
                      const updatedBundles = shoppingCartBundles.filter(
                        (bundle) =>
                          splitProduct?.product?.splitId
                            ? splitProduct?.product?.id !== bundle.splitId
                            : splitProduct?.product?.id !== bundle.id
                      )
                      const updatedShoppingProducts = [
                        ...updatedBundles,
                        ...splitProducts.products
                      ]
                      dispatch(
                        setShoppingCartBundles({
                          bundles: updatedShoppingProducts
                        })
                      )
                    } else if (splitProduct?.type === 'leftOverProduct') {
                      const updatedProducts =
                        shoppingCartLeftOverProducts.filter((product) =>
                          splitProduct?.product?.splitId
                            ? splitProduct?.product?.id !== product.splitId
                            : splitProduct?.product?.id !== product.id
                        )
                      const updatedShoppingProducts = [
                        ...updatedProducts,
                        ...splitProducts.products
                      ]
                      dispatch(
                        setShoppingCart({ products: updatedShoppingProducts })
                      )
                      dispatch(
                        setShoppingCartLeftOverProducts({
                          leftOverProducts: updatedShoppingProducts
                        })
                      )
                    }
                  } else if (
                    splitProducts &&
                    splitProduct &&
                    splitProduct.product &&
                    splitProduct.product.quantity > 0
                  ) {
                    if (splitProduct?.type === 'product') {
                      let updatedShoppingProducts = [
                        ...shoppingCartProducts,
                        ...splitProducts.products
                      ]
                      updatedShoppingProducts = updatedShoppingProducts.map(
                        (product) => {
                          if (
                            splitProduct?.product &&
                            product.splitId ===
                              splitProduct.product.splitId &&
                            product.id === splitProduct.product.id
                          ) {
                            return {
                              ...product,
                              quantity: splitProduct.product.quantity
                            }
                          }
                          return product
                        }
                      )
                      updatedShoppingProducts.forEach((product) => {
                        if (
                          'specifications' in product &&
                          product.specifications.isBillOfMaterials
                        ) {
                          dispatch(addToPreMadeBox(product.splitId))
                        }
                      })

                      dispatch(
                        setShoppingCart({ products: updatedShoppingProducts })
                      )
                    } else if (splitProduct?.type === 'bundle') {
                      let updatedShoppingProducts = [
                        ...shoppingCartBundles,
                        ...(splitProducts?.products || [])
                      ]
                      updatedShoppingProducts = updatedShoppingProducts.map(
                        (product) => {
                          if (
                            splitProduct?.product &&
                            product.splitId ===
                              splitProduct.product.splitId &&
                            product.id === splitProduct.product.id
                          ) {
                            return {
                              ...product,
                              quantity: splitProduct.product.quantity
                            }
                          }
                          return product
                        }
                      )
                      updatedShoppingProducts.forEach((product) => {
                        if (
                          'specifications' in product &&
                          product.specifications.isBillOfMaterials
                        ) {
                          dispatch(addToPreMadeBox(product.splitId))
                        }
                      })

                      dispatch(
                        setShoppingCartBundles({
                          bundles: updatedShoppingProducts
                        })
                      )
                    } else if (splitProduct?.type === 'leftOverProduct') {
                      let updatedShoppingProducts = [
                        ...shoppingCartLeftOverProducts,
                        ...splitProducts.products
                      ]
                      updatedShoppingProducts = updatedShoppingProducts.map(
                        (product) => {
                          if (
                            splitProduct?.product &&
                            product.splitId ===
                              splitProduct.product.splitId &&
                            product.id === splitProduct.product.id
                          ) {
                            return {
                              ...product,
                              quantity: splitProduct.product.quantity
                            }
                          }
                          return product
                        }
                      )

                      dispatch(
                        setShoppingCart({ products: updatedShoppingProducts })
                      )

                      dispatch(
                        setShoppingCartLeftOverProducts({
                          leftOverProducts: updatedShoppingProducts
                        })
                      )
                    }
                  }

                  setSplitProducts(null)
                  dismissModal('splitAmountModal')
                  openModal('shoppingShippingDetailsModal')
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShoppingModals
