import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { Address, Salutation, Title, User } from '../../types'
import { countriesObject } from '../../utils/countries'
import Progress from '../loaders/Progress'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import * as userRoles from '../../constants/userRoles'
import { rolesObject } from '../../constants/userRoles'
import {
  updateUserById
} from '../../store/reducers/api/usersReducer'
import { phoneValidationPattern } from '../../constants/regexPatterns'
import { SALUTATION_MAX_LENGTH, TITLE_MAX_LENGTH } from '../../constants/maxAndMinFieldValues'

interface ProfileEditorProps {
  profile: User | null
  token: string | undefined
  isLoading: boolean
  isUpdating: boolean
  addresses: Address[]
  profileTitle?: string
}

const ProfileEditor = ({ profile, token, isLoading, isUpdating, addresses, profileTitle }: ProfileEditorProps) => {
  const salutations = useAppSelector((state) => state.apiSalutation.salutations)
  const titles = useAppSelector((state) => state.apiTitle.titles)

  const [initialUser, setInitialUser] = useState<Partial<User>>({
    salutation: profile?.salutation || '',
    title: profile?.title || '',
    firstName: profile?.firstName,
    lastName: profile?.lastName,
    username: profile?.username || ''
  })
  const [initialAddress, setInitialAddress] = useState<Address | null>(null)
  const [hasAddress, setHasAddress] = useState<'no' | 'yes'>('yes')

  const role = profile?.role || userRoles.USER
  const userId = profile?.id
  const countries = countriesObject.map(country => ({ value: country.country, label: country.country }))
  const types = [{ type: 'billing', label: 'Billing' }, { type: 'delivery', label: 'Delivery' }, { type: 'billingAndDelivery', label: 'Billing and Delivery' }]

  const dispatch = useAppDispatch()

  const addressSchema = object({
    country: string().required('Country is required').oneOf(countries.map(country => country.value)),
    city: string()
      .required('City is required')
      .max(64, 'City name is too long'),
    street: string()
      .required('Street name and House Number are required')
      .max(64, 'Street name and House Number are too long'),
    zip: string()
      .required('Zip is required')
      .max(24, 'Zip is too long'),
    phone: string()
      .nullable()
      .matches(phoneValidationPattern, 'Enter a valid phone number'),
    addressAddition: string()
      .nullable()
      .max(255, 'Address Addition is too long'),
    type: string().oneOf(types.map(type => type.type)).nullable()
  })

  const profileSchema = object({
    salutation: string().label('Salutation').nullable().max(SALUTATION_MAX_LENGTH),
    title: string().label('Title').nullable().max(TITLE_MAX_LENGTH),
    firstName: string()
      .required('First Name is required')
      .min(2, 'Enter a valid first name')
      .max(32, 'First Name is too long'),
    lastName: string()
      .required('Last Name is required')
      .min(2, 'Enter a valid last name')
      .max(32, 'Last Name is too long'),
    username: string()
      .nullable()
      .min(2, 'Username is too short')
      .max(32, 'Username is too long')
      .matches(/^\S+$/, 'Enter a valid username'),
    phone: string()
      .nullable()
      .matches(phoneValidationPattern, 'Enter a valid phone number'),
    country: string().oneOf(countries.map(country => country.value)),
    address: addressSchema,
    hasAddress: string().oneOf(['yes', 'no'])
  })

  const getAddressByType = (type: string | null) => {
    const address = addresses?.find((address) => address.type === type || address.type === null)
    return address || null
  }

  useEffect(() => {
    setInitialAddress(addresses?.[0] || null)
  }, [addresses])

  return (
    <div className="border border-gray rounded mb-3">
      {(isLoading || isUpdating) && <Progress marginBottom={false} />}
      <div className="p-3">
        <Formik
          validationSchema={hasAddress === 'yes' ? profileSchema : profileSchema.omit(['address'])}
          enableReinitialize
          initialValues={{
            salutation: initialUser?.salutation || '',
            title: initialUser?.title || '',
            firstName: initialUser?.firstName,
            lastName: initialUser?.lastName,
            username: initialUser?.username || '',
            address: {
              country: initialAddress?.country || '',
              city: initialAddress?.city || '',
              street: initialAddress?.street || '',
              zip: initialAddress?.zip || '',
              phone: initialAddress?.phone || '',
              addressAddition: initialAddress?.addressAddition || '',
              type: initialAddress?.type || ''
            },
            hasAddress
          }}
          onSubmit={(
            { salutation, title, firstName, lastName, username, address, hasAddress },
            actions
          ) => {
            const controller = new AbortController()
            const signal = controller.signal
            const user = {
              salutation,
              title,
              firstName,
              lastName,
              username: username || null,
              address: hasAddress === 'yes'
                ? {
                    ...address,
                    id: initialAddress?.id || null,
                    type: address.type || null,
                    affiliation: 'personal'
                  }
                : null
            }
            if (token && userId) {
              dispatch(
                updateUserById({
                  id: userId,
                  token,
                  user,
                  signal
                })
              )
            }
            actions.setSubmitting(false)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit}>
              {profileTitle && <h6 className="mb-4 text-uppercase">
                <i className="bi bi-pencil-square me-1"></i>{' '}
                Update Personal Information
              </h6>}
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="profileSalutation"
                      className="form-label"
                    >
                      Salutation
                    </label>
                    <div className="input-group has-validation">
                      <CreatableSelect
                        components={{
                          Input: (props) => (
                            <components.Input {...props} maxLength={SALUTATION_MAX_LENGTH} />
                          )
                        }}
                        inputId="profileSalutation"
                        aria-label="Salutation"
                        name="salutation"
                        options={salutations.map((salutation: Salutation) => ({
                          value: salutation.name,
                          label: salutation.name
                        }))}
                        value={
                          values.salutation
                            ? { value: values.salutation, label: values.salutation }
                            : null
                        }
                        onChange={(option) => {
                          const value = option?.value || ''
                          setFieldValue('salutation', value)
                          setInitialUser({ ...initialUser, salutation: value })
                        }}
                        onBlur={handleBlur}
                        className={`${touched.salutation && errors.salutation ? 'is-invalid' : ''} flex-grow-1`}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderColor: (errors.salutation && touched.salutation) ? '#dc3545' : provided.borderColor,
                            '&:hover': {
                              boxShadow: (errors.salutation && touched.salutation) ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                              borderColor: (errors.salutation && touched.salutation) ? '#dc3545' : '#86b7fe'
                            }
                          })
                        }}
                        placeholder="Select or type"
                        isClearable
                      />
                      {role === userRoles.ADMIN && (
                        <>
                          <button
                            type="button"
                            id="button-addon-salutation"
                            className="btn btn-outline-secondary btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#salutationEditModal"
                          >
                            <i className="bi bi-pencil"></i>
                          </button>
                          <button
                            type="button"
                            id="button-addon-salutation-2"
                            className="btn btn-outline-secondary btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#salutationModal"
                          >
                            <i className="bi bi-plus"></i>
                          </button>
                        </>
                      )}
                      <div id="validationSalutationFeedback" className="invalid-feedback">
                        {errors.salutation}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="profileTitle" className="form-label">
                      Title
                    </label>
                    <div className="input-group has-validation">
                      <CreatableSelect
                        components={{
                          Input: (props) => (
                            <components.Input {...props} maxLength={TITLE_MAX_LENGTH} />
                          )
                        }}
                        inputId="profileTitle"
                        aria-label="Title"
                        name="title"
                        options={titles.map((title: Title) => ({
                          value: title.name,
                          label: title.name
                        }))}
                        value={
                          values.title
                            ? { value: values.title, label: values.title }
                            : null
                        }
                        onChange={(option) => {
                          const value = option?.value || ''
                          setFieldValue('title', value)
                          setInitialUser({ ...initialUser, title: value })
                        }}
                        onBlur={handleBlur}
                        className={`${touched.title && errors.title ? 'is-invalid' : ''} flex-grow-1`}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderColor: (errors.title && touched.title) ? '#dc3545' : provided.borderColor,
                            '&:hover': {
                              boxShadow: (errors.title && touched.title) ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                              borderColor: (errors.title && touched.title) ? '#dc3545' : '#86b7fe'
                            }
                          })
                        }}
                        placeholder="Select or type"
                        isClearable
                      />
                      {role === userRoles.ADMIN && (
                        <>
                          <button
                            type="button"
                            id="button-addon-title"
                            className="btn btn-outline-secondary btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#titleEditModal"
                          >
                            <i className="bi bi-pencil"></i>
                          </button>
                          <button
                            type="button"
                            id="button-addon-title-2"
                            className="btn btn-outline-secondary btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#titleModal"
                          >
                            <i className="bi bi-plus"></i>
                          </button>
                        </>
                      )}
                      <div id="validationTitleFeedback" className="invalid-feedback">
                        {errors.title}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="profileFirstName"
                      className="form-label"
                    >
                      First Name
                    </label>
                    <input
                      onChange={(event) => {
                        setInitialUser({
                          ...initialUser,
                          firstName: event.target.value
                        })
                      }}
                      onBlur={handleBlur}
                      value={values.firstName}
                      type="text"
                      className={`form-control ${
                        touched.firstName &&
                        errors.firstName
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="profileFirstName"
                      name="firstName"
                      placeholder="Enter first name"
                    />
                    <div
                      id="validationFirstNameFeedback"
                      className="invalid-feedback"
                    >
                      {errors.firstName}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="profileLastName"
                      className="form-label"
                    >
                      Last Name
                    </label>
                    <input
                      onChange={(event) => {
                        setInitialUser({
                          ...initialUser,
                          lastName: event.target.value
                        })
                      }}
                      onBlur={handleBlur}
                      value={values.lastName}
                      type="text"
                      className={`form-control ${
                        touched.lastName &&
                        errors.lastName
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="profileLastName"
                      name="lastName"
                      placeholder="Enter last name"
                    />
                    <div
                      id="validationLastNameFeedback"
                      className="invalid-feedback"
                    >
                      {errors.lastName}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="profileEmail"
                      className="form-label"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={profile?.email || ''}
                      id="profileEmail"
                      name="email"
                      placeholder="Email"
                      autoComplete="off"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="profileRole" className="form-label">
                      Role
                    </label>
                    <input
                      value={rolesObject[profile?.role ?? userRoles.USER] || ''}
                      type="text"
                      className={'form-control'}
                      id="profileRole"
                      name="role"
                      placeholder="Role"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <h6 className="my-4 text-uppercase">
                <i className="bi bi-pencil-square me-1"></i>{' '}
                Update Personal Addresses
              </h6>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="profileStreet"
                      className="form-label"
                    >
                      Street and House Number
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.street}
                      type="text"
                      className={`form-control ${
                        touched.address?.street &&
                        errors.address?.street
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="profileStreet"
                      name="address.street"
                      placeholder="Enter street and House Number"
                    />
                    <div
                      id="validationProfileStreetFeedback"
                      className="invalid-feedback"
                    >
                      {errors.address?.street}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="profileZip" className="form-label">
                      Zip
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.zip}
                      type="text"
                      className={`form-control ${
                        touched.address?.zip && errors.address?.zip
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="profileZip"
                      name="address.zip"
                      placeholder="Enter zip"
                    />
                    <div
                      id="validationProfileZipFeedback"
                      className="invalid-feedback"
                    >
                      {errors.address?.zip}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="profileCity" className="form-label">
                      City
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      className={`form-control ${
                        touched.address?.city &&
                        errors.address?.city
                          ? 'is-invalid'
                          : ''
                      }`}
                      value={values.address.city}
                      id="profileCity"
                      name="address.city"
                      placeholder="Enter city"
                    />
                    <div
                      id="validationProfileCityFeedback"
                      className="invalid-feedback"
                    >
                      {errors.address?.city}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="profileAddressAddition"
                      className="form-label"
                    >
                      Address Addition
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.addressAddition}
                      type="text"
                      className={`form-control ${
                        touched.address?.addressAddition &&
                        errors.address?.addressAddition
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="profileAddressAddition"
                      name="address.addressAddition"
                      placeholder="Enter Address Addition"
                    />
                    <div
                      id="validationProfileAddressAdditionFeedback"
                      className="invalid-feedback"
                    >
                      {errors.address?.addressAddition}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="profilePhone" className="form-label">
                      Phone
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.phone}
                      type="text"
                      className={`form-control ${
                        touched.address?.phone &&
                        errors.address?.phone
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="profilePhone"
                      name="address.phone"
                      placeholder="Enter Phone Number"
                    />
                    <div
                      id="validationProfilePhoneFeedback"
                      className="invalid-feedback"
                    >
                      {errors.address?.phone}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="profileCountry"
                      className="form-label"
                    >
                      Country
                    </label>
                      <Select
                        className={`${
                          touched.address?.country &&
                          errors.address?.country
                            ? 'is-invalid'
                            : ''
                        }`}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderColor: (errors.address?.country && touched.address?.country) ? '#dc3545' : provided.borderColor,
                            '&:hover': {
                              borderColor: (errors.address?.country && touched.address?.country) ? '#dc3545' : provided.borderColor
                            }
                          })
                        }}
                        isClearable
                        inputId="profileCountry"
                        name="address.country"
                        aria-label="Country"
                        options={countries}
                        getOptionLabel={(country) => `${country.value}`}
                        getOptionValue={(country) => String(country.value)}
                        onChange={(selectedOption) => {
                          const selectedCountry = selectedOption?.value ?? ''
                          setFieldValue('address.country', selectedCountry)
                        }}
                        onBlur={handleBlur}
                        value={countries.find((country) => country.value === values.address.country)}
                      />
                    <div
                      id="validationProfileCountryFeedback"
                      className="invalid-feedback"
                    >
                      {errors.address?.country}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="profileType" className="form-label">
                      Type
                    </label>
                    <select
                      aria-label="Type"
                      onChange={(event) => {
                        handleChange(event)
                        setInitialAddress(getAddressByType(event.target.value || null))
                      }}
                      onBlur={handleBlur}
                      value={values.address.type}
                      className={`form-select ${
                        (errors.address?.type && touched.address?.type)
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="profileType"
                      name="address.type"
                      autoComplete="off"
                    >
                      <option value="">Select Type</option>
                      {types.map((type, index: number) => (
                        <option key={index} value={type.type}>{type.label}</option>
                      ))}
                    </select>
                    <div
                      id="validationProfileCountryFeedback"
                      className="invalid-feedback"
                    >
                      {errors.address?.type}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <p className="mt-2">
                      Include address on save
                    </p>
                    <div className={`form-check form-check-inline ${errors.hasAddress ? 'is-invalid' : ''}`}>
                      <input
                        className={`form-check-input ${errors.hasAddress ? 'is-invalid' : ''}`}
                        type="radio"
                        name="hasAddress"
                        id="inlineHasAddressRadioYes"
                        onChange={() => setHasAddress('yes')}
                        value="yes"
                        autoComplete="off"
                        checked={values.hasAddress === 'yes'}
                      />
                      <label className="form-check-label" htmlFor="inlineHasAddressRadioYes">Yes</label>
                    </div>
                    <div className={`form-check form-check-inline ${errors.hasAddress ? 'is-invalid' : ''}`}>
                      <input
                        className={`form-check-input ${errors.hasAddress ? 'is-invalid' : ''}`}
                        type="radio"
                        name="hasAddress"
                        id="inlineHasAddressRadioNo"
                        onChange={() => setHasAddress('no')}
                        value="no"
                        autoComplete="off"
                        checked={values.hasAddress === 'no'}
                      />
                      <label className="form-check-label" htmlFor="inlineHasAddressRadioNo">No</label>
                    </div>
                    <div
                      id="validationHasAddressFeedback"
                      className="invalid-feedback"
                    >
                      {errors.hasAddress}
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-primary mt-2"
                  disabled={
                    isSubmitting || isUpdating || isLoading
                  }
                >
                  <i className="bi bi-save"></i> Save
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default ProfileEditor
