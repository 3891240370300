import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProductCategoryService from '../../../services/api/ProductCategoryService'
import { ApiProductCategoryState } from '../../../types'
import {
  PRODUCT_CATEGORY_CREATION_SUCCESS_MESSAGE,
  PRODUCT_CATEGORY_DELETION_MESSAGE,
  PRODUCT_CATEGORY_TAG_CREATION_SUCCESS_MESSAGE,
  PRODUCT_CATEGORY_UPDATE_SUCCESS_MESSAGE,
  PRODUCT_CATEGORY_PRODUCTS_ADDITION_SUCCESS_MESSAGE,
  PRODUCT_CATEGORY_SORT_ORDER_UPDATE_SUCCESS_MESSAGE
} from '../../../constants/messages'

const initialState: ApiProductCategoryState = {
  isLoading: false,
  isLoadingProductCategorySortOrder: false,
  isLoadingProductCategoryTags: false,
  isLoadingProductCategoryProducts: false,
  error: null,
  productCategory: null,
  productCategories: [],
  productCategoryTag: null,
  productCategoryTags: [],
  productCategoryProduct: null,
  productCategoryProducts: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  productCategoryTagsMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  productCategoryProductsMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getProductCategories = createAsyncThunk('api/product-categories', async ({ token, perPage, page, signal, search, filterHidden }: { token: string, perPage: number, page: number, signal: AbortSignal, search?: string, silent?: boolean, filterHidden?:string }, { rejectWithValue }) => {
  try {
    const res = await ProductCategoryService.getProductCategories(token, perPage, page, signal, search, filterHidden)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

export const addProductCategory = createAsyncThunk('api/product-categories/add', async ({ token, productCategory, signal }: { token: string, productCategory: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductCategoryService.addProductCategory(token, productCategory, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getProductCategoryById = createAsyncThunk('api/product-categories/get', async ({ token, productCategoryId, signal }: { token: string, productCategoryId: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductCategoryService.getProductCategoryById(token, productCategoryId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const editProductCategoryById = createAsyncThunk('api/product-categories/edit', async ({ token, productCategoryId, productCategory, signal }: { token: string, productCategoryId: any, productCategory: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductCategoryService.editProductCategoryById(token, productCategoryId, productCategory, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateProductCategoriesSortOrder = createAsyncThunk('api/product-categories/update-sort-order', async ({ token, productCategories, signal }: { token: string, productCategories: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductCategoryService.updateProductCategoriesSortOrder(token, productCategories, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteProductCategoryById = createAsyncThunk('api/product-categories/delete', async ({ token, productCategoryId, signal }: { token: string, productCategoryId: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductCategoryService.deleteProductCategoryById(token, productCategoryId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addTagToProductCategory = createAsyncThunk('api/product-categories/productCategoryTags/post', async ({ token, productCategoryId, productCategoryTag, signal }: { token: string, productCategoryId: string, productCategoryTag: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductCategoryService.addTagToProductCategory(token, productCategoryId, productCategoryTag, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

export const getTagsOfProductCategory = createAsyncThunk('api/product-categories/productCategoryTags/get', async ({ token, perPage, page, productCategoryId, signal, search }: { token: string, perPage: number, page: number, productCategoryId: string, signal: AbortSignal, search?: string }, { rejectWithValue }) => {
  try {
    const res = await ProductCategoryService.getTagsOfProductCategory(token, perPage, page, productCategoryId, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

export const addProductToProductCategory = createAsyncThunk('api/product-categories/productCategoryProducts/post', async ({ token, productCategoryId, productCategory, signal }: { token: string, productCategoryId: string, productCategory: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductCategoryService.addProductsToProductCategory(token, productCategoryId, productCategory, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

export const getProductsOfProductCategory = createAsyncThunk('api/product-categories/productCategoryProducts/get', async ({ token, perPage, page, productCategoryId, signal, search }: { token: string, perPage: number, page: number, productCategoryId: string, signal: AbortSignal, search?: string }, { rejectWithValue }) => {
  try {
    const res = await ProductCategoryService.getProductsOfProductCategory(token, perPage, page, productCategoryId, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

const productCategorySlice = createSlice({
  name: 'api/product-category',
  initialState,
  reducers: {
    resetProductCategoryError: (state) => {
      state.error = null
    },
    resetProductCategoryMessage: (state) => {
      state.message = null
    },
    resetProductCategoryData: (state) => {
      state.error = null
      state.productCategory = null
      state.productCategories = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductCategories.pending, (state, action) => {
        state.productCategories = action.meta.arg.silent ? state.productCategories : []
        state.isLoading = !action.meta.arg.silent
        state.isLoadingProductCategorySortOrder = !!action.meta.arg.silent
      })
      .addCase(getProductCategories.fulfilled, (state, action) => {
        state.isLoading = false
        state.isLoadingProductCategorySortOrder = false
        state.productCategories = action.payload.productCategories
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getProductCategories.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingProductCategorySortOrder = false
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addProductCategory.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addProductCategory.fulfilled, (state, action) => {
        state.isLoading = false
        state.productCategory = action.payload.productCategory
        state.message = PRODUCT_CATEGORY_CREATION_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(addProductCategory.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getProductCategoryById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getProductCategoryById.fulfilled, (state, action) => {
        state.isLoading = false
        state.productCategory = action.payload.productCategory
        state.error = null
      })
      .addCase(getProductCategoryById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.message = null
        state.error = action.payload
      })
    builder
      .addCase(editProductCategoryById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editProductCategoryById.fulfilled, (state, action) => {
        state.isLoading = false
        state.productCategory = action.payload.productCategory
        state.message = PRODUCT_CATEGORY_UPDATE_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(editProductCategoryById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.message = null
        state.error = action.payload
      })
    builder
      .addCase(updateProductCategoriesSortOrder.pending, (state) => {
        state.isLoadingProductCategorySortOrder = true
      })
      .addCase(updateProductCategoriesSortOrder.fulfilled, (state, action) => {
        state.isLoadingProductCategorySortOrder = false
        state.message = PRODUCT_CATEGORY_SORT_ORDER_UPDATE_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(updateProductCategoriesSortOrder.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingProductCategorySortOrder = false
        }
        state.message = null
        state.error = action.payload
      })
    builder
      .addCase(deleteProductCategoryById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteProductCategoryById.fulfilled, (state) => {
        state.isLoading = false
        state.error = null
        state.message = PRODUCT_CATEGORY_DELETION_MESSAGE
      })
      .addCase(deleteProductCategoryById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.message = null
        state.error = action.payload
      })
    builder
      .addCase(addTagToProductCategory.pending, (state) => {
        state.isLoadingProductCategoryTags = true
      })
      .addCase(addTagToProductCategory.fulfilled, (state, action) => {
        state.isLoadingProductCategoryTags = false
        state.productCategoryTag = action.payload.productCategoryTag
        state.message = PRODUCT_CATEGORY_TAG_CREATION_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(addTagToProductCategory.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingProductCategoryTags = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getTagsOfProductCategory.pending, (state) => {
        state.productCategoryTags = []
        state.isLoadingProductCategoryTags = true
      })
      .addCase(getTagsOfProductCategory.fulfilled, (state, action) => {
        state.isLoadingProductCategoryTags = false
        state.productCategoryTags = action.payload.productCategoryTags
        state.productCategoryTagsMetadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getTagsOfProductCategory.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingProductCategoryTags = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addProductToProductCategory.pending, (state) => {
        state.isLoadingProductCategoryProducts = true
      })
      .addCase(addProductToProductCategory.fulfilled, (state, action) => {
        state.isLoadingProductCategoryProducts = false
        state.message = PRODUCT_CATEGORY_PRODUCTS_ADDITION_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(addProductToProductCategory.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingProductCategoryProducts = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getProductsOfProductCategory.pending, (state) => {
        state.productCategoryProducts = []
        state.isLoadingProductCategoryProducts = true
      })
      .addCase(getProductsOfProductCategory.fulfilled, (state, action) => {
        state.isLoadingProductCategoryProducts = false
        state.productCategoryProducts = action.payload.productCategoryProducts
        state.productCategoryProductsMetadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getProductsOfProductCategory.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingProductCategoryProducts = false
        }
        state.error = action.payload
      })
  }
})

export const { resetProductCategoryError, resetProductCategoryMessage, resetProductCategoryData } = productCategorySlice.actions

const { reducer } = productCategorySlice
export default reducer
