import axios from 'axios'
import { Product, ProductCustomisation, ProductGraduatedPrice, ProductStockNotification, ProductUpdate } from '../../types'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  getAllProducts (token: string, perPage: number, page: number, search: string, signal: AbortSignal, orderBy: string = '', filter: string = '') {
    const config = {
      params: {
        search,
        limit: perPage,
        page
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/products?${orderBy}&${filter}`, config)
  },
  getProductById (token: string, productId: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/products/${productId}`, config)
  },
  getAllCatalogueProducts (token: string, perPage: number, page: number, search: string, signal: AbortSignal, orderBy: string = '', filter: string = '') {
    const config = {
      params: {
        search,
        limit: perPage,
        page
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/products/catalogue?${orderBy}&${filter}`, config)
  },
  getCatalogueProductById (token: string, productId: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/products/${productId}/catalogue`, config)
  },
  getSimilarCatalogueProducts (token: string, productId: string, perPage: number, page: number, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/products/${productId}/catalogue/similar`, config)
  },
  addProduct (token: string, product: Partial<Product>, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/products', { product }, config)
  },
  editProductById (token: string, productId: string, product: Partial<ProductUpdate>, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/products/${productId}`, { product }, config)
  },
  updateProductCompanyById (token: string, productId: string, product: { companyId: string | null }, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch(`/products/${productId}/company`, { product }, config)
  },
  deleteProductById (token: string, productId: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/products/${productId}`, config)
  },
  getProductStock (token: string, productId: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/products/${productId}/stocks`, config)
  },
  getProductOutbounds (token: string, perPage: number, page: number, productId: string, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/products/${productId}/outbounds`, config)
  },
  getProductInbounds (token: string, perPage: number, page: number, productId: string, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/products/${productId}/inbounds`, config)
  },
  getProductCategories (token: string, perPage: number, page: number, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/products-categories', config)
  },
  addCategoryTagToProduct (token: string, productId: string, productTag: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/products/${productId}/tags`, { productTag }, config)
  },
  addGraduatedPriceToProduct (token: string, productId: string, productGraduatedPrice: Pick<ProductGraduatedPrice, 'price' | 'firstUnit' | 'lastUnit'>, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/products/${productId}/graduated-prices`, { productGraduatedPrice }, config)
  },
  getProductVariations (token: string, perPage: number, page: number, productId: string, signal: AbortSignal, filter?: string) {
    const config = {
      params: {
        limit: perPage,
        page
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/products/${productId}/catalogue/variations?${filter}`, config)
  },
  getAllProductStockNotifications ({ token, perPage, page, productId, signal }: { token: string, perPage: number, page: number, productId: string, signal:AbortSignal}) {
    const config = {
      params: {
        limit: perPage,
        page
      },
      signal

    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/products/${productId}/stock-notifications`, config)
  },
  createProductStockNotification ({ token, productId, signal, productStockNotification }: { token: string, productId: string, productStockNotification: ProductStockNotification, signal:AbortSignal}) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/products/${productId}/stock-notifications`, { productStockNotification }, config)
  },
  getProductCustomisations (token: string, perPage: number, page: number, productId: string, signal: AbortSignal) {
    const config = {
      signal,
      params: {
        limit: perPage,
        page
      }
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/products/${productId}/product-customisations`, config)
  },
  createProductCustomisations (token: string, productId:string, productCustomisation: ProductCustomisation, signal:AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/products/${productId}/product-customisations`, { productCustomisation }, config)
  }
}
