import axios from 'axios'
import { ProductStockNotification } from '../../types'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  deleteProductStockNotification ({ token, productStockNotificationId, signal }: { token: string, productStockNotificationId: string, signal: AbortSignal }) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/product-stock-notifications/${productStockNotificationId}`, config)
  },
  updateProductStockNotification ({ token, productStockNotificationId, productStockNotification, signal }: { token: string, productStockNotificationId: string, productStockNotification: ProductStockNotification, signal: AbortSignal }) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/product-stock-notifications/${productStockNotificationId}`, { productStockNotification }, config)
  }
}
