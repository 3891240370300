import React, { useEffect } from 'react'
import { Link, Navigate, useSearchParams } from 'react-router-dom'
import Alert from '../../../components/Alert'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { verify } from '../../../store/reducers/authReducer'
import { logoutCurrentUser } from '../../../store/reducers/api/authReducer'

const Verify = () => {
  const dispatch = useAppDispatch()
  const isSuccess = useAppSelector((state) => state.auth.success)
  const isLoading = useAppSelector((state) => state.auth.isLoading)
  const error = useAppSelector((state) => state.auth.error)

  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token) {
      dispatch(verify({ token, signal }))
    }
    return () => {
      controller.abort()
    }
  }, [token, dispatch])

  useEffect(() => {
    if (isSuccess) {
      dispatch(logoutCurrentUser())
    }
  }, [isSuccess, dispatch])

  if (isSuccess) {
    return <Navigate to="/login" />
  }

  return (
    <div className="container h-100 p-4">
      {error?.errors &&
        (<>
          <Alert type="danger" message={`${error?.errors?.message || 'Verification failed'}. Kindly navigate to your profile page to continue and request a new verification link or code.`} />
          <Link to="/profile">
            <i className="fas fa-arrow-left me-1"></i>
            Back to Profile
          </Link>
        </>)
      }
      {isLoading && (
        <div className="border border-light p-3 mb-4">
          <div className="d-flex align-items-center justify-content-center" style={{ height: '350px' }}>
            <div className="col-lg-5">
              <div className="card shadow-lg border-0 rounded-lg mt-5">
                <div className="card-header">
                  <h3 className="text-center font-weight-light my-4">
                    Verifying
                  </h3>
                </div>
                <div className="card-body">
                  <div className="d-grid gap-2 mx-auto">
                    <div className="text-center">
                      <div className="spinner-border text-danger" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Verify
