import React, { useEffect, useState } from 'react'
import { bool, object, string } from 'yup'
import { Formik } from 'formik'
import Select from 'react-select'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { AccessPermission, AllowedCompanyModule, Module, Permission, Role } from '../../types'
import { resetCompanyError } from '../../store/reducers/api/companyReducer'
import { editableRoles } from '../../constants/userRoles'
import { modulesObject } from '../../constants/appModules'
import { READ, READWRITE, permissionsObject } from '../../constants/permissions'
import * as userRoles from '../../constants/userRoles'

type AccessPermissionEditorProps = { id: string, initialAccessPermission: Partial<AccessPermission>, save: Function, isEdit: boolean }

const AccessPermissionEditor = ({ id, initialAccessPermission, save, isEdit }: AccessPermissionEditorProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const isLoading = useAppSelector((state) => state.apiCompany.isLoading)
  const companies = useAppSelector((state) => state.apiCompany.companies)
  const appDefaultAccessPermissions = useAppSelector((state) => state.apiAccessPermission.defaultAccessPermissions)

  const [defaultAccessPermissions, setDefaultAccessPermissions] = useState<AccessPermission[]>([])
  const [allowedCompanyModules, setAllowedCompanyModules] = useState<Array<AllowedCompanyModule>>([])

  const token = currentUser?.token
  const role = currentUser?.role

  const dispatch = useAppDispatch()

  const accessPermissionsEditableRoles = editableRoles.filter((editableRole) => editableRole.value !== userRoles.COMPANYADMINISTRATOR)

  const accessPermissionSchema = object({
    name: string().label('Name').required(),
    role: string().label('Role').required('Role is required').oneOf(accessPermissionsEditableRoles.map(role => role.value)),
    permission: string().label('Permission').required('Permission is required').when('module', {
      is: (module: string) => (allowedCompanyModules.find(allowedCompanyModule => allowedCompanyModule.module === module)?.permission === READ),
      then: string().oneOf([READ]),
      otherwise: string().oneOf([READ, READWRITE])
    }),
    module: string().required('Module is required')
      .oneOf(allowedCompanyModules.map(allowedCompanyModule => allowedCompanyModule.module), 'Select a module'),
    isEnabled: bool().label('Is Enabled').required().typeError('Is Enabled is required'),
    companyId: string().label('Company').when([], {
      is: () => currentUser?.role === userRoles.ADMIN,
      then: string().required()
    }),
    override: string().label('Override default access permissions')
  })

  const saveAccessPermission = (id: string, accessPermission: Partial<AccessPermission>, signal: AbortSignal) => {
    dispatch(save({ id, token, accessPermission, signal }))
  }

  useEffect(() => {
    if (profile && profile.company) {
      setDefaultAccessPermissions(profile.company.defaultAccessPermissions)
    } else {
      setDefaultAccessPermissions(appDefaultAccessPermissions)
    }
  }, [profile, appDefaultAccessPermissions])

  useEffect(() => {
    let accessPermissions

    if (profile && profile.company) {
      accessPermissions = defaultAccessPermissions
    } else {
      accessPermissions = appDefaultAccessPermissions
    }
    const allowedModules: AllowedCompanyModule[] = accessPermissions
      .filter((defaultAccessPermission: AccessPermission) => defaultAccessPermission.role === userRoles.COMPANYADMINISTRATOR)
      .map(defaultAccessPermission => ({ name: modulesObject[defaultAccessPermission.module as Module], module: defaultAccessPermission.module, permission: defaultAccessPermission.permission }))

    setAllowedCompanyModules(allowedModules)
  }, [defaultAccessPermissions, appDefaultAccessPermissions])

  return (
    <div>
      <Formik
        validationSchema={accessPermissionSchema}
        enableReinitialize
        initialValues={{
          ...initialAccessPermission,
          companyId: initialAccessPermission.company?.id,
          override: 'no'
        }}
        onSubmit={(
          { name, role, permission, module, isEnabled, companyId, override },
          actions
        ) => {
          const controller = new AbortController()
          const signal = controller.signal

          const accessPermission: any = {
            name, role, permission, module, isEnabled, override: override === 'yes'
          }
          if (!isEdit && currentUser?.role === userRoles.ADMIN) {
            accessPermission.companyId = companyId
          }

          if (token && (id || companyId)) {
            saveAccessPermission(id, accessPermission, signal)
          }
          actions.setSubmitting(false)
          dispatch(resetCompanyError())
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            {role === userRoles.ADMIN && (<div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="accessPermissionCompany"
                    className="form-label"
                  >
                    Company
                  </label>
                  <Select
                    className={`${
                      touched.companyId &&
                      errors.companyId
                        ? 'is-invalid'
                        : ''
                    }`}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: (errors.companyId && touched.companyId) ? '#dc3545' : provided.borderColor,
                        '&:hover': {
                          borderColor: (errors.companyId && touched.companyId) ? '#dc3545' : provided.borderColor
                        }
                      })
                    }}
                    isClearable
                    inputId="accessPermissionCompany"
                    name="companyId"
                    aria-label="Company"
                    options={companies}
                    getOptionLabel={(company) => `${company.name} - ${company.domain ?? 'Domain not set'}`}
                    getOptionValue={(company) => String(company.id)}
                    onChange={(selectedOption) => {
                      const selectedCompanyId = selectedOption?.id ?? ''
                      setFieldValue('companyId', selectedCompanyId)
                    }}
                    onBlur={handleBlur}
                    isLoading={isLoading}
                    isDisabled={isEdit}
                    value={companies.find((company) => company.id === values.companyId)}
                  />
                  <div
                    id="validationAccessPermissionCompanyFeedback"
                    className="invalid-feedback"
                  >
                    {errors.companyId}
                  </div>
                </div>
              </div>
            </div>)}
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="accessPermissionName"
                    className="form-label"
                  >
                    Name <span className="small text-primary">(autogenerated)</span>
                  </label>
                  <input
                    readOnly
                    onBlur={handleBlur}
                    value={values.name}
                    type="text"
                    className={`form-control ${
                      (touched.name &&
                      errors.name)
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="accessPermissionName"
                    name="name"
                    placeholder=""
                    maxLength={64}
                  />
                  <div
                    id="validationAccessPermissionNameFeedback"
                    className="invalid-feedback"
                  >
                    {errors.name}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="accessPermissionRole"
                    className="form-label"
                  >
                    User Role
                  </label>
                  <select
                    aria-label="User Role"
                    onChange={(event) => {
                      handleChange(event)
                      const { value } = event.target
                      setFieldValue('name', `${userRoles.rolesObject[value as Role]} ${permissionsObject[values.permission as Permission]} ${modulesObject[values.module as Module]}`)
                    }}
                    onBlur={handleBlur}
                    value={values.role}
                    className={`form-select ${
                      touched.role &&
                      errors.role
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="accessPermissionRole"
                    name="role"
                  >
                    <option value={''}>Select Role</option>
                    {
                      accessPermissionsEditableRoles.map((role) => (<option key={role.text} value={role.value}>{role.text}</option>))
                    }
                  </select>
                  <div
                    id="validationAccessPermissionRoleFeedback"
                    className="invalid-feedback"
                  >
                    {errors.role}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="accessPermission"
                    className="form-label"
                  >
                    Permission
                  </label>
                  <select
                    aria-label="Permission"
                    onChange={(event) => {
                      handleChange(event)
                      const { value } = event.target
                      setFieldValue('name', `${userRoles.rolesObject[values.role as Role]} ${permissionsObject[value as Permission]} ${modulesObject[values.module as Module]}`)
                    }}
                    onBlur={handleBlur}
                    value={values.permission}
                    className={`form-select ${
                      touched.permission &&
                      errors.permission
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="accessPermission"
                    name="permission"
                  >
                    <option value={''}>Select Permission</option>
                    <option value={READ}>Read</option>
                    <option
                      value={READWRITE}
                      disabled={allowedCompanyModules.find(allowedCompanyModule => allowedCompanyModule.module === values.module)?.permission === READ}
                    >
                      Read and Write
                    </option>
                  </select>
                  <div
                    id="validationAccessPermissionRoleFeedback"
                    className="invalid-feedback"
                  >
                    {errors.permission}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="accessPermissionModule"
                    className="form-label"
                  >
                    Module
                  </label>
                  <select
                    aria-label="Module"
                    onChange={(event) => {
                      handleChange(event)
                      const { value } = event.target
                      setFieldValue('name', `${userRoles.rolesObject[values.role as Role]} ${permissionsObject[values.permission as Permission]} ${modulesObject[value as Module]}`)
                      if (allowedCompanyModules.find(allowedCompanyModule => allowedCompanyModule.module === value)?.permission === READ) {
                        setFieldValue('permission', 'read')
                        setFieldValue('name', `${userRoles.rolesObject[values.role as Role]} ${permissionsObject.read} ${modulesObject[value as Module]}`)
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.module}
                    className={`form-select ${
                      touched.module &&
                      errors.module
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="accessPermissionModule"
                    name="module"
                  >
                    <option value={''}>Select Module</option>
                    {
                      allowedCompanyModules.map((allowedCompanyModule) => (<option key={allowedCompanyModule.module} value={allowedCompanyModule.module}>{allowedCompanyModule.name}</option>))
                    }
                  </select>
                  <div
                    id="validationAccessPermissionModuleFeedback"
                    className="invalid-feedback"
                  >
                    {errors.module}
                  </div>
                  <div className="text-primary small fw-semibold">
                    {
                      defaultAccessPermissions.find(defaultAccessPermission => defaultAccessPermission.role === values.role && defaultAccessPermission.module === values.module) &&
                      `Set 'Override default role permission' to 'Yes' below to override the default permission of a ${userRoles.rolesObject[values.role as Role]} for ${modulesObject[values.module as Module]}`
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="accessPermissionIsEnabled"
                    className="form-label"
                  >
                    Is Enabled
                  </label>
                  <select
                    aria-label="Access Permission Is Enabled"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={String(values.isEnabled)}
                    className={`form-select ${
                      touched.isEnabled &&
                      errors.isEnabled
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="accessPermissionIsEnabled"
                    name="isEnabled"
                  >
                    <option value={undefined}>Select Is Enabled</option>
                    <option value={'true'}>Yes</option>
                    <option value={'false'}>No</option>
                  </select>
                  <div
                    id="validationAccessPermissionIsEnabledFeedback"
                    className="invalid-feedback"
                  >
                    {errors.isEnabled}
                  </div>
                </div>
              </div>
            </div>

            {(defaultAccessPermissions.find(defaultAccessPermission => defaultAccessPermission.role === values.role && defaultAccessPermission.module === values.module) || role === userRoles.ADMIN) &&
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="accessPermissionIsEnabled"
                    className="form-label fw-bold text-primary"
                  >
                    Override default role permission
                  </label>
                  <select
                    aria-label="Override Default Role Permission"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.override}
                    className={`form-select ${
                      touched.override &&
                      errors.override
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="override"
                    name="override"
                  >
                    <option value={undefined}>Override default role permission</option>
                    <option value={'yes'}>Yes</option>
                    <option value={'no'}>No</option>
                  </select>
                  <div
                    id="validationAccessPermissionOverrideFeedback"
                    className="invalid-feedback"
                  >
                    {errors.override}
                  </div>
                </div>
              </div>
            </div>}

            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={
                  isSubmitting || isLoading
                }
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default AccessPermissionEditor
