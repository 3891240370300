import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import PrivacyRuleService from '../../../services/api/PrivacyRuleService'
import { ApiPrivacyRule, PrivacyRule } from '../../../types'
import { PRIVACY_RULE_DELETE_MESSAGE, PRIVACY_RULE_UPDATE_MESSAGE } from '../../../constants/messages'

const initialState: ApiPrivacyRule = {
  isLoading: false,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  message: null,
  error: null,
  privacyRule: null,
  privacyRules: []
}

export const getPrivacyRules = createAsyncThunk('privacyRules/fetchAll', async ({ token, perPage, page, signal, search }: { token: string; perPage: number; page: number; signal: AbortSignal; search?: string }, { rejectWithValue }) => {
  try {
    const res = await PrivacyRuleService.getPrivacyRules(token, perPage, page, signal, search)
    return res.data
  } catch (error: any) {
    return rejectWithValue(error?.response?.data || error.message)
  }
}
)

export const getPrivacyRuleById = createAsyncThunk(
  'privacyRules/fetchById',
  async ({ token, privacyId, signal }: { token: string; privacyId: string; signal: AbortSignal }, { rejectWithValue }) => {
    try {
      const res = await PrivacyRuleService.getPrivacyRuleById(privacyId, token, signal)
      return res.data
    } catch (error: any) {
      return rejectWithValue(error?.response?.data || error.message)
    }
  }
)

export const addPrivacyRule = createAsyncThunk('privacyRules/add', async ({ token, privacyRule, signal }: { token: string; privacyRule: PrivacyRule; signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await PrivacyRuleService.addPrivacyRule(token, privacyRule, signal)
    return res.data
  } catch (error: any) {
    return rejectWithValue(error?.response?.data || error.message)
  }
}
)

export const deletePrivacyRule = createAsyncThunk('privacyRules/delete', async ({ token, privacyId, signal }: { token: string; privacyId: string; signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await PrivacyRuleService.deletePrivacyRule(privacyId, token, signal)
    return res.data
  } catch (error: any) {
    return rejectWithValue(error?.response?.data || error.message)
  }
}
)

export const updatePrivacyRule = createAsyncThunk('privacyRules/update', async ({ token, privacyId, privacyRule, signal }: { token: string, privacyId: string, privacyRule: Partial<PrivacyRule>, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await PrivacyRuleService.updatePrivacyRule(privacyId, token, { role: privacyRule.role, module: privacyRule.module, isEnabled: privacyRule.isEnabled }, signal)
    return res.data
  } catch (error: any) {
    return rejectWithValue(error?.response?.data || error.message)
  }
}
)

const privacyRuleSlice = createSlice({
  name: 'privacyRules',
  initialState,
  reducers: {
    resetPrivacyRuleError: (state) => {
      state.error = null
    },
    resetPrivacyRuleMessage: (state) => {
      state.message = null
    },
    resetPrivacyRuleData: (state) => {
      state.error = null
      state.message = null
      state.privacyRules = []
      state.privacyRule = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrivacyRules.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPrivacyRules.fulfilled, (state, action) => {
        state.isLoading = false
        state.privacyRules = action.payload.privacyRules
        state.metadata = action.payload.meta
      })
      .addCase(getPrivacyRules.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })

    builder
      .addCase(getPrivacyRuleById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPrivacyRuleById.fulfilled, (state, action) => {
        state.isLoading = false
        state.privacyRule = action.payload.privacyRule
      })
      .addCase(getPrivacyRuleById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })

    builder
      .addCase(updatePrivacyRule.pending, (state) => {
        state.message = null
        state.error = null
        state.isLoading = true
      })
      .addCase(updatePrivacyRule.fulfilled, (state, action) => {
        state.isLoading = false
        state.privacyRule = action.payload.privacyRule
        state.message = PRIVACY_RULE_UPDATE_MESSAGE
      })
      .addCase(updatePrivacyRule.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })

    builder
      .addCase(deletePrivacyRule.pending, (state) => {
        state.message = null
        state.isLoading = true
      })
      .addCase(deletePrivacyRule.fulfilled, (state, action) => {
        state.isLoading = false
        state.privacyRules = state.privacyRules.filter((rule) => rule.id !== action.payload.privacyId)
        state.message = PRIVACY_RULE_DELETE_MESSAGE
      })
      .addCase(deletePrivacyRule.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetPrivacyRuleError, resetPrivacyRuleMessage, resetPrivacyRuleData } = privacyRuleSlice.actions

const { reducer } = privacyRuleSlice
export default reducer
