import axios from 'axios'
import { CreateProductCustomisationChatParams, ProductCustomisationChatAttachment } from '../../types'

const baseURL = process.env.REACT_APP_API_URL

const apiClient = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

interface ProductCustomisationChatResponse {
  id: string
  productCustomisationId: string
  message: string
  attachment: ProductCustomisationChatAttachment[]
  createdAt: string
  updatedAt: string
}

export default {
  createProductCustomisationChat ({
    token,
    productCustomisationId,
    productCustomisationChat
  }: CreateProductCustomisationChatParams) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`

    return apiClient.post(
      `/product-customisations/${productCustomisationId}/chats`,
      { productCustomisationChat }
    )
  },

  getProductCustomisationChats ({
    token,
    productCustomisationId
  }: { token: string; productCustomisationId: string; }) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`

    return apiClient.get<ProductCustomisationChatResponse[]>(
      `/product-customisations/${productCustomisationId}/chats`
    )
  },

  updateProductCustomisation ({
    token,
    productCustomisationId,
    productCustomisationData
  }: {
    token: string
    productCustomisationId: string
    productCustomisationData: any
  }) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`

    return apiClient.put(
      `/product-customisations/${productCustomisationId}`,
      { productCustomisation: productCustomisationData }
    )
  }
}
