export const UNAUTHORIZED_401_MESSAGE = 'Request failed with status code 401'
export const FORBIDDEN_403_MESSAGE = 'Request failed with status code 403'
export const BAD_REQUEST_400_MESSAGE = 'Request failed with status code 400'
export const VERIFICATION_CODE_CONFIRMATION = 'A verification code has been sent to your email'
export const EMAIL_VERIFICATION_CONFIRMATION = 'Email verified successfully'
export const CAMPAIGN_DELETION_MESSAGE = 'Campaign deleted successfully'
export const SALUTATION_DELETION_MESSAGE = 'Salutation deleted successfully'
export const TITLE_DELETION_MESSAGE = 'Title deleted successfully'
export const SHIPPING_METHOD_DELETION_MESSAGE = 'Shipping method deleted successfully'
export const COST_CENTER_DELETION_MESSAGE = 'Cost center deleted successfully'
export const ACCESS_PERMISSION_DELETION_MESSAGE = 'Access Permission deleted successfully'
export const PRODUCT_DELETION_MESSAGE = 'Product deleted successfully'
export const PRODUCT_UPDATE_MESSAGE = 'Product updated successfully'
export const COMPANY_HEADER_UPLOAD_SUCCESS_MESSAGE = 'Company header updated successfully'
export const PRODUCT_COMPANY_UPDATE_MESSAGE = 'Product company has been updated successfully'
export const BUNDLE_DELETION_MESSAGE = 'Bundle deleted successfully'
export const RECIPIENT_DELETION_MESSAGE = 'Recipient deleted successfully'
export const CARD_TEMPLATE_DELETION_MESSAGE = 'Card Template deleted successfully'
export const PENDING_ORDER_DELETION_MESSAGE = 'Pending order deleted successfully'
export const PENDING_ORDER_UPDATE_MESSAGE = 'Pending order updated successfully'
export const ORDER_DUPLICATE_MESSAGE = 'Order duplicated successfully and will appear in the pending orders section shortly'
export const ORDER_CREATION_SUCCESS_MESSAGE = 'Order created successfully and will appear in the pending orders section shortly'
export const COST_CENTER_UPDATE_SUCCESS_MESSAGE = 'Cost center updated successfully'
export const ACCESS_PERMISSION_UPDATE_SUCCESS_MESSAGE = 'Access permission updated successfully'
export const RECIPIENT_UPDATE_SUCCESS_MESSAGE = 'Recipient updated successfully'
export const ITEM_DELETION_MESSAGE = 'Item deleted successfully'
export const EMAIL_UNVERIFIED_MESSAGE = 'Kindly verify your email address'
export const COMPANY_DOMAIN_UNVERIFIED_MESSAGE = 'Kindly verify your company domain'
export const PASSWORD_CHANGE_CONFIRMATION = 'Your password was updated. You will be redirected to log in.'
export const PICTURE_POSTED_SUCCESS_MESSAGE = 'Picture successfully added'
export const PICTURE_DELETION_MESSAGE = 'Picture deleted successfully'
export const USER_UPDATE_SUCCESS_MESSAGE = 'User updated successfully'
export const ADDRESS_UPDATE_SUCCESS_MESSAGE = 'Address updated successfully'
export const USER_ROLE_UPDATE_SUCCESS_MESSAGE = 'User role updated successfully'
export const USER_EMAIL_VERIFICATION_STATUS_UPDATE_SUCCESS_MESSAGE = 'User email verification status updated successfully'
export const USER_ACTIVE_STATE_UPDATE_SUCCESS_MESSAGE = 'User active state updated successfully'
export const USER_CREATION_SUCCESS_MESSAGE = 'User created successfully'
export const USER_DELETION_SUCCESS_MESSAGE = 'The user was deleted successfully'
export const ADDRESS_ADDITION_SUCCESS_MESSAGE = 'Address added successfully'
export const COMPANY_CREATION_SUCCESS_MESSAGE = 'Company created successfully'
export const COMPANY_DELETION_SUCCESS_MESSAGE = 'Company deleted successfully'
export const COMPANY_UPDATE_SUCCESS_MESSAGE = 'Company updated successfully'
export const CAMPAIGN_CREATION_SUCCESS_MESSAGE = 'Campaign created successfully'
export const COST_CENTER_CREATION_SUCCESS_MESSAGE = 'Cost center created successfully'
export const LEGAL_TEXT_CREATION_SUCCESS_MESSAGE = 'Legal text created successfully'
export const PRODUCT_CREATION_SUCCESS_MESSAGE = 'Product created successfully'
export const ROLE_UPDATE_SUCCESS_MESSAGE = 'Role updated successfully'
export const RECIPIENT_ADDITION_SUCCESS_MESSAGE = 'Recipient added successfully'
export const CARD_TEMPLATE_CREATION_SUCCESS_MESSAGE = 'Card Template created successfully'
export const CARD_TEMPLATE_UPDATE_SUCCESS_MESSAGE = 'Card Template updated successfully'
export const CAMPAIGN_UPDATE_SUCCESS_MESSAGE = 'Campaign updated successfully'
export const BUNDLE_CREATION_SUCCESS_MESSAGE = 'Bundle created successfully'
export const PASSWORD_UPDATE_SUCCESS_MESSAGE = 'Password updated successfully'
export const COMPANY_EMPLOYEE_UPDATE_SUCCESS_MESSAGE = 'Company employees updated successfully'
export const COMPANY_DOMAIN_VERIFICATION_STATUS_SUCCESS_MESSAGE = 'Company domain verification status updated successfully'
export const COMPANY_NOT_FOUND = 'Company not found'
export const INVALID_INVITATION_LINK = 'The invitation link is invalid.'
export const ACCESS_PERMISSION_CREATION_SUCCESS_MESSAGE = 'Access permission created successfully'
export const CARD_SETTING_CREATION_SUCCESS_MESSAGE = 'Card Settings edited successfully'
export const FULFILLMENT_ORDERS_CANCELLED_SUCCESS_MESSAGE = 'Fulfillment order(s) cancelled successfully'
export const ARTICLE_CATEGORY_CREATION_SUCCESS_MESSAGE = 'Article category created successfully'
export const ARTICLE_CATEGORY_UPDATE_SUCCESS_MESSAGE = 'Article category updated successfully'
export const VOLUME_BASED_SALE_CREATION_SUCCESS_MESSAGE = 'Volume based sale created successfully'
export const VOLUME_BASED_SALE_UPDATE_SUCCESS_MESSAGE = 'Volume based sale updated successfully'
export const CAMPAIGN_QUOTA_EXCEEDED_MESSAGE = 'Campaign quota exceeded'
export const CAMPAIGN_INACTIVE_MESSAGE = 'Campaign is inactive. Order creation has been disabled'
export const SHIPPING_DESTINATION_CREATION_SUCCESS_MESSAGE = 'Shipping destinations created successfully'
export const SHIPPING_DESTINATION_UPDATE_SUCCESS_MESSAGE = 'Shipping destinations updated successfully'
export const SHIPPING_DESTINATION_DELETION_MESSAGE = 'Shipping destination deleted successfully'
export const CAMPAIGN_ORDER_LIMIT_CREATION_SUCCESS_MESSAGE = 'Campaign order limit created successfully'
export const ORDER_LIMIT_DELETION_MESSAGE = 'Order limit deleted successfully'
export const USER_PASSWORD_RESET_SUCCESS_MESSAGE = 'User password reset successfully'
export const EMAIL_TEMPLATE_SUCCESS_MESSAGE = 'Email template created successfully'
export const EMAIL_TEMPLATE_UPDATE_SUCCESS_MESSAGE = 'Email template updated successfully'
export const COMPANY_ASSIGNMENT_SUCCESS_MESSAGE = 'Company assignment completed successfully'
export const INVITE_TOKEN_REGENERATION_SUCCESS_MESSAGE = 'The company invite link and code have been regenerated successfully'
export const INVITE_DOMAIN_CHECK_SUCCESS_MESSAGE = 'The company invite domain check has been updated successfully'
export const CAMPAIGN_ADDRESS_CREATION_SUCCESS_MESSAGE = 'Campaign address created successfully'
export const CAMPAIGN_ADDRESS_DELETION_MESSAGE = 'Campaign address deleted successfully'
export const MAINTENANCE_MODE_CREATION_SUCCESS_MESSAGE = 'Maintenance mode created successfully'
export const MAINTENANCE_MODE_UPDATE_SUCCESS_MESSAGE = 'Maintenance mode updated successfully'
export const MAINTENANCE_MODE_DELETION_MESSAGE = 'Maintenance mode deleted successfully'
export const COMPANY_LOGO_UPLOAD_SUCCESS_MESSAGE = 'Company logo uploaded successfully'
export const COMPANY_THEME_SUCCESS_MESSAGE = 'Company theme updated successfully'
export const PRODUCT_ACCESS_CONTROL_GROUP_CREATION_SUCCESS_MESSAGE = 'Product Access Control Group created successfully'
export const PRODUCT_ACCESS_CONTROL_GROUP_UPDATE_SUCCESS_MESSAGE = 'Product Access Control Group updated successfully'
export const PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE = 'Product Access Control Group deleted successfully'
export const PRODUCT_ACCESS_CONTROL_GROUP_PRODUCT_CATEGORY_TAGS_ADDITION_SUCCESS_MESSAGE = 'Product Category Tags added to Product Access Control Group successfully'
export const PRODUCT_CATEGORY_TAG_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE = 'Product Category Tag in Product Access Control Group removed successfully'
export const PRODUCT_ACCESS_CONTROL_GROUP_USERS_ADDITION_SUCCESS_MESSAGE = 'Users added to Product Access Control Group successfully'
export const USER_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE = 'User in Product Access Control Group removed successfully'
export const PRODUCT_ACCESS_CONTROL_GROUP_COMPANIES_ADDITION_SUCCESS_MESSAGE = 'Companies added to Product Access Control Group successfully'
export const COMPANY_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE = 'Company in Product Access Control Group removed successfully'
export const PRODUCT_ACCESS_CONTROL_GROUP_COMPANY_USER_GROUPS_ADDITION_SUCCESS_MESSAGE = 'Company User Groups added to Product Access Control Group successfully'
export const COMPANY_USER_GROUP_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE = 'Company User Group in Product Access Control Group removed successfully'
export const COMPANY_USER_GROUP_CREATION_SUCCESS_MESSAGE = 'Company User Group created successfully'
export const COMPANY_USER_GROUP_UPDATE_SUCCESS_MESSAGE = 'Company User Group updated successfully'
export const COMPANY_USER_GROUP_DELETION_MESSAGE = 'Company User Group deleted successfully'
export const COMPANY_USER_GROUP_USERS_ADDITION_SUCCESS_MESSAGE = 'Users added to Company User Group successfully'
export const USER_COMPANY_USER_GROUP_DELETION_MESSAGE = 'User in Company User Group removed successfully'
export const PRODUCT_CATEGORY_CREATION_SUCCESS_MESSAGE = 'Product category created successfully'
export const PRODUCT_CATEGORY_UPDATE_SUCCESS_MESSAGE = 'Product category updated successfully'
export const PRODUCT_CATEGORY_SORT_ORDER_UPDATE_SUCCESS_MESSAGE = 'Product categories sorted successfully'
export const PRODUCT_CATEGORY_DELETION_MESSAGE = 'Product category deleted successfully'
export const PRODUCT_CATEGORY_TAG_CREATION_SUCCESS_MESSAGE = 'Product category tag created successfully'
export const PRODUCT_CATEGORY_PRODUCTS_ADDITION_SUCCESS_MESSAGE = 'Products added to category successfully'
export const PRODUCT_CATEGORY_TAG_DELETION_MESSAGE = 'Product category tag deleted successfully'
export const PRODUCT_CATEGORY_TAG_UPDATE_SUCCESS_MESSAGE = 'Product category tag updated successfully'
export const PRODUCT_TAGS_UPDATE_MESSAGE = 'Product tags updated successfully'
export const PRODUCT_IN_PRODUCT_CATEGORY_DELETION_MESSAGE = 'Product in Product Category removed successfully'
export const PRODUCT_GRADUATED_PRICE_CREATION_MESSAGE = 'Product graduated price created successfully'
export const PRODUCT_GRADUATED_PRICE_UPDATE_MESSAGE = 'Product graduated price updated successfully'
export const PRODUCT_GRADUATED_PRICE_DELETION_MESSAGE = 'Product graduated price deleted successfully'
export const ORDER_VISIBILITY_CHANGE_SUCCESS_MESSAGE = 'Shipping order visibility changed successfully'
export const ARTICLE_ITEM_CREATION_SUCCESS_MESSAGE = 'Article item created successfully'
export const CAMPAIGN_QUOTA_CREATION_SUCCESS_MESSAGE = 'Campaign quota created successfully'
export const CAMPAIGN_QUOTA_UPDATE_SUCCESS_MESSAGE = 'Campaign quota updated successfully'
export const CAMPAIGN_QUOTA_DELETION_MESSAGE = 'Campaign quota deleted successfully'
export const CAMPAIGN_QUOTA_NOTIFICATION_CREATION_SUCCESS_MESSAGE = 'Campaign quota notification created successfully'
export const CAMPAIGN_QUOTA_NOTIFICATION_UPDATE_SUCCESS_MESSAGE = 'Campaign quota notification updated successfully'
export const CAMPAIGN_QUOTA_NOTIFICATION_DELETION_MESSAGE = 'Campaign quota notification deleted successfully'
export const CAMPAIGN_ADDITIONAL_PRODUCT_SETTING_DELETION_MESSAGE = 'Additional product setting deleted successfully'
export const CAMPAIGN_ADDITIONAL_PRODUCT_SETTING_UPDATE_SUCCESS_MESSAGE = 'Additional product setting updated successfully'
export const CAMPAIGN_ADDITIONAL_PRODUCT_SETTING_ADDITION_SUCCESS_MESSAGE = 'Additional product setting added successfully'
export const PRODUCT_STOCK_NOTIFICATION_DELETION_MESSAGE = 'Product stock notification deleted successfully'
export const PRODUCT_STOCK_NOTIFICATION_UPDATE_SUCCESS_MESSAGE = 'Product stock notification updated successfully'
export const PRODUCT_STOCK_NOTIFICATION_CREATION_SUCCESS_MESSAGE = 'Product stock notification created successfully'
export const PRIVACY_RULE_UPDATE_MESSAGE = 'Privacy rule updated successfully'
export const PRIVACY_RULE_DELETE_MESSAGE = 'Privacy rule deleted successfully'
