import React, { useEffect, useState } from 'react'
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize
} from 'react-papaparse'
import { FieldArray, Formik, getIn } from 'formik'
import { array, date, number, object, string } from 'yup'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import Papa, { ParseError, ParseMeta } from 'papaparse'
import Calendar from 'react-datepicker'
import { styles } from '../../../pages/Campaigns/Details/styles'
import { countriesObject } from '../../../utils/countries'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { getAllSalutations } from '../../../store/reducers/api/salutationReducer'
import { getAllTitles } from '../../../store/reducers/api/titleReducer'
import {
  ApiMetadata,
  Bundle,
  Campaign,
  Nullable,
  OrderLineRequest,
  Product,
  Salutation,
  Title,
  BillOfMaterialsComponent
} from '../../../types'
import { getShippingMethod } from '../../../constants/shippingMethods'
import { setToast } from '../../../store/reducers/toastReducer'
import { dismissModal } from '../../../utils/dismissModal'
import Progress from '../../loaders/Progress'
import { createBulkOrders, resetPendingOrders } from '../../../store/reducers/api/campaignReducer'
import { detectOS } from '../../../utils/detectOs'
import CompanyService from '../../../services/api/CompanyService'
import { isWeekday } from '../../../utils/isWeekday'
import { DaysOfWeek } from '../../../enums/daysOfTheWeek'
import CampaignService from '../../../services/api/CampaignService'
import { phoneValidationPattern } from '../../../constants/regexPatterns'
import { getDateOfDispatchMinimumDays } from '../../../utils/getDateOfDispatchMinimumDays'
import { SALUTATION_MAX_LENGTH, TITLE_MAX_LENGTH } from '../../../constants/maxAndMinFieldValues'

dayjs.extend(customParseFormat)

const bulkImportTemplateLink = String(process.env.REACT_APP_BULK_IMPORT_TEMPLATE_LINK)
const faqLink = String(process.env.REACT_APP_FAQ_LINK)
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919'
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40)

const dateOfDispatchMinimumDays = getDateOfDispatchMinimumDays()
const maximumQuantity = 1000

interface BundleImporterProps {
  campaign: Campaign
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>
  isAllowedToReadProducts: boolean
  isAllowedToReadBundles: boolean
  isAdditionalProductAllowed: boolean
}

const BulkImporter = ({
  campaign,
  setShowDialog,
  isAllowedToReadProducts,
  isAllowedToReadBundles,
  isAdditionalProductAllowed
}: BundleImporterProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const salutations = useAppSelector((state) => state.apiSalutation.salutations)
  const titles = useAppSelector((state) => state.apiTitle.titles)
  const isCreatingBulkOrders = useAppSelector((state) => state.apiCampaign.isCreatingOrders)
  const createdPendingOrders = useAppSelector((state) => state.apiCampaign.pendingOrders)
  const orderError = useAppSelector((state) => state.apiCampaign.error)

  const [zoneHover, setZoneHover] = useState(false)
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR)
  const [results, setResults] = useState<Array<any>>([])
  const [fileErrors, setFileErrors] = useState<Array<ParseError[]>>([])
  const [templateError, setTemplateError] = useState<Nullable<string>>(null)
  const [encoding, setEncoding] = useState(localStorage.getItem('encodingBulkUpload') || detectOS().encoding)
  const [clear, setClear] = useState(false)
  const [csvFile, setCsvFile] = useState<File | null>(null)
  const [perPage] = useState(100)
  const [page] = useState(1)
  const [products, setProducts] = useState<Product[]>([])
  const [isLoadingCompanyProducts, setIsLoadingCompanyProducts] = useState(false)
  const [, setMetadata] = useState<ApiMetadata>({
    page: 1,
    pageCount: 1,
    perPage: 100,
    total: 0
  })
  const [, setError] = useState(null)
  const [, setBundleError] = useState(null)
  const [bundles, setBundles] = useState<Bundle[]>([])
  const [filteredBundles, setFilteredBundles] = useState<Bundle[]>([])
  const [isLoadingCampaignBundles, setIsLoadingCampaignBundles] = useState(false)
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([])

  const token = currentUser?.token
  const companyId = campaign.company?.id
  const campaignId = campaign.id
  const campaignShippingDestinationsObject = campaign.campaignShippingDestinations.map(destination =>
    countriesObject.find(country => country.country === destination.country)
  )
  const campaignShippingDestinations = campaignShippingDestinationsObject.map(destination => ({
    value: destination?.alpha2Code,
    label: destination?.country
  }))
  const countries = countriesObject.map(country => ({
    value: country.alpha2Code,
    label: country.country
  }))

  const baseAllowedKeys = [
    'company',
    'salutation',
    'title',
    'firstName',
    'lastName',
    'email',
    'phone',
    'street',
    'zip',
    'city',
    'addressAddition',
    'country',
    'note',
    'dateOfDispatch',
    'costCenter',
    'quantity',
    'bundle'
  ]
  const additionalItemKeys = [
    'additionalItem1',
    'additionalItem2',
    'additionalItem3',
    'additionalItem4'
  ]

  const allowedKeys = [
    ...baseAllowedKeys,
    ...(isAdditionalProductAllowed ? additionalItemKeys : []),
    ...(campaign.includeStartDate ? ['startDate'] : [])
  ]

  const dispatch = useAppDispatch()
  const { CSVReader } = useCSVReader()

  const checkBundleExistsOrIsOutOfStock = (label: string, originalValue: string, values: string) => {
    const foundBundle = bundles.find(bundle =>
      String(bundle.merchantSku) === String(originalValue) ||
      bundle.id === originalValue ||
      bundle.name === originalValue
    )
    if (foundBundle) {
      return `${label} must be in stock`
    }
    return `Bundle must be one of the following values: ${values}`
  }
  const checkProductExistsOrIsOutOfStock = (label: string, originalValue: string, values: string) => {
    const foundProduct = products.find(product =>
      String(product.merchantSku) === String(originalValue) ||
      product.name === originalValue
    )
    if (foundProduct) {
      return `${label} must be in stock`
    }
    return `${label} must be one of the following values: ${values}`
  }

  const orderShape: any = {
    company: string().label('Company').nullable(),
    firstName: string()
      .label('First Name')
      .required('First Name is required')
      .min(2, (value) => `First Name must be at least ${value.min} characters`)
      .max(32, (value) => `First Name must be at most ${value.max} characters`)
      .nullable(),
    lastName: string()
      .label('Last Name')
      .required('Last Name is required')
      .min(2, (value) => `Last Name must be at least ${value.min} characters`)
      .max(32, (value) => `Last Name must be at most ${value.max} characters`)
      .nullable(),
    email: string().label('Email').email('Enter a valid email').required('Email is required').nullable(),
    quantity: number()
      .typeError((value) => `Quantity must be a number, update the provided value: ${value.originalValue}`)
      .label('Quantity')
      .required()
      .min(1, (value) => `Quantity must be greater than or equal to ${value.min}`),
    salutation: string().label('Salutation').nullable(),
    title: string().label('Title').nullable(),
    note: string()
      .label('Note')
      .max(100, (value) => `Note must be at most ${value.max} characters`)
      .default('')
      .nullable(),
    street: string().label('Street and House Number').required('Street and House Number are required').nullable(),
    city: string().label('City').required().nullable(),
    zip: string().label('Zip').required().nullable(),
    phone: string()
      .label('Phone')
      .nullable()
      .matches(phoneValidationPattern, 'Enter a valid phone number'),
    country: string()
      .label('Country')
      .required('Country is required')
      .oneOf(
        campaignShippingDestinationsObject.length > 0
          ? campaignShippingDestinationsObject.map(destination => destination?.alpha2Code.toUpperCase())
          : countriesObject.map(country => country.alpha2Code.toUpperCase())
      )
      .oneOf(
        campaignShippingDestinationsObject.length > 0
          ? campaignShippingDestinationsObject.map(destination => destination?.alpha3Code.toUpperCase())
          : countriesObject.map(country => country.alpha3Code.toUpperCase())
      )
      .oneOf(
        campaignShippingDestinationsObject.length > 0
          ? campaignShippingDestinationsObject.map(destination => destination?.country.toUpperCase())
          : countriesObject.map(country => country.country.toUpperCase())
      )
      .oneOf(
        campaignShippingDestinationsObject.length > 0
          ? campaignShippingDestinationsObject.map(destination => destination?.countryGerman.toUpperCase())
          : countriesObject.map(country => country.countryGerman.toUpperCase())
      )
      .transform((value, originalValue) => {
        if (originalValue) {
          originalValue.toUpperCase()
        }
        return value?.toUpperCase()
      }),
    dateOfDispatch: date()
      .typeError('Date of Dispatch is required')
      .label('Date of Dispatch')
      .min(dayjs().startOf('day').add(dateOfDispatchMinimumDays, 'days').toDate(), (value) => `Date of Dispatch field must be later than ${dayjs(value.min).format('YYYY-MM-DD HH:mm')}`)
      .transform((value, originalValue) => {
        // Move date to nearest weekday and convert the value to a Date before validating
        if (originalValue) {
          let date = dayjs(originalValue, ['DD-MM-YYYY', 'YYYY-MM-DD', 'DD.MM.YYYY']).set('hour', dayjs().hour()).set('minute', dayjs().minute()).add(15, 'minutes')
          const dayOfWeek = date.day()
          // If date is Saturday, move to next Monday
          if (dayOfWeek === DaysOfWeek.Saturday) {
            date = date.add(2, 'days')
          }
          // If date is Sunday, move to next Monday
          if (dayOfWeek === DaysOfWeek.Sunday) {
            date = date.add(1, 'day')
          }
          return date.toDate()
        }
        return value
      })
      .test('is-weekday', 'Date of Dispatch must be a weekday', (value) => {
        // Check if the date is a Saturday or Sunday
        const dayOfWeek = dayjs(value).day()
        return dayOfWeek !== DaysOfWeek.Sunday && dayOfWeek !== DaysOfWeek.Saturday
      }),
    costCenter: string().label('Cost Center').default('').nullable(),
    startDate: date().label('Start Date').nullable().default(null),
    bundle: string()
      .label('Bundle')
      .required('Bundle is required')
      .oneOf(
        filteredBundles.filter(filteredBundle => filteredBundle.merchantSku)
          .map(filteredBundle => filteredBundle.merchantSku),
        (value) => `${checkBundleExistsOrIsOutOfStock(value.label, value.originalValue, value.values)}`
      )
      .oneOf(
        filteredBundles.map(filteredBundle => filteredBundle.id),
        (value) => `${checkBundleExistsOrIsOutOfStock(value.label, value.originalValue, value.values)}`
      )
      .oneOf(
        filteredBundles.map(filteredBundle => filteredBundle.name),
        (value) => `${checkBundleExistsOrIsOutOfStock(value.label, value.originalValue, value.values)}`
      )
      .nullable()
  }

  if (isAdditionalProductAllowed) {
    additionalItemKeys.forEach((key) => {
      orderShape[key] = string()
        .label(key.replace(/([A-Z0-9])/g, ' $1').trim().replace(/\b\w/g, char => char.toUpperCase()))
        .oneOf(
          filteredProducts.map(filteredProduct => filteredProduct.merchantSku),
          (value) => `${checkProductExistsOrIsOutOfStock(value.label, value.originalValue, value.values)}`
        )
        .oneOf(
          filteredProducts.map(filteredProduct => filteredProduct.name),
          (value) => `${checkProductExistsOrIsOutOfStock(value.label, value.originalValue, value.values)}`
        )
        .oneOf([null], (value) => `${checkProductExistsOrIsOutOfStock(value.label, value.originalValue, value.values)}`)
        .nullable()
    })
  }

  const ordersSchema = object().shape({
    orders: array()
      .of(object(orderShape))
      .required('Orders are required')
      .min(1, 'Upload a csv with at least 1 order'),
    hasAgreed: string().oneOf(
      ['yes'],
      'We want to make sure you understand our policies. Please read and agree to our terms and conditions before continuing.'
    )
  })

  const getAvailableBundleStock = (bundleId: string) => {
    const foundBundle = bundles.find(bundle => bundle.id === bundleId)
    if (foundBundle) {
      const foundBundleStock = foundBundle?.stock
      if (foundBundleStock) {
        return Math.max(
          (foundBundleStock?.stockLevel || 0) - (foundBundleStock?.stockLevelReserved || 0),
          0
        )
      }
      return 0
    }
    return 0
  }

  const getAvailableProductStock = (productId: string) => {
    const foundProduct = products.find(product => product.id === productId)
    if (foundProduct && foundProduct.stock) {
      return Math.max(
        (foundProduct.stock?.stockLevel || 0) - (foundProduct.stock?.stockLevelReserved || 0),
        0
      )
    }
    return 0
  }

  const errorParagraphs = (errors: any) => {
    if (typeof errors === 'string') {
      return <li className="list-group-item text-danger small">{errors}</li>
    }

    if (typeof errors === 'object') {
      return errors.map((error: any, index: number) => {
        if (typeof error === 'object' && error !== null) {
          const errorMessage: any = Object.values(error)[0]
          return (
            <li key={`errors-${index}`} className="list-group-item text-danger small">
              Row {index + 1}: {errorMessage}
            </li>
          )
        }
        return null
      })
    }
    return null
  }

  const headers = (key: string) => {
    const required = ['city', 'zip']
    const data: any = {
      company: {
        text: 'Company',
        type: 'text',
        width: 200
      },
      firstName: {
        text: 'First Name*',
        type: 'text'
      },
      lastName: {
        text: 'Last Name*',
        type: 'text'
      },
      email: {
        text: 'Email*',
        type: 'text',
        width: 280
      },
      salutation: {
        text: 'Salutation',
        type: 'select-creatable',
        width: 150,
        options: salutations.map((salutation: Salutation) => ({
          label: salutation.name,
          value: salutation.name
        })),
        maxLength: SALUTATION_MAX_LENGTH
      },
      title: {
        text: 'Title',
        type: 'select-creatable',
        width: 150,
        options: titles.map((title: Title) => ({
          label: title.name,
          value: title.name
        })),
        maxLength: TITLE_MAX_LENGTH
      },
      street: {
        text: 'Street + House Number*',
        type: 'text',
        width: 250
      },
      country: {
        text: 'Country*',
        type: 'select-search',
        options: campaignShippingDestinations.length > 0 ? campaignShippingDestinations : countries,
        width: 180
      },
      addressAddition: {
        text: 'Address Addition',
        type: 'text'
      },
      costCenter: {
        text: 'Cost Center',
        type: 'text'
      },
      note: {
        text: 'Note',
        type: 'textarea',
        width: 300
      },
      quantity: {
        text: 'Quantity*',
        type: 'number',
        width: 80,
        min: 1
      },
      dateOfDispatch: {
        text: 'Date of Dispatch*',
        type: 'date',
        min: dayjs().add(dateOfDispatchMinimumDays, 'day').toDate(),
        placeholder: 'Select a weekday'
      },
      bundle: {
        text: 'Bundle*',
        type: 'select',
        options: bundles.map(bundle => ({
          label: bundle.name,
          value: bundle.merchantSku || bundle.id
        }))
      },
      additionalItem1: {
        text: 'Additional Item 1',
        type: 'select',
        width: 200,
        options: products.map(product => ({
          label: product.name,
          value: product.merchantSku
        }))
      },
      additionalItem2: {
        text: 'Additional Item 2',
        type: 'select',
        width: 200,
        options: products.map(product => ({
          label: product.name,
          value: product.merchantSku
        }))
      },
      additionalItem3: {
        text: 'Additional Item 3',
        type: 'select',
        width: 200,
        options: products.map(product => ({
          label: product.name,
          value: product.merchantSku
        }))
      },
      additionalItem4: {
        text: 'Additional Item 4',
        type: 'select',
        width: 200,
        options: products.map(product => ({
          label: product.name,
          value: product.merchantSku
        }))
      },
      startDate: {
        text: 'Start Date',
        type: 'date',
        placeholder: 'Select Start Date'
      }
    }
    if (key in data) {
      return data[key]
    }
    const text = required.includes(key) ? `${key}*` : key
    return {
      text,
      type: 'text'
    }
  }

  const renderInputs = (
    index: number,
    value: any,
    key: any,
    errors: any,
    handleChange: any,
    handleBlur: any,
    setFieldValue: any
  ) => {
    if (!isAdditionalProductAllowed && additionalItemKeys.includes(key)) return null

    const className = `form-control ${getIn(errors.orders, `[${index}].${key}`) ? 'is-invalid' : ''}`
    const ariaLabel = headers(key).text || key

    if (headers(key).type === 'textarea') {
      return (
        <textarea
          className={className}
          name={`orders[${index}].${key}`}
          aria-label={ariaLabel}
          value={value[key] ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          style={{ minWidth: headers(key).width || 150 }}
          rows={1}
        />
      )
    }

    if (headers(key).type === 'number') {
      if (key === 'quantity') {
        let validatedValue = value[key]
        if (isNaN(Number(validatedValue))) {
          validatedValue = ''
        } else {
          if (campaign.isExceedStockEnabled) {
            validatedValue = Math.max(Math.min(validatedValue, maximumQuantity), 1)
          } else {
            const foundBundle = bundles.find(bundle =>
              String(bundle.merchantSku) === String(value.bundle) ||
              bundle.id === value.bundle ||
              bundle.name === value.bundle
            )
            if (foundBundle) {
              const availableStock = getAvailableBundleStock(foundBundle.id)
              const max = availableStock
              validatedValue = Math.max(Math.min(validatedValue, max), 1)
            } else {
              validatedValue = 0
            }
          }
        }
        return (
          <input
            className={className}
            type={headers(key).type}
            name={`orders[${index}].${key}`}
            aria-label={ariaLabel}
            value={validatedValue}
            onChange={(e) => {
              const selectedValue = Number(e.target.value)
              if (campaign.isExceedStockEnabled) {
                setFieldValue(`orders[${index}].${key}`, Math.max(Math.min(selectedValue, maximumQuantity), 1))
              } else {
                const foundBundle = bundles.find(bundle =>
                  String(bundle.merchantSku) === String(value.bundle) ||
                  bundle.id === value.bundle ||
                  bundle.name === value.bundle
                )
                if (foundBundle) {
                  const availableStock = getAvailableBundleStock(foundBundle.id)
                  const max = availableStock
                  const quantity = Math.max(Math.min(Number(selectedValue), max), 1)
                  setFieldValue(`orders[${index}].${key}`, quantity)
                }
              }
            }}
            onBlur={handleBlur}
            style={{ minWidth: headers(key).width || 150 }}
            min={headers(key).min}
          />
        )
      }
      return (
        <input
          className={className}
          type={headers(key).type}
          name={`orders[${index}].${key}`}
          aria-label={ariaLabel}
          value={value[key] ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          style={{ minWidth: headers(key).width || 150 }}
          min={headers(key).min}
        />
      )
    }

    if (headers(key).type === 'select') {
      let initialValue = value[key] ?? ''
      if (key === 'country') {
        const countryValue = initialValue.toUpperCase()
        initialValue = countriesObject
          .find(item =>
            item.alpha2Code.toUpperCase() === countryValue ||
            item.alpha3Code.toUpperCase() === countryValue ||
            item.country.toUpperCase() === countryValue ||
            item.countryGerman.toUpperCase() === countryValue
          )?.alpha2Code
      }

      if (key === 'bundle') {
        const foundBundle = bundles.find(bundle =>
          String(bundle.merchantSku) === String(value[key]) ||
          bundle.id === value[key] ||
          bundle.name === value[key]
        )
        initialValue = foundBundle?.merchantSku || foundBundle?.id
      }

      const additionalItemsKeys = ['additionalItem1', 'additionalItem2', 'additionalItem3', 'additionalItem4']
      if (additionalItemsKeys.includes(key)) {
        const foundAdditionalItem = products.find(
          product =>
            String(product.merchantSku) === String(value[key]) ||
            product.name === String(value[key])
        )
        initialValue = foundAdditionalItem?.merchantSku
      }

      return (
        <select
          className={className}
          name={`orders[${index}].${key}`}
          aria-label={ariaLabel}
          onChange={handleChange}
          onBlur={handleBlur}
          value={initialValue}
          style={{ minWidth: headers(key).width || 150 }}
        >
          <option value="">{`Select ${headers(key).text || key}`}</option>
          {headers(key).options.map((option: { label: string, value: any }, index: number) => (
            <option key={`${index}-name`} value={option.value}>{option.label}</option>
          ))}
        </select>
      )
    }

    if (headers(key).type === 'select-search') {
      let initialValue = value[key] ?? ''
      if (key === 'country') {
        const countryValue = initialValue.toUpperCase()
        initialValue = countriesObject
          .find(item =>
            item.alpha2Code.toUpperCase() === countryValue ||
            item.alpha3Code.toUpperCase() === countryValue ||
            item.country.toUpperCase() === countryValue ||
            item.countryGerman.toUpperCase() === countryValue
          )?.alpha2Code
      }
      return (
        <Select
          menuPosition="fixed"
          name={`orders[${index}].${key}`}
          options={headers(key).options}
          onChange={(selectedOption: any) => {
            setFieldValue(`orders[${index}].${key}`, selectedOption.value)
          }}
          value={headers(key).options.find((option: { value: string, label: string }) => option.value === initialValue) || null}
          className={`${getIn(errors.orders, `[${index}].${key}`) ? 'is-invalid' : ''}`}
          styles={{
            control: (provided, state) => ({
              ...provided,
              minWidth: headers(key).width,
              borderColor: getIn(errors.orders, `[${index}].${key}`) ? '#dc3545' : provided.borderColor,
              '&:hover': {
                boxShadow: getIn(errors.orders, `[${index}].${key}`) ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                borderColor: getIn(errors.orders, `[${index}].${key}`) ? '#dc3545' : '#86b7fe'
              }
            })
          }}
        />
      )
    }

    if (headers(key).type === 'date') {
      let selectedDate = dayjs(dayjs(value[key], ['DD-MM-YYYY', 'YYYY-MM-DD', 'DD.MM.YYYY']).format('YYYY-MM-DD'))
      if (selectedDate.isValid()) {
        selectedDate = dayjs(dayjs(value[key], ['DD-MM-YYYY', 'YYYY-MM-DD', 'DD.MM.YYYY']).format('YYYY-MM-DD'))
      } else {
        selectedDate = dayjs(dayjs().format('YYYY-MM-DD'))
      }
      const dayOfWeek = selectedDate.day()
      // If date is Saturday, move to next Monday
      if (dayOfWeek === DaysOfWeek.Saturday) {
        selectedDate = selectedDate.add(2, 'days')
      }
      // If date is Sunday, move to next Monday
      if (dayOfWeek === DaysOfWeek.Sunday) {
        selectedDate = selectedDate.add(1, 'day')
      }

      return (
        <div style={{ minWidth: headers(key).width || 150 }}>
          <Calendar
            id={`orders[${index}].${key}`}
            name={`orders[${index}].${key}`}
            onChange={(date: Date) => {
              if (date) {
                setFieldValue(`orders[${index}].${key}`, dayjs(date).format())
              } else {
                setFieldValue(`orders[${index}].${key}`, date)
              }
            }}
            selected={value[key] ? selectedDate.toDate() : null}
            className={className}
            minDate={headers(key).min}
            dateFormat={'dd/MM/yyyy'}
            autoComplete={'off'}
            filterDate={isWeekday}
            placeholderText={headers(key)?.placeholder || 'Select a weekday'}
            calendarStartDay={DaysOfWeek.Monday}
          />
        </div>
      )
    }

    if (headers(key).type === 'select-creatable') {
      return (
        <CreatableSelect
          menuPosition="fixed"
          isClearable={true}
          components={{
            Input: (props) => (
              <components.Input {...props} maxLength={headers(key).maxLength} />
            )
          }}
          inputId={`orders[${index}].${key}`}
          aria-label={ariaLabel}
          name={`orders[${index}].${key}`}
          options={headers(key).options}
          value={value[key] ? { value: value[key], label: value[key] } : null}
          onChange={(selectedOption) => {
            setFieldValue(`orders[${index}].${key}`, selectedOption ? selectedOption.value : '')
          }}
          onBlur={handleBlur}
          className={`${getIn(errors.orders, `[${index}].${key}`) ? 'is-invalid' : ''}`}
          styles={{
            control: (provided, state) => ({
              ...provided,
              minWidth: headers(key).width,
              borderColor: getIn(errors.orders, `[${index}].${key}`) ? '#dc3545' : provided.borderColor,
              '&:hover': {
                boxShadow: getIn(errors.orders, `[${index}].${key}`) ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                borderColor: getIn(errors.orders, `[${index}].${key}`) ? '#dc3545' : '#86b7fe'
              }
            })
          }}
        />
      )
    }

    return (
      <input
        className={className}
        type={headers(key).type}
        name={`orders[${index}].${key}`}
        aria-label={ariaLabel}
        value={value[key] ?? ''}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{ minWidth: headers(key).width || 150 }}
        multiple={false}
      />
    )
  }

  const formatOrder = (order: any) => {
    const activeBundle = bundles.find(
      (bundle) =>
        String(bundle.merchantSku) === String(order.bundle) ||
        bundle.id === order.bundle ||
        bundle.name === order.bundle
    ) as Bundle

    let orderLineRequests: Array<Partial<OrderLineRequest>> = []

    const getShippingId = () => {
      return getShippingMethod(campaign)
    }

    const createOrderLineRequest = (
      bundleItem: Product | BillOfMaterialsComponent
    ): Partial<OrderLineRequest> => {
      return {
        itemName: bundleItem.name,
        articleNumber: String(bundleItem.merchantSku),
        itemNetSale: 0,
        itemVAT: 0,
        quantity: Number(order.quantity),
        type: 0,
        discount: 0,
        netPurchasePrice: 0
      }
    }

    if (activeBundle.merchantSku && activeBundle.isBillOfMaterials && activeBundle.isLocked) {
      orderLineRequests = [
        {
          itemName: activeBundle.name,
          articleNumber: String(activeBundle.merchantSku),
          itemNetSale: 0,
          itemVAT: 0,
          quantity: Number(order.quantity),
          type: 0,
          discount: 0,
          netPurchasePrice: 0
        }
      ]
    } else {
      orderLineRequests =
        activeBundle?.specifications?.billOfMaterialsComponents
          .map(createOrderLineRequest) || []
    }
    if (isAdditionalProductAllowed) {
      additionalItemKeys.forEach((bundleItemKey) => {
        if (order[bundleItemKey]) {
          const bundleItem = products.find(
            (product) => String(product.merchantSku) === String(order[bundleItemKey])
          ) as Product

          if (bundleItem) {
            const orderLineRequest = createOrderLineRequest(bundleItem)
            const isDuplicate = orderLineRequests.some(
              (item) => item.articleNumber === orderLineRequest.articleNumber
            )
            if (!isDuplicate) {
              orderLineRequests.push(orderLineRequest)
            }
          }
        }
      })
    }

    const updatedOrder = {
      currency: 'EUR',
      orderNo: '0',
      shippingId: getShippingId(),
      shipped: dayjs(order.dateOfDispatch, ['DD-MM-YYYY', 'YYYY-MM-DD', 'DD.MM.YYYY'])
        .set('hour', dayjs().hour())
        .set('minute', dayjs().minute())
        .add(15, 'minutes')
        .format(),
      deliverydate: dayjs(order.dateOfDispatch, ['DD-MM-YYYY', 'YYYY-MM-DD', 'DD.MM.YYYY'])
        .set('hour', dayjs().hour())
        .set('minute', dayjs().minute())
        .add(15, 'minutes')
        .format(),
      note: order.note,
      description: `${(campaign.description || '')} + ${(activeBundle.description || '')}`,
      costCenter: order.costCenter ? String(order.costCenter) : '',
      quantity: Number(order.quantity),
      orderLineRequests,
      shippingAddressRequests: [
        {
          salutation: order.salutation,
          firstName: order.firstName,
          lastName: order.lastName,
          title: order.title ?? '',
          company: order.company,
          companyAddition: '',
          street: order.street,
          addressAddition: order.addressAddition,
          zipCode: String(order.zip),
          place: order.city,
          phone: order.phone,
          state: '',
          country: order.country,
          iso: '',
          telephone: '',
          mobile: '',
          fax: '',
          email: order.email,
          startDate: campaign.includeStartDate ? (order.startDate || null) : null
        }
      ]
    }
    return updatedOrder
  }

  useEffect(() => {
    const length = createdPendingOrders.length
    if (length > 0) {
      const payload = {
        title: 'Success',
        message: `${length} ${length === 1 ? 'order' : 'orders'} created successfully`,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      setShowDialog(false)
      setResults([])
      setClear(!clear)
      dispatch(resetPendingOrders())
      dismissModal('bulkCreateOrderModal')
    }
  }, [createdPendingOrders])

  useEffect(() => {
    if (orderError && orderError?.errors.message) {
      const payload = {
        title: 'Error',
        message: orderError.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
    }
  }, [orderError])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getAllSalutations({ token, perPage: 100, page: 1, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getAllTitles({ token, perPage: 100, page: 1, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    setIsLoadingCompanyProducts(true)
    const controller = new AbortController()
    const signal = controller.signal

    if (token && companyId && isAllowedToReadProducts) {
      CompanyService.getCompanyProducts(companyId, token, perPage, page, '', signal)
        .then((res: any) => {
          setProducts(res.data.products)
          setMetadata(res.data.meta)
          setIsLoadingCompanyProducts(false)
        })
        .catch((error: any) => {
          setError(error)
          setProducts([])
          if (error.message !== 'canceled') {
            setIsLoadingCompanyProducts(false)
          }
        })
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page, companyId])

  useEffect(() => {
    if (templateError) {
      const payload = {
        title: 'Info',
        message: templateError,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'info'
      }
      dispatch(setToast(payload))
    }
    setTemplateError(null)
  }, [templateError])

  useEffect(() => {
    setIsLoadingCampaignBundles(true)
    const pageBundle = 1
    const perPageBundle = 100
    const controller = new AbortController()
    const signal = controller.signal

    if (token && campaignId && isAllowedToReadBundles) {
      CampaignService.getCampaignBundles(campaignId, token, perPageBundle, pageBundle, signal)
        .then((res: any) => {
          setBundles(res.data.bundles)
          setIsLoadingCampaignBundles(false)
        })
        .catch((error: any) => {
          setBundleError(error)
          setBundles([])
          if (error.message !== 'canceled') {
            setIsLoadingCampaignBundles(false)
          }
        })
    }

    return () => {
      controller.abort()
    }
  }, [campaignId])

  useEffect(() => {
    const validProducts = products.filter(product => getAvailableProductStock(product.id) > 0)
    setFilteredProducts(validProducts)
  }, [products])

  useEffect(() => {
    const validBundles = campaign.isExceedStockEnabled
      ? bundles
      : bundles.filter(bundle => getAvailableBundleStock(bundle.id) > 0)
    setFilteredBundles(validBundles)
  }, [bundles])

  return (
    <div className="main">
      <div className="row mb-2">
        <div className="d-flex flex-row justify-content-between">
          <a href={bulkImportTemplateLink} className="link-primary">
            Bulk Import Template Link
          </a>
          <a href={faqLink} target="_blank" className="link-primary" rel="noreferrer">
            FAQ
          </a>
        </div>
      </div>
      <div className="col mb-3">
        <label className="fw-semibold" htmlFor="encodingSelect">
          Change the encoding if your data is not rendered correctly:
        </label>
        <select
          id="encodingSelect"
          className="form-select"
          aria-label="Select encoding"
          value={encoding}
          onChange={(event) => {
            localStorage.setItem('encodingBulkUpload', event.target.value)
            setEncoding(event.target.value)
            if (csvFile) {
              Papa.parse(csvFile, {
                encoding: event.target.value,
                header: true,
                dynamicTyping: true,
                skipEmptyLines: true,
                transformHeader: (header: string) => header.trim(),
                transform: (value: string) => value.trim(),
                complete: function (results) {
                  setResults(results.data)
                  results.errors.length > 0 && setFileErrors([results.errors])
                }
              })
            }
          }}
        >
          <option value="ISO-8859-1">ISO-8859-1</option>
          <option value="UTF-8">UTF-8</option>
        </select>
      </div>
      <CSVReader
        disabled={(isLoadingCampaignBundles || isLoadingCompanyProducts)}
        key={clear}
        config={{
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
          encoding,
          transformHeader: (header: string) => header.trim(),
          transform: (value: string) => value.trim()
        }}
        onUploadAccepted={(
          results: { data: Array<any>; errors: Array<ParseError[]>; meta: Array<ParseMeta> },
          file: File
        ) => {
          const foundKeys = Object.keys(results.data[0])
          const areAllowedKeysPresent = foundKeys.every((item) => {
            if (!isAdditionalProductAllowed && additionalItemKeys.includes(item)) return true
            if (!campaign.includeStartDate && item === 'startDate') return true
            return allowedKeys.includes(item)
          })
          const hasStartDateKey = foundKeys.includes('startDate')

          if (areAllowedKeysPresent) {
            setCsvFile(file)
            const rows = results.data.map(item => {
              const newItem = { ...item }
              if (!campaign.includeStartDate) {
                delete newItem.startDate
              } else if (!hasStartDateKey) {
                newItem.startDate = null
              }
              if (!isAdditionalProductAllowed) {
                additionalItemKeys.forEach(key => {
                  delete newItem[key]
                })
              }
              return newItem
            })
            setResults(rows)
            setFileErrors(results.errors)
          } else {
            setTemplateError(
              'A new template is available. Download the template from the link provided in the modal in order to continue.'
            )
          }
          setZoneHover(false)
        }}
        onDragOver={(event: DragEvent) => {
          event.preventDefault()
          setZoneHover(true)
        }}
        onDragLeave={(event: DragEvent) => {
          event.preventDefault()
          setZoneHover(false)
        }}
      >
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }: any) => (
          <>
            <div
              {...getRootProps()}
              style={Object.assign({}, styles.zone, zoneHover && styles.zoneHover)}
            >
              {acceptedFile
                ? (
                <>
                  <div style={styles.file}>
                    <div style={styles.info}>
                      <span style={styles.size}>
                        {formatFileSize(acceptedFile.size)}
                      </span>
                      <span style={styles.name}>{acceptedFile.name}</span>
                    </div>
                    <div style={styles.progressBar}>
                      <ProgressBar />
                    </div>
                    <div
                      {...getRemoveFileProps()}
                      style={styles.remove}
                      onMouseOver={(event: Event) => {
                        event.preventDefault()
                        setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT)
                      }}
                      onMouseOut={(event: Event) => {
                        event.preventDefault()
                        setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR)
                      }}
                      onClick={(event: Event) => {
                        getRemoveFileProps().onClick(event)
                        setCsvFile(null)
                        setResults([])
                        setFileErrors([])
                      }}
                    >
                      <Remove color={removeHoverColor} />
                    </div>
                  </div>
                </>
                  )
                : (
                `${(isLoadingCampaignBundles || isLoadingCompanyProducts)
                  ? 'Loading stocks...'
                  : 'Drop CSV file here or click to upload'}`
                  )}
              {((isLoadingCampaignBundles || isLoadingCompanyProducts)) && <Progress />}
            </div>
          </>
        )}
      </CSVReader>
      <Formik
        validationSchema={ordersSchema}
        enableReinitialize={true}
        initialValues={{
          orders: results,
          hasAgreed: 'no'
        }}
        onSubmit={(values, actions): void => {
          const pendingOrders = values.orders.map((order: any) => formatOrder(order))
          const controller = new AbortController()
          const signal = controller.signal

          if (campaignId && token) {
            dispatch(createBulkOrders({ id: campaignId, token, pendingOrders, signal }))
          }
          actions.setSubmitting(false)
        }}
      >
        {({ values, handleChange, handleSubmit, errors, handleBlur, isSubmitting, setFieldValue }) => {
          const rowKeys = values.orders.length > 0
            ? Object.keys(values.orders[0]).filter(key => isAdditionalProductAllowed || !additionalItemKeys.includes(key))
            : []

          return (
            <div className="mt-4">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead className="sticky-top">
                    <tr>
                      {values.orders.length > 0 && <th>#</th>}
                      {values.orders.length > 0 &&
                        rowKeys.map((key: string, index: number) => (
                          <th key={`header-${key}-${index}`} className="text-capitalize" scope="col">
                            {headers(key).text} <span className="small">{headers(key).secondaryText}</span>
                          </th>
                        ))}
                      {values.orders.length > 0 && <th>Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray
                      name="orders"
                      render={arrayHelpers => (
                        <>
                          {values.orders.length > 0
                            ? values.orders.map((value, index) => (
                                <tr key={`order-${index}`}>
                                  <td className={getIn(errors.orders, `${index}`) ? 'text-danger fw-bold' : ''}>
                                    {index + 1}
                                  </td>
                                  {Object.keys(value)
                                    .filter(key => isAdditionalProductAllowed || !additionalItemKeys.includes(key))
                                    .map((key: string) => (
                                      <td key={`input-${key}-${index}`}>
                                        {renderInputs(index, value, key, errors, handleChange, handleBlur, setFieldValue)}
                                      </td>
                                    ))}
                                  <td>
                                    <div className="btn-group" role="group" aria-label="Order Action Buttons">
                                      <button
                                        type="button"
                                        title={`Remove Order on Row ${index + 1}`}
                                        className="btn"
                                        onClick={() => {
                                          setFileErrors([])
                                          arrayHelpers.remove(index)
                                        }}
                                        disabled={values.orders.length === 1}
                                      >
                                        <i className="bi bi-dash-circle text-danger"></i>
                                      </button>
                                      {index === values.orders.length - 1 && (
                                        <button
                                          type="button"
                                          title={'Add New Order Row'}
                                          className="btn"
                                          onClick={() => {
                                            arrayHelpers.push({
                                              company: '',
                                              salutation: '',
                                              title: '',
                                              firstName: '',
                                              lastName: '',
                                              email: '',
                                              phone: '',
                                              street: '',
                                              zip: '',
                                              city: '',
                                              addressAddition: '',
                                              country: '',
                                              note: '',
                                              dateOfDispatch: dayjs().add(dateOfDispatchMinimumDays, 'days').format('YYYY-MM-DD'),
                                              costCenter: '',
                                              quantity: 1,
                                              bundle: '',
                                              ...(isAdditionalProductAllowed && {
                                                additionalItem1: '',
                                                additionalItem2: '',
                                                additionalItem3: '',
                                                additionalItem4: ''
                                              }),
                                              ...(campaign.includeStartDate && {
                                                startDate: null
                                              })
                                            })
                                          }}
                                          disabled={false}
                                        >
                                          <i className="bi bi-plus-circle text-secondary"></i>
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                            ))
                            : null}
                        </>
                      )}
                    />
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3 px-1">
                    <p className="mt-2">
                      By creating an order, you agree to our{' '}
                      <button
                        className="btn btn-link text-primary m-0 p-0"
                        data-bs-toggle="modal"
                        type="button"
                        data-bs-target="#termsModal"
                      >
                        terms and conditions
                      </button>.
                    </p>
                    <div className={`form-check form-check-inline ${errors.hasAgreed ? 'is-invalid' : ''}`}>
                      <input
                        className={`form-check-input ${errors.hasAgreed ? 'is-invalid' : ''}`}
                        type="radio"
                        name="hasAgreed"
                        id="inlineRadioYes"
                        onChange={handleChange}
                        value="yes"
                        checked={values.hasAgreed === 'yes'}
                      />
                      <label className="form-check-label" htmlFor="inlineRadioYes">
                        Yes
                      </label>
                    </div>
                    <div className={`form-check form-check-inline ${errors.hasAgreed ? 'is-invalid' : ''}`}>
                      <input
                        className={`form-check-input ${errors.hasAgreed ? 'is-invalid' : ''}`}
                        type="radio"
                        name="hasAgreed"
                        id="inlineRadioNo"
                        onChange={handleChange}
                        value="no"
                        checked={values.hasAgreed === 'no'}
                      />
                      <label className="form-check-label" htmlFor="inlineRadioNo">
                        No
                      </label>
                    </div>
                    <div id="validationHasAgreedFeedback" className="invalid-feedback">
                      {errors.hasAgreed}
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-1">
                {Object.keys(errors).length > 0 && (
                  <div className="text-danger small">
                    Kindly check the imported data and fix any errors
                  </div>
                )}
                {fileErrors.length > 0 && (
                  <ul className="list-group mt-2">
                    {fileErrors.map((fileError, index) => (
                      <li className="list-group-item text-danger small" key={`file-error-${index}`}>
                        Row {fileError[0].row + 1}: {fileError[0].message}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="py-1">
                {Object.keys(errors).length > 0 && (
                  <ul className="list-group mt-2">{errorParagraphs(errors.orders)}</ul>
                )}
              </div>
              {isCreatingBulkOrders && <Progress marginBottom={false} />}
              <div className="text-end py-2">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={() => {
                    handleSubmit()
                  }}
                  disabled={isSubmitting || isCreatingBulkOrders}
                >
                  Create Orders
                </button>
              </div>
            </div>
          )
        }}
      </Formik>
    </div>
  )
}

export default BulkImporter
