import axios from 'axios'
import { PrivacyRule } from '../../types'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  getPrivacyRules (token: string, perPage: number, page: number, signal: AbortSignal, search?: string) {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/privacy-rules', config)
  },
  getPrivacyRuleById (id: string, token: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/privacy-rules/${id}`, config)
  },
  updatePrivacyRule (id: string, token: string, privacyRule: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/privacy-rules/${id}`, { privacyRule }, config)
  },
  deletePrivacyRule (id: string, token: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/privacy-rules/${id}`, config)
  },
  addPrivacyRule (token: string, privacyRule: PrivacyRule, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/privacy-rules', privacyRule, config)
  }
}
