import { Product, ShoppingCartProduct } from '../types'
import { getMaximumProductOrderQuantity } from './getMaximumProductOrderQuantity'
import { getMinimumProductOrderQuantity } from './getMinimumProductOrderQuantity'

const isProductQuantityValid = (quantity: number, product: Product | ShoppingCartProduct | null, totalQuantityInBundles: number | null = null): boolean => {
  if (!product) {
    return false
  }

  const minQuantity = totalQuantityInBundles ? Math.max(totalQuantityInBundles, getMinimumProductOrderQuantity(product.minimumOrderQuantity ?? 1, product.graduatedPrices)) : getMinimumProductOrderQuantity(product.minimumOrderQuantity ?? 1, product.graduatedPrices)
  const maxQuantity = product.isExceedStockEnabled
    ? getMaximumProductOrderQuantity(1000, product.graduatedPrices)
    : Math.max((product.stock?.stockLevel || 0) - (product.stock?.stockLevelReserved || 0), 0) || 1

  return quantity >= minQuantity && quantity <= maxQuantity
}

export default isProductQuantityValid
