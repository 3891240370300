import React from 'react'

interface ShimmerProps {
  animation?: boolean
}
const Shimmer = ({ animation = true }: ShimmerProps) => {
  return (
    <div className={`${animation ? 'shimmer-wrapper ' : ''}`}>
      <div className="shimmer"></div>
    </div>
  )
}

export default Shimmer
