import React, { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import isToday from 'dayjs/plugin/isToday'
import Placeholder from '../../assets/images/placeholder.png'
import { TrashIcon } from '../icons/TrashIcon'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { ProductCustomisation } from '../../types'
import { createProductCustomisationChat, getAllProductCustomisationChats } from '../../store/reducers/api/productCustomisationChatReducer'
import { getProductCustomisations } from '../../store/reducers/api/productReducer'
import MiniPagination from '../MiniPagination'

dayjs.extend(isToday)

interface PrintPreviewProps {
  selectedProduct: ProductCustomisation | null
  isLoading: boolean
  setSelectedProducts: React.Dispatch<React.SetStateAction<ProductCustomisation | null>>
  uploadedFile: { url: string; filename: string } | null
  handleDeleteFile: () => void
}

const PrintPreview: React.FC<PrintPreviewProps> = ({
  selectedProduct,
  setSelectedProducts,
  uploadedFile,
  handleDeleteFile
}) => {
  const customisations = useAppSelector((state) => state.apiProduct.productCustomisation)
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isProductCustomisationLoading = useAppSelector((state) => state.apiProduct.isProductCustomisationLoading)
  const productCustomisationMetaData = useAppSelector((state) => state.apiProduct.productCustomisationMetaData)
  const customisationChats = useAppSelector((state) => state.apiProductCustomisationChat.chats)
  const token = currentUser?.token
  const userId = currentUser?.id
  const { id } = useParams()

  const [page, setPage] = useState(1)
  const [perPage] = useState(5)

  const chatContainerRef = useRef<HTMLDivElement>(null)
  const [commentMessage, setCommentMessage] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const profile = useAppSelector((state) => state.profile)

  const handleAddComment = async () => {
    if (token && selectedProduct?.id) {
      setIsSubmitting(true)
      const payload = {
        message: commentMessage,
        attachment: uploadedFile ? [uploadedFile] : []
      }
      await dispatch(createProductCustomisationChat({ token, productCustomisationChat: payload, productCustomisationId: selectedProduct.id }))
      await dispatch(getAllProductCustomisationChats({ token, productCustomisationId: selectedProduct.id }))
      setIsSubmitting(false)
    }
    handleDeleteFile()
    setCommentMessage('')
  }

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  }

  const thumbnails = customisations.filter(
    (customisation) => customisation.id !== selectedProduct?.id
  )

  useEffect(() => {
    if (token && id) {
      const controller = new AbortController()
      const signal = controller.signal
      dispatch(getProductCustomisations({ token, signal, productId: id, perPage, page }))
      return () => controller.abort()
    }
  }, [token, id, page, perPage, dispatch])

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }, [selectedProduct, customisationChats])

  useEffect(() => {
    const selected = customisations.find((customisation) => customisation.id === selectedProduct?.id) || null
    if (selected) {
      setSelectedProducts(selected)
    }
  }, [customisations])

  useEffect(() => {
    if (selectedProduct?.id && token) {
      dispatch(getAllProductCustomisationChats({ token, productCustomisationId: selectedProduct.id }))
    }
  }, [selectedProduct, token, dispatch])

  return (
    <div className="mt-4">
      <div className="row g-3">
        <div className="gap-4 col-12 col-md-7">
          <div className="d-flex flex-column flex-md-row gap-3">
            <div>
              <div className="d-flex flex-column w-100 gap-2">
                {isProductCustomisationLoading
                  ? (
                      Array.from(Array(3).keys()).map((index: number) => (
                        <img
                          key={`product-customisation-thumbnail-loading-${index}`}
                          src={Placeholder}
                          alt="Product thumbnail"
                          className="img-fluid border rounded object-fit-cover"
                        />
                      ))
                    )
                  : thumbnails.length > 0
                    ? (
                        thumbnails.map((customisation, index) => (
                          <img
                            key={`product-customisation-thumbnail-${index}`}
                            src={customisation.photos?.[0]?.url || Placeholder}
                            alt="Product thumbnail"
                            className="img-fluid rounded border object-fit-cover"
                            role="button"
                            tabIndex={0}
                            onClick={() => setSelectedProducts(customisation)}
                            onError={(e) => {
                              const target = e.target as HTMLImageElement
                              target.onerror = null
                              target.src = Placeholder
                            }}
                          />
                        ))
                      )
                    : (
                        <img
                          src={Placeholder}
                          alt="Product thumbnail"
                          className="img-fluid border rounded object-fit-cover"
                        />
                      )
                }
              </div>
            </div>

            <div className="card flex-grow-1">
              <div className="card-body d-flex align-items-center justify-content-between">
                <button
                  className="btn"
                  onClick={() => {
                    const currentIndex = customisations.findIndex(
                      (customisation) => customisation.id === selectedProduct?.id
                    )
                    const nextIndex =
                      (currentIndex - 1 + customisations.length) % customisations.length
                    setSelectedProducts(customisations[nextIndex])
                  }}
                >
                  <i className="bi bi-chevron-left fs-3 fw-bold"></i>
                </button>
                <div className="flex-grow-1 text-center">
                  {isProductCustomisationLoading
                    ? (
                      <img
                        src={Placeholder}
                        alt="Loading placeholder"
                        className="img-fluid object-fit-cover"
                      />
                      )
                    : (
                      <img
                        src={selectedProduct?.photos?.[0]?.url || Placeholder}
                        alt="Selected product"
                        className="img-fluid object-fit-cover"
                        onError={(e) => {
                          const target = e.target as HTMLImageElement
                          target.onerror = null
                          target.src = Placeholder
                        }}
                      />
                      )
                  }
                </div>
                <button
                  className="btn"
                  onClick={() => {
                    const currentIndex = customisations.findIndex(
                      (customisation) => customisation.id === selectedProduct?.id
                    )
                    const nextIndex = (currentIndex + 1) % customisations.length
                    setSelectedProducts(customisations[nextIndex])
                  }}
                >
                  <i className="bi bi-chevron-right fs-3 fw-bold"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between w-100 mt-3">
            <div className="d-flex justify-content-start">
              <MiniPagination
                metadata={{
                  limit: productCustomisationMetaData?.perPage,
                  total: productCustomisationMetaData?.total,
                  offset:
                    productCustomisationMetaData?.page && productCustomisationMetaData?.perPage
                      ? (productCustomisationMetaData.page - 1) * productCustomisationMetaData.perPage
                      : 0
                }}
                page={page}
                handlePageChange={handlePageChange}
                showLabel={false}
              />
            </div>
            <div className="d-flex justify-content-end gap-2">
              <button
                className="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#ApproveConfirmationModal"
                disabled={selectedProduct?.isApproved}
              >
                <i className="bi bi-check-circle-fill"></i>
                <span className="d-none d-md-inline-block ms-1">Approve</span>
              </button>
              <button
                className="btn btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#DisapproveConfirmationModal"
                disabled={selectedProduct?.isApproved}
              >
                <i className="bi bi-x-circle-fill"></i>
                <span className="d-none d-md-inline-block ms-1">Disapprove</span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-5">
          <div className="card w-100 p-2" style={{ height: '570px' }}>
            <div className="card-chat w-100" style={{ minHeight: '550px' }}>
              <div
                className="border-bottom overflow-auto h-100 w-100"
                id="chat-container"
                ref={chatContainerRef}
                style={{ maxHeight: 'calc(100vh - 200px)' }}
              >
                {
                  customisationChats?.length === 0
                    ? (
                      <div className="d-flex justify-content-center align-items-center h-100 p-2 rounded">
                        <div>
                          <p className="text-secondary m-0">No comments yet</p>
                          <div className="d-flex flex-column align-items-center">
                            <i className="bi bi-chat-left-text fs-1 text-primary"></i>
                            <i className="bi bi-chat-right-text fs-1 text-secondary"></i>
                          </div>
                        </div>
                      </div>
                      )
                    : (
                        customisationChats?.slice().reverse().map((chat, index) => (
                          <div key={`product-customisation-chat-${chat.id ?? index}`} className="card-body p-1">
                            <div
                              className={`d-flex flex-column align-items-start justify-content-center gap-2 ${
                                chat.owner?.id !== userId && 'bg-inprogress'
                              } rounded-5`}
                            >
                              <div className="d-flex align-items-start justify-content-start gap-4">
                                <div>
                                  <div className="avatar bg-delivered d-flex align-items-center justify-content-center p-4">
                                    {profile.profile?.firstName?.charAt(0)}
                                    {profile.profile?.lastName?.charAt(0)}
                                  </div>
                                </div>
                                <div className="d-flex flex-column mb-3">
                                  <div className="d-flex align-items-center gap-3">
                                    <p className="h6 m-0">{`${profile.profile?.firstName || ''} ${profile.profile?.lastName || ''}`}</p>
                                    <p className="m-0 fw-semibold small text-secondary">
                                    {chat?.createdAt
                                      ? dayjs(chat.createdAt).isToday()
                                        ? dayjs(chat.createdAt).format('h:mm A')
                                        : dayjs(chat.createdAt).format('MMM D, YYYY h:mm A')
                                      : ''}
                                    </p>
                                  </div>

                                  <p className="mt-2">{chat?.message}</p>

                                  {chat?.attachment?.[0]?.url && (
                                    <div className="border p-2 px-3 d-flex align-items-center gap-3 rounded-3 bg-light mt-2">
                                      <i className="bi bi-file-earmark fs-1 text-primary"></i>
                                      <a
                                        href={chat.attachment?.[0]?.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="h6 text-decoration-none"
                                      >
                                        {chat.attachment?.[0]?.filename || 'Download File'}
                                      </a>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      )
                }
              </div>
              {!selectedProduct?.isApproved
                ? (
                  <div className="d-flex align-items-center gap-2 w-100 py-2">
                    <div
                      className="btn d-flex align-items-center justify-content-center border"
                      data-bs-toggle="modal"
                      data-bs-target="#uploadNewFileModal"
                    >
                      <i className="bi bi-paperclip m-0 fs-6 text-secondary"></i>
                    </div>

                    <div className="d-flex w-100 align-items-center">
                      <input
                        type="text"
                        className="form-control flex-grow-1"
                        placeholder="Comment"
                        value={commentMessage}
                        onChange={(e) => setCommentMessage(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleAddComment()
                          }
                        }}
                        disabled={isSubmitting}
                      />
                      <button
                        onClick={handleAddComment}
                        className="btn btn-primary ms-2"
                        disabled={
                          customisations.find((product) => product.id === selectedProduct?.id)?.isApproved ||
                          (commentMessage === '' && !uploadedFile) ||
                          isSubmitting
                        }
                      >
                        <i className="bi bi-send"></i>
                      </button>
                    </div>
                  </div>
                  )
                : (
                  <div className="d-flex justify-content-center align-items-center p-2 rounded">
                    <p className="text-secondary m-0">Product Design Approved</p>
                  </div>
                  )}

              {uploadedFile && (
                <div className="d-flex align-items-center gap-2 p-3 px-2 w-100">
                  <div className="d-flex align-items-center justify-content-between border p-2 rounded w-100">
                    <div className="d-flex align-items-center gap-2">
                      <i className="bi bi-file-earmark fs-4"></i>
                      <span
                        className="d-block d-md-none text-truncate"
                        style={{ maxWidth: 75 }}
                        title={uploadedFile.filename}
                      >
                        {uploadedFile.filename}
                      </span>
                      <span className="d-none d-md-block" title={uploadedFile.filename}>
                        {uploadedFile.filename}
                      </span>
                    </div>
                    <button
                      type="button"
                      title="Remove Item"
                      className="btn btn-outline-danger btn-round btn-sm ms-5"
                      onClick={handleDeleteFile}
                    >
                      <TrashIcon />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrintPreview
